import React from "react";
import styled from "styled-components";
const jiraIcon = require("../../img/jira-logo.png");

const JiraWrapper = styled.div`
  user-select: none;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 20px;
  font-size: 12px;
  width: 100%;
  margin-top: 4px;
  > img {
    height: 12px;
    margin-left: 4px;
    margin-top: 2px;
  }
`;

const PoweredByJira = () => {
  return (
    <JiraWrapper>
      Powered by Jira
      <img src={jiraIcon} />
    </JiraWrapper>
  );
};
export default PoweredByJira;
