import * as React from "react";
import PageHeader from "../Header";
import { withRouter } from "react-router-dom";
import { CalendarContainer } from "./Styled";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment";
import axios from "axios";
import UserContext from "../../Context";
import { Message, Icon } from "semantic-ui-react";

class StaffCalendar extends React.PureComponent {
  calendarRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      department: "",
      events: {},
      currentMomnet: moment(),
    };
  }

  fetchDepartment = () => {
    return axios
      .get("/api/getDepartment?name=" + this.context.username)
      .then((res) => {
        const department = res.data.department;
        this.setState({ department });
      });
  };

  fetchDefaultCalendar = async () => {
    const { currentMonth, prevMonth, nextMonth } = this.getPrevAndNextMonth();
    this.setState({ isFetching: true });

    try {
      await Promise.all([
        this.fetchCalendar(currentMonth),
        this.fetchCalendar(prevMonth),
        this.fetchCalendar(nextMonth),
      ]);
    } finally {
      this.setState({ isFetching: false });
    }
  };

  getDifferenceInTime = (start) => {
    const time = moment(start).format("HH");
    /**
     * Start Time
     * 13 = PM
     * 10 = AM
     */
    return time < 13 ? "AM" : "PM";
  };

  fetchCalendar = (yearMonth) => {
    const [year, month] = yearMonth.split("-");

    return axios
      .get("/api/whyze/v2/calendar", { params: { year, month } })
      .then((res) => {
        const event = this.mapCalendarEvents(res.data);

        this.setState(({ events }) => ({
          events: {
            ...events,
            [`${year}-${month}`]: event,
          },
        }));
      });
  };

  getPrevAndNextMonth = () => {
    const { currentMomnet } = this.state;
    const prevMonth = currentMomnet
      .clone()
      .subtract(1, "month")
      .format("YYYY-MM");

    const nextMonth = currentMomnet.clone().add(1, "month").format("YYYY-MM");

    return {
      currentMonth: currentMomnet.format("YYYY-MM"),
      prevMonth,
      nextMonth,
    };
  };

  mapCalendarEvents = (response) => {
    const { isDarkTheme } = this.context;
    const { department: userDepartment } = this.state;

    return response
      .sort((prev, next) => prev.department > next.department)
      .map((item) => {
        const { allday, department, endOn, startOn, name } = item;

        const fullday = allday === "true";
        const start = moment(startOn).format("YYYY-MM-DD");
        const end = moment(endOn).format("YYYY-MM-DD");
        const sameDepartment =
          userDepartment.toLowerCase() === department.toLowerCase();

        // Assume no department = holiday
        const isHoliday = department === "";

        if (isHoliday) {
          return {
            title: name,
            start,
            end,
            color: "#e5f9e7",
            textColor: "#1ebc30"
          };
        }

        const colorMap = isDarkTheme
          ? {
              color: sameDepartment ? "#666" : "#222",
              textColor: sameDepartment ? "#fff" : "#fff",
            }
          : {
              color: sameDepartment ? "#0747a6" : "#deebff",
              textColor: sameDepartment ? "#fff" : "#0052cc",
            };

        const duration = fullday
          ? "(1)"
          : `(0.5 ${this.getDifferenceInTime(startOn)})`;

        return {
          title: `${duration} ${name.replace('(SG)', `(${department})`)}`,
          start,
          end,
          ...colorMap
        };
      });
  };

  onMonthChange = (action) => {
    const calendarApi = this.calendarRef.current.getApi();

    if (action === "today") {
      calendarApi.today();
    }

    if (action === "prev") {
      calendarApi.prev();
    }

    if (action === "next") {
      calendarApi.next();
    }

    this.setState(
      {
        currentMomnet: moment(calendarApi.getDate()),
      },
      () => this.onCurrentMomentChanged(action)
    );
  };

  onCurrentMomentChanged = (action) => {
    const { prevMonth, nextMonth } = this.getPrevAndNextMonth();

    if (action === "prev") {
      this.fetchCalendar(prevMonth);
    }

    if (action === "next") {
      this.fetchCalendar(nextMonth);
    }

    if (action === "today") {
      this.fetchDefaultCalendar();
    }
  };

  async componentDidMount() {
    document.title = "Staff Calendar";
    await this.fetchDepartment();
    await this.fetchDefaultCalendar();
  }

  render() {
    const { isFetching, events } = this.state;
    const { currentMonth, prevMonth, nextMonth } = this.getPrevAndNextMonth();

    const combinedEvents = [
      ...(events[currentMonth] || []),
      ...(events[prevMonth] || []),
      ...(events[nextMonth] || []),
    ];

    return (
      <>
        <PageHeader>
          <span className="title">Staff Calendar</span>
        </PageHeader>
        <CalendarContainer>
          <Message
            color="blue"
            style={{
              position: "absolute",
              top: 16,
              right: 178,
              padding: "0 16px",
              height: 36,
              lineHeight: "36px",
              margin: "0",
            }}
          >
            <Icon name="sync" loading={isFetching} />
            Data will be refreshed every hour
          </Message>
          <FullCalendar
            ref={this.calendarRef}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={combinedEvents}
            customButtons={{
              prev: {
                text: "Prev",
                click: () => this.onMonthChange("prev"),
              },
              next: {
                text: "Next",
                click: () => this.onMonthChange("next"),
              },
              today: {
                text: "Today",
                click: () => this.onMonthChange("today"),
              },
            }}
            headerToolbar={{
              left: "title",
              center: "",
              right: "today prev,next",
            }}
          />
        </CalendarContainer>
      </>
    );
  }
}

StaffCalendar.contextType = UserContext;

export default withRouter(StaffCalendar);
