import * as React from "react";
import PageHeader from "../Header";
import { Container, Icon } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { WorksheetContainer } from "./Styled";
import List from "./List";

class Jenkins extends React.Component {
  componentDidMount() {
    document.title = "Worksheet";
  }
  render() {
    return (
      <>
        <PageHeader>
          <span className="title">Worksheet</span>
        </PageHeader>
        <WorksheetContainer>
          <List />
        </WorksheetContainer>
      </>
    );
  }
}

export default withRouter(Jenkins);
