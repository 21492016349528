import * as React from "react";
import PageHeader from "../Header";
import { GroupingContainer, TableWrapper } from "./Styled";
import { Button, Icon, Input } from "semantic-ui-react";
import { createGrouping, listGrouping } from "./data";
import UserContext from "../../Context";

const allowedUser = ["weichoon.lee", "kumsoon.wong"];

const list = require("./list.json");

const MAX_FONT_SIZE = 24;
const MIN_FONT_SIZE = 10;

class Grouping extends React.Component {
  state = {
    list: [],
    originalList: [],
    currentFontSize: 12,
    currentUser: ""
  };

  shuffle = array => {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  reshuffle = async () => {
    const PERSON_PER_GROUP = 9;
    const GROUP_COUNT = 10;
    const shuffledArray = this.shuffle(list);
    let group = 1;
    let newGroup = [],
      tmp = [];
    shuffledArray
      .filter(item => item.Attendance !== "No")
      .forEach((item, index) => {
        item.group = group;
        tmp.push(item);

        if ((index + 1) % PERSON_PER_GROUP === 0) {
          newGroup.push(tmp);
          tmp = [];
          group++;
        }
        if (group > GROUP_COUNT) {
          group = 1;
        }
      });

    await createGrouping(newGroup);

    this.setState({
      originalList: newGroup,
      list: newGroup
    });
  };

  handleSearch = e => {
    const { originalList, list } = this.state;
    const currValue = e.target.value;
    /**
     * Find which group you belongs to
     */
    const filtered = originalList.filter(item => {
      return (
        item.filter(item => {
          return (
            item &&
            item.Name &&
            item.Name.trim()
              .toLowerCase()
              .includes(currValue.trim().toLowerCase())
          );
        }).length !== 0
      );
    });
    if (currValue !== "") {
      this.setState({
        list: filtered
      });
    } else {
      this.setState({
        list: originalList
      });
    }
  };

  increaseFontSize = isIncrease => {
    const { currentFontSize } = this.state;
    const FONT_SIZE_CONSTANT = 2;

    this.setState({
      currentFontSize: isIncrease
        ? currentFontSize + FONT_SIZE_CONSTANT
        : currentFontSize - FONT_SIZE_CONSTANT
    });
  };

  getData = async () => {
    // return new Promise(async resolver => {
    await listGrouping(data => {
      // resolver(data);

      if (data.length === 0) {
        this.reshuffle();
      } else {
        this.setState({
          originalList: data,
          list: data
        });
      }
    });
    // });
  };

  async componentDidMount() {
    document.title = "Grouping";

    const userContext = this.context;

    this.setState({
      currentUser: userContext.username
    });

    await this.getData();
  }

  render() {
    const { list, currentFontSize, currentUser } = this.state;
    return (
      <>
        <PageHeader>
          <span className="title">Grouping</span>
        </PageHeader>
        <GroupingContainer>
          <div className="sub-header">
            <Input
              icon="search"
              placeholder="Search your name..."
              onKeyUp={this.handleSearch}
              style={{ marginRight: "auto" }}
            />
            {allowedUser.find(item => item === currentUser) && (
              <Button
                content="Shuffle"
                icon="random"
                labelPosition="left"
                onClick={this.reshuffle}
              />
            )}
            <Button.Group>
              <Button
                icon
                onClick={() => this.increaseFontSize(false)}
                disabled={currentFontSize === MIN_FONT_SIZE}
              >
                <Icon name="minus" />
              </Button>
              <Button
                icon
                onClick={() => this.increaseFontSize(true)}
                disabled={currentFontSize === MAX_FONT_SIZE}
              >
                <Icon name="plus" />
              </Button>
            </Button.Group>
          </div>
          <TableWrapper
            style={{
              fontSize: currentFontSize,
              fontWeight: currentFontSize === MAX_FONT_SIZE ? "bold" : "normal"
            }}
          >
            {list.map((item, index) => (
              <table key={index}>
                <thead>
                  <tr>
                    <th>Team {item[0].group}</th>
                  </tr>
                </thead>
                <tbody>
                  {item.map(item => (
                    <tr key={item.Name}>
                      <td>{item.Name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ))}
          </TableWrapper>
        </GroupingContainer>
      </>
    );
  }
}

Grouping.contextType = UserContext;

export default Grouping;
