import styled from "styled-components";
import { keyframes } from "styled-components";

const borderColor = ({ theme }) => theme.tableBorderColor;

const tableAnimation = keyframes`
  from{
    transform: translateY(30px);
    opacity: 0;
  }
  to{
    transform: translateY(0);
    opacity: 1;
  }
`;

export const TableWrapper = styled.div`
  padding: 1rem;
  box-sizing: border-box;
  animation: ${tableAnimation} 300ms linear forwards;
  background: ${({ theme }) => theme.body};
  .dev-label {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
    position: relative;
    &.online {
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: green;
      }
    }
    @media screen and (max-width: 420px) {
      margin-right: 2px;

      img {
        display: none !important;
      }
    }
  }
  .reset-btn {
    cursor: pointer;
    position: absolute;
    top: 1em;
    right: 0;
    padding: 3px 0;
    box-sizing: border-box;
    background-color: rgba(219, 40, 40, 0.39);
    color: #fff;
    border-radius: 6px;
    transition: all 200ms ease-in-out;
    z-index: 10;
    width: 25px;
    text-align: right;
    > strong {
      transform: translateX(100%);
      opacity: 0;
      transition: all 200ms ease-in-out;
      position: absolute;
      left: 8px;
    }
    &:hover {
      background-color: rgba(219, 40, 40, 1);
      width: 70px;
      > strong {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
  td {
    &:last-child {
      padding: 1rem 0.5rem;
      width: 150px;
      @media screen and (max-width: 420px) {
        width: auto;
      }
    }
    .dev-label {
      @media screen and (max-width: 420px) {
        display: block !important;
        line-height: 1 !important;
        vertical-align: top !important;
        margin: initial !important;
        background-color: transparent !important;
        padding: 0 !important;
        color: initial !important;
        font-weight: 400 !important;
        border: 0 solid transparent;
        border-radius: 0 !important;
      }
    }
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid ${borderColor};
  .main-issue {
    margin-bottom: 6px;
    color: #0052cc;
    filter: ${({ theme }) => theme.textBrightness};
    font-weight: bold;
    display: block;
    @media screen and (max-width: 420px) {
      margin-top: 20px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .sub-issue {
    font-weight: bold;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: unset;
    flex-flow: row wrap;
    .copy-func {
      margin-left: 12px;
      color: grey;
    }
    .copy-func:active {
      color: blue;
    }
    .merge-request {
      margin-left: auto;
      + .status {
        margin-left: 8px;
        @media screen and (max-width: 420px) {
          margin-left: 0;
        }
      }
      svg {
        path {
          fill: ${({ theme }) => theme.text};
        }
      }
    }
    .text {
      &:hover {
        text-decoration: underline;
      }
    }

    .merge-request {
      &:hover {
        text-decoration: underline;
      }
    }

    &:hover {
      .status {
        text-decoration: none;
      }
    }
    @media screen and (max-width: 420px) {
      padding-top: 15px;
      .status {
        left: 0;
        right: 0;
        top: 0;
        border-radius: 0;
        width: 100%;
        position: absolute;
        text-align: right;
        font-size: 11px;
      }
    }
  }
  .avatar {
    padding: 4px 8px;
    display: inline-block;
    min-height: 25px;
    border-radius: 8px 8px 8px 8px;
    background-color: ${({ theme }) => theme.avatarBg};
    color: ${({ theme }) => theme.avatarColor};
    font-weight: bold;
    font-size: 13px;
    box-sizing: border-box;
    cursor: pointer;
    @media screen and (max-width: 420px) {
      padding: 2px 0.5rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 0;
      font-size: 11px;
      min-height: initial;
    }
  }
  thead {
    background-color: ${({ theme }) => theme.tableHeaderBg};
    tr {
      &:only-child {
        border-bottom: 1px solid ${borderColor};
      }
      &:first-child {
        td {
          font-weight: bold;

          &:first-child {
            border-radius: 4px 0 0 0;
          }
          &:last-child {
            border-radius: 0 4px 0 0;
          }
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid ${borderColor};
      }
      td {
        padding: 1rem;

        &:not(:last-child) {
          border-right: 1px solid ${borderColor};
        }
        @media screen and (max-width: 420px) {
          padding: 0.5rem;
        }
      }
    }
  }
  tbody {
    tr {
      &:last-child {
        td {
          &:first-child {
            border-radius: 0 0 0 4px;
          }
          &:last-child {
            border-radius: 0 0 4px 0;
          }
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid ${borderColor};
      }
      td {
        padding: 1rem;
        position: relative;
        .status {
          //position: absolute;
          color: #fff;
          //height: 100%;
          //width: 4px;
          //font-size: 0;
          padding: 2px 6px;
          border-radius: 20px;
          margin-left: auto;
          font-size: 12px;
        }
        &.striped {
          &.first {
            &:before {
              content: "";
              position: absolute;
              top: 0;
              height: 3px;
              width: 100%;
              left: 0;
              background: rgba(55, 55, 55, 0.1);
            }
          }
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            height: 3px;
            width: 100%;
            left: 0;
            background: rgba(55, 55, 55, 0.1);
          }
        }
        &.yellow {
          color: #594300;
          .text {
            color: #594300;
            filter: ${({ theme }) => theme.textBrightness};
          }
          background-color: rgba(225, 225, 0, 0.1);
          .status {
            background: #e3b000;
          }
        }
        &.green {
          color: #14892c;
          .text {
            color: #14892c;
            filter: ${({ theme }) => theme.textBrightness};
          }
          background-color: rgba(0, 150, 18, 0.08);
          .status {
            background: #1cb03c;
          }
        }
        &.blue-gray {
          color: #4a6785;
          .text {
            color: #4a6785;
          }
          background-color: rgba(129, 129, 173, 0.08);
          .status {
            background: #82b8e3;
          }
        }
        &:only-child {
          border-right: 0;
        }
        &:first-child {
          vertical-align: text-top;
        }
        &:not(:last-child) {
          border-right: 1px solid ${borderColor};
        }
        @media screen and (max-width: 420px) {
          padding: 1rem 0.5rem;
        }
      }
    }
  }
`;

export const CanvasWrapper = styled.div`
  animation: ${tableAnimation} 300ms linear forwards;
  height: calc(100vh - 40px);
  width: 100%;
  > canvas {
    background: linear-gradient(to bottom, rgba(222, 235, 255, 0.44), #fff);
    width: 100%;
    height: 100%;
  }
`;

export const DatePickerWrapper = styled.div``;

const loadingAnimation = keyframes`
  0%{
    transform: translateY(10px);
    opacity: 0;
  }
  50%{
    transform: translateY(0);
    opacity: 1;
  }
  100%{
  transform: translateY(-10px);
    opacity: 0;
  }
`;

export const Loading = styled.div`
  height: calc(100vh - 40px);
  width: 100%;
  overflow: hidden;
  > .loading-item {
    width: 100%;
    height: 50px;
    margin: 10px 0 0 0;
    background-color: ${({ theme }) => theme.loadingBg};
    //box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    animation: ${loadingAnimation} 2s linear infinite;

    &:nth-child(1) {
      animation-delay: 100ms;
    }
    &:nth-child(2) {
      animation-delay: 200ms;
    }
    &:nth-child(3) {
      animation-delay: 300ms;
    }
    &:nth-child(4) {
      animation-delay: 400ms;
    }
    &:nth-child(5) {
      animation-delay: 500ms;
    }
    &:nth-child(6) {
      animation-delay: 600ms;
    }
    &:nth-child(7) {
      animation-delay: 700ms;
    }
    &:nth-child(8) {
      animation-delay: 800ms;
    }
    &:nth-child(9) {
      animation-delay: 900ms;
    }
    &:nth-child(10) {
      animation-delay: 1000ms;
    }
  }
`;
