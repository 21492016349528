import * as React from "react";
import styled from "styled-components";

let requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  function(callback) {
    window.setTimeout(callback, 1000 / 60);
  };
window.requestAnimationFrame = requestAnimationFrame;

let flakes = [],
  flakeCount = 100,
  mX = -100,
  mY = -100;

const SnowWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background: linear-gradient(
    to bottom,
    rgba(38, 134, 226, 0.3),
    rgba(0, 0, 0, 0.01)
  );
  overflow: hidden;
  z-index: 100;
  animation: fadeIn 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  > canvas {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: snowFadeIn 2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
  @keyframes snowFadeIn {
    from {
      transform: scale(2);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

class Snow extends React.Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.ctx = "";
  }

  snow = () => {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    for (var i = 0; i < flakeCount; i++) {
      var flake = flakes[i],
        x = mX,
        y = mY,
        minDist = 150,
        x2 = flake.x,
        y2 = flake.y;

      var dist = Math.sqrt((x2 - x) * (x2 - x) + (y2 - y) * (y2 - y)),
        dx = x2 - x,
        dy = y2 - y;

      if (dist < minDist) {
        var force = minDist / (dist * dist),
          xcomp = (x - x2) / dist,
          ycomp = (y - y2) / dist,
          deltaV = force / 2;

        flake.velX -= deltaV * xcomp;
        flake.velY -= deltaV * ycomp;
      } else {
        flake.velX *= 0.98;
        if (flake.velY <= flake.speed) {
          flake.velY = flake.speed;
        }
        flake.velX += Math.cos((flake.step += 0.05)) * flake.stepSize;
      }

      this.ctx.fillStyle = "rgba(255,255,255," + flake.opacity + ")";
      flake.y += flake.velY;
      flake.x += flake.velX;

      if (flake.y >= this.canvas.height || flake.y <= 0) {
        this.reset(flake);
      }

      if (flake.x >= this.canvas.width || flake.x <= 0) {
        this.reset(flake);
      }

      this.ctx.beginPath();
      this.ctx.arc(flake.x, flake.y, flake.size, 0, Math.PI * 2);
      this.ctx.fill();
    }
    requestAnimationFrame(this.snow);
  };

  reset = flake => {
    flake.x = Math.floor(Math.random() * this.canvas.width);
    flake.y = 0;
    flake.size = Math.random() * 3 + 3;
    flake.speed = Math.random() * 1 + 1.5;
    flake.velY = flake.speed;
    flake.velX = 0;
    flake.opacity = Math.random() * 0.5 + 0.3;
  };

  init = () => {
    for (var i = 0; i < flakeCount; i++) {
      var x = Math.floor(Math.random() * this.canvas.width),
        y = Math.floor(Math.random() * this.canvas.height),
        size = Math.random() * 3 + 2,
        speed = Math.random() * 1 + 0.5,
        opacity = Math.random() * 0.5 + 0.3;

      flakes.push({
        speed: speed,
        velY: speed,
        velX: 0,
        x: x,
        y: y,
        size: size,
        stepSize: Math.random() / 30,
        step: 0,
        opacity: opacity
      });
    }

    this.snow();
  };

  componentDidMount() {
    this.canvas = document.getElementById("snow");
    this.ctx = this.canvas.getContext("2d");

    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;

    let currentCanvas = this.canvas;

    this.canvas.addEventListener("mousemove", function(e) {
      mX = e.clientX;
      mY = e.clientY;
    });

    window.addEventListener("resize", function() {
      currentCanvas.width = window.innerWidth;
      currentCanvas.height = window.innerHeight;
    });

    this.init();
  }

  componentWillUnmount() {
    flakeCount = 100;
    mX = -100;
    mY = -100;
    cancelAnimationFrame(window.requestAnimationFrame);
  }

  render() {
    return (
      <SnowWrapper>
        <canvas id="snow" ref={this.canvas} />
      </SnowWrapper>
    );
  }
}

export default Snow;
