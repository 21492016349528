import * as React from "react";
import { JenkinsList } from "../Styled";
import { Icon, Segment, Label, Button, Modal } from "semantic-ui-react";
import { Loading } from "../../Public/Styled";
import Ajax from "../../Public/Ajax";

const displayTab = ["All", "PROD", "SIT", "UAT"];

class JenkinsStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      currentSelected: "All",
      modalOpen: false,
      log: ""
    };
  }

  getList = platform => {
    return new Promise(async resolver => {
      await Ajax("/api/jenkins/listJenkins", {
        method: "GET",
        params: {
          platform: platform ? platform : undefined
        }
      }).then(function(response) {
        resolver(response);
      });
    });
  };

  async init() {
    this.setState({
      isFetching: true
    });
    const that = this;

    Promise.all([
      that.getList("PROD"),
      that.getList("UAT"),
      that.getList("SIT")
    ]).then(function(response) {
      const list = [...response[0], ...response[1], ...response[2]];

      that.setState({
        list: list,
        isFetching: false
      });
    });
  }

  async componentDidMount() {
    await this.init();
  }

  viewLog = log => {
    this.setState({
      modalOpen: true,
      log: log
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
      log: ""
    });
  };

  renderJenkinsItem = props => {
    const name = props.name;
    const result = props.result;
    const lastBuild = props.lastBuild;
    const log = props.log;

    return (
      <div
        key={`${name}${result}${lastBuild}`}
        className={`jenkins-item ${
          result === null
            ? "loading"
            : result && result.toLowerCase() === "aborted"
            ? "unknown"
            : result && result.toLowerCase() === "failure"
            ? "failed"
            : "success"
        }`}
      >
        <div className="jenkins-application-name">
          {name}
          <span className="jenkins-application-status">
            {result === null ? (
              <Icon name="spinner loading icon" />
            ) : result && result.toLowerCase() === "success" ? (
              <Icon name="check circle" />
            ) : (
              <Icon name="times circle" />
            )}
          </span>
        </div>
        <div className="jenkins-application-details">
          <div className="status-wrapper">
            <div className="label">Last build</div>
            <div className="content"> {lastBuild}</div>
          </div>
          {/*<div className="status-wrapper">*/}
          {/*  <div className="label">Running in EB</div>*/}
          {/*  <div className="content">{props.envVersion}</div>*/}
          {/*</div>*/}
          <div className="status-wrapper">
            <div className="label">Build Result</div>
            <div className="content">
              {result === null ? "Building" : result}
            </div>
            {result === "FAILURE" && (
              <Button
                color="red"
                style={{ marginLeft: "auto" }}
                size="mini"
                onClick={() => this.viewLog(log)}
              >
                <Icon name="eye" />
                View Log
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  changeTab = selected => {
    this.setState({
      currentSelected: selected
    });
  };

  render() {
    const { list, isFetching, currentSelected, modalOpen, log } = this.state;

    // console.log(environment, flattenedLastBuild);
    return (
      <>
        {isFetching ? (
          <Loading>
            {Array.from(Array(10).keys()).map((item, i) => {
              return <div className="loading-item" key={i} />;
            })}
          </Loading>
        ) : (
          <>
            <Modal open={modalOpen}>
              <Modal.Header style={{ display: "flex", alignItems: "center" }}>
                Log
                <Icon
                  name="times"
                  onClick={this.handleClose}
                  style={{ marginLeft: "auto" }}
                />
              </Modal.Header>
              <Modal.Content>
                <pre style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                  {log}
                </pre>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={this.handleClose}>Close</Button>
              </Modal.Actions>
            </Modal>
            <Segment
              vertical
              className="tab-wrapper"
              style={{ overflow: "auto" }}
            >
              {displayTab.map((item, index) => (
                <Label
                  className="tab-item"
                  key={item}
                  color={currentSelected === item ? "blue" : ""}
                  onClick={() => this.changeTab(item)}
                >
                  {item}
                </Label>
              ))}
              <Label
                color="teal"
                className={`tab-item`}
                style={{ marginLeft: "auto" }}
                onClick={() => this.init()}
              >
                <Icon name="refresh" />
                Refresh
              </Label>
            </Segment>
            <JenkinsList>
              {list &&
                list
                  .filter(item =>
                    item && currentSelected === "SIT"
                      ? item.name.toLowerCase().includes("sit")
                      : currentSelected === "UAT"
                      ? item.name.toLowerCase().includes("uat")
                      : currentSelected === "PROD"
                      ? !(
                          item.name.toLowerCase().includes("uat") ||
                          item.name.toLowerCase().includes("sit")
                        )
                      : true
                  )
                  .map((item, index) => {
                    const name = item.name;
                    const result = item.result;
                    const lastBuild = item.lastBuild;
                    const log = item.log;

                    return this.renderJenkinsItem({
                      name: name,
                      result: result,
                      lastBuild: lastBuild,
                      log: log
                    });
                  })}
            </JenkinsList>
          </>
        )}
      </>
    );
  }
}

export default JenkinsStatus;
