import React, { useContext, useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/amchartsdark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { JIRA_BASE_URL } from "../Public/Links";
import UserContext from "../../Context";

function Chart({ tasks }) {
  const [chartHeight, setChartHeight] = useState();
  const userContext = useContext(UserContext);
  const isDarkTheme = userContext.isDarkTheme;

  useEffect(() => {
    if (tasks) {
      const height =
        tasks.reduce((acc, item) => {
          const { category } = item;
          if (!acc) {
            acc = [];
            acc.push(item);
          }

          if (!acc.find((item) => item.category === category)) {
            acc.push(item);
          }
          return acc;
        }, []).length *
          40 +
        100;

      setChartHeight(height);
    }

    let chart = am4core.create("chartdiv", am4charts.XYChart);

    // detect dark theme and set corresponding theme
    if (isDarkTheme) {
      am4core.useTheme(am4themes_dark);
    } else {
      am4core.useTheme(am4themes_animated);
    }
    am4core.addLicense("ch-custom-attribution");

    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.paddingRight = 30;
    chart.dateFormatter.inputDateFormat = "YYYY-MM-dd";

    const colorSet = new am4core.ColorSet();
    colorSet.saturation = 0.4;

    const data = tasks.map((item, index) => {
      const { colorIndex, statusColorIndex } = item;
      return {
        ...item,
        color: colorSet.getIndex(colorIndex).brighten(statusColorIndex * 0.5),
      };
    });

    chart.data = data;

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    const labelDOM = `<div class="chart-label">
      <a class="chart-link" href="${JIRA_BASE_URL}/browse/{key}" target="_blank">{category}</a
      ><span class="status {statusColorName}">{statusText}</span>
    </div>`;

    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.labels.template.textAlign = "start";
    categoryAxis.renderer.labels.template.align = "left";
    categoryAxis.renderer.labels.template.cursorOverStyle =
      am4core.MouseCursorStyle.pointer;
    categoryAxis.renderer.labels.template.html = labelDOM;
    categoryAxis.renderer.labels.template.tooltipPosition = "pointer";
    categoryAxis.renderer.labels.template.tooltipText = "{category}";
    // categoryAxis.renderer.width = 200

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.dateFormatter.dateFormat = "YYYY-MM-dd";
    dateAxis.renderer.minGridDistance = 70;
    dateAxis.baseInterval = { count: 1, timeUnit: "day" };
    // dateAxis.max = new Date(2022, 0, 1, 24, 0, 0, 0).getTime();
    // dateAxis.strictMinMax = true;
    dateAxis.renderer.tooltipLocation = 0;
    dateAxis.renderer.opposite = true;
    dateAxis.renderer.minGridDistance = 80;
    // dateAxis.renderer.labels;

    const series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.width = am4core.percent(80);
    series1.columns.template.tooltipText = "{name} : {openDateX} - {dateX}";

    series1.dataFields.openDateX = "fromDate";
    series1.dataFields.dateX = "toDate";
    series1.dataFields.categoryY = "category";
    series1.columns.template.propertyFields.fill = "color"; // get color from data
    series1.columns.template.propertyFields.stroke = "color";
    series1.columns.template.strokeOpacity = 1;

    chart.scrollbarX = new am4core.Scrollbar();
  }, [isDarkTheme, tasks]);

  return (
    <div
      id="chartdiv"
      style={{
        width: "100%",
        height: chartHeight + "px",
      }}
    />
  );
}

export default Chart;
