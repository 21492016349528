import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";

const style = {
  width: "100%",
  marginBottom: "50px"
};

const fadedStyle = {
  color: "gray",
  fontSize: "smaller"
};

const Help = () => {
  const [active, setActive] = useState(false);
  return (
    <div>
      <Accordion styled style={style}>
        <Accordion.Title
          active={active}
          index={0}
          onClick={() => setActive(!active)}
        >
          <Icon name="dropdown" />
          Translation Help <Icon name="question circle" />
        </Accordion.Title>
        <Accordion.Content active={active}>
          <p>
            if certain phrases need to be translated differently while still
            using the same key in English, you can append a module prefix to
            differentiate the keys. Anything before the <b>first</b> colon (
            <code>:</code>) will be considered a prefix. Spaces are not required
            before or after the colon. Subsequent colons are not considered.
          </p>
          <p>
            In the case where an actual colon needs to be displayed, use a
            backward slash (<code>\</code>) before the colon to ignore this
            convention. <sup>*</sup>
          </p>
          <p>
            If unsure, look at the generated English text in the non-editable
            field on the right below to make sure it is correct.
          </p>
          <p style={fadedStyle}>
            * Note: here, only a single slash is required, but when translating
            in code, this backward slash has to be ignored (<code>\\</code>) to
            be detected properly.
          </p>
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default Help;
