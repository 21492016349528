import styled from "styled-components";

const aqLogo = require("./img/aq-plus.svg");
const aqBg = require("./img/aq-bg.svg");
const orionIconChristmas = require("./img/christmas/orion-logo-christmas.png");
const orionIconNewYear = require("./img/newYear/orion-logo-new-year.png");
const orionIconCNY = require("./img/CNY/orion-logo-cny.png");
const cnyFu = require("./img/CNY/fu.svg");
const santaImage = require("./img/christmas/santa.png");
const reindeerImage = require("./img/christmas/reindeer.gif");

export const Header = styled.div`
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  //justify-content: center;
  background-color: #f7faff;
  background-image: url(${aqBg});
  background-size: cover;
  background-position: center;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  padding: 0 20px 0 60px;
  box-sizing: border-box;
  @media screen and (max-width: 400px) {
    padding: 0 8px 0 40px;
  }

  + div {
    position: relative;
    margin-top: 52px;
    box-sizing: border-box;
    min-height: calc(100vh - 52px);
  }

  &.prod {
    text-align: left;
    //padding: 0 20px;
    @media screen and (max-width: 400px) {
      padding: 0 8px;
    }
  }

  .logo {
    display: block;
    width: 120px;
    height: 100%;
    background: url(${aqLogo}) center / contain no-repeat;
    z-index: 10;

    .dropdown {
      position: absolute;
      width: 100px;
      height: auto;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(3px);
      top: 44px;
      left: 40px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 200ms ease-in-out;
      opacity: 0;
      visibility: hidden;

      > button {
        border: 0;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 100%;
        border-radius: 4px;
        transition: all 200ms ease-in-out;
        color: #0948a6;

        > img {
          margin-left: 10px;
        }

        &:hover {
          background-color: rgba(38, 134, 226, 0.4);
        }
      }
    }

    &.christmas {
      background: url(${orionIconChristmas}) left 7px / contain no-repeat;
    }

    &.new-year {
      background: url(${orionIconNewYear}) left 7px / contain no-repeat;
    }

    &.cny {
      background: url(${orionIconCNY}) center / contain no-repeat;

      &:before {
        content: "";
        background: url(${cnyFu}) center / contain no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 11px;
        left: 81px;
        transform: scale(0.4) rotate(38deg);
        transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      &:hover {
        &:before {
          left: 114px;
          top: 7px;
          transform: scale(1) rotate(360deg);
        }
      }
    }

    @media screen and (max-width: 400px) {
      width: 40px;
    }

    &:hover {
      //& + .hover-effect {
      //  opacity: 1;
      //}
      .dropdown {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .hover-effect {
    position: absolute;
    width: 30px;
    height: 30px;
    opacity: 0;
    background: radial-gradient(rgb(37, 54, 255) 0%, transparent 80%);
    transition: opacity 200ms ease-in-out;
    pointer-events: none;
    z-index: 0;
  }

  .title {
    margin-left: 12px;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    margin-right: auto;
    @media screen and (max-width: 400px) {
      font-size: 12px;
      margin-left: 6px;
    }
  }

  .date-picker-wrapper {
    width: 120px;
    position: relative;
    background: rgba(9, 30, 66, 0.48);
    height: 2.143em;
    border-radius: 3px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    font-size: 13px;
    line-height: 13px;

    & + div {
      margin-left: 4px;
    }

    > i {
      opacity: 0.5;
    }

    input {
      outline: none;
      border: none;
      box-shadow: none;
      box-sizing: border-box;
      background: transparent;
      height: 100%;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      color: #fff;
      padding: 0;
      vertical-align: baseline;
      text-align: right;
    }

    .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
      margin-left: 8em;
    }
  }
`;

export const UserDropdown = styled.div`
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
  padding-left: 12px;
  padding-right: 8px;
  position: relative;
  .icon {
    margin-left: 4px;
    margin-right: 0;
  }
  &:hover .user-menu {
    opacity: 1;
    visibility: visible;
  }
`;

export const UserDropdownMenu = styled.div`
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  color: #0948a6;
  min-width: 120px;
  position: absolute;
  top: 38px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  &:before {
    content: "";
    position: absolute;
    border: 6px solid transparent;
    border-bottom: 6px solid #fff;
    right: 20%;
    top: -12px;
  }
  .user-menu-item {
    border-radius: 6px;
    display: flex;
    padding: 8px 12px;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    &:hover {
      background-color: #deebff;
    }
  }
`;

export const Santa = styled.div`
  position: absolute;
  bottom: 30px;
  left: auto;
  margin-top: -85px;
  animation: path-animation 30s infinite;
  &:before {
    content: "";
    width: 40px;
    height: 40px;
    border: 1px solid transparent;
    background: url(${santaImage}) no-repeat left top / 482px 40px;
    animation: santa-animation 0.8s steps(12) infinite;
    position: fixed;
    z-index: 100;
    top: 0;
  }
  &:after {
    content: "";
    position: fixed;
    top: 6px;
    left: 0;
    width: 40px;
    height: 40px;
    z-index: 100;
    background: url(${reindeerImage}) center / contain no-repeat;
    animation: path-animation 27s infinite;
  }
  @keyframes santa-animation {
    0% {
      background-position: 0px 0;
    }
    100% {
      background-position: -482px 0;
    }
  }
  @keyframes path-animation {
    0% {
      left: -5%;
    }
    100% {
      left: 120%;
    }
  }
`;
