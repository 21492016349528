import * as React from "react";
import styled from "styled-components";
import { Divider } from "semantic-ui-react";

const stickmanUrl = require("../../img/emo-stickman.gif");
const EmoStickmanWrapper = styled("div")`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 0, 0, 0.05);
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 4px;
  padding: 6px;
  margin-top: 12px;
  > img {
    width: 40px;
    filter: ${({ theme }) => theme.iconFilter};
  }
  > span {
    margin-left: 4px;
    color: ${({ theme }) => theme.text};
  }
`;

class EmoStickman extends React.Component {
  render() {
    const { mainKey } = this.props;
    return (
      <>
        <Divider />
        <EmoStickmanWrapper>
          <img src={stickmanUrl} />
          <span>
            The Aquarix Plus man tried his best to retrieve the tickets but he
            failed, kindly proceed to{" "}
            <a href={`https://aquariux.atlassian.net/browse/${mainKey}`} target="_blank">
              {mainKey}
            </a>{" "}
            to view the full ticket
          </span>
        </EmoStickmanWrapper>
      </>
    );
  }
}

export default EmoStickman;
