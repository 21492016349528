import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import {
  Route,
  Link,
  HashRouter as Router,
  withRouter,
  Redirect,
} from "react-router-dom";
import { Modal, Label } from "semantic-ui-react";
import Home from "./components/Home";
import AppTicket from "./components/AppTicket";
import Jira from "./components/jira";
import Incident from "./components/incident";
import Live from "./components/live";
import UpdateTicket from "./components/UpdateTicket";
import Translate from "./components/Translate";
import Aws from "./components/AWS";
import Jenkins from "./components/Jenkins";
import Grouping from "./components/Grouping";
import Worksheet from "./components/Worksheet";
import SupportForm from "./components/SupportForm";
import WhyzeCalendar from "./components/Whyze";
import Timeline from "./components/Timeline";

import {
  MenuToggle,
  Page,
  PageContainer,
  Fetching,
} from "./components/Home/Styled";
import {
  addOnlineUser,
  removeOnlineUsers,
} from "./components/Public/OnlineUsers";
import axios from "axios";
import { GlobalStyles } from "./global";
import { lightTheme, darkTheme } from "./theme";
import { ThemeProvider } from "styled-components";

import AppProvider from "./Provider";
// import { Header, Icon, Segment, Sidebar } from "semantic-ui-react";
import PageHeader from "./components/Header";

import {
  Button,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
} from "semantic-ui-react";
import Login from "./components/Login";
import UserContext from "./Context";

// import PageHeader from "./Header";
// import { Page, PageContainer } from "./Styled";
// import { Link } from "react-router-dom";

import {
  DEPLOYMENT_PLAN,
  SUPPORT_REFERENCE,
  JIRA_LINK,
  CONFLUENCE_LINK,
  GENERAL_COMPANY_INFO,
  ON_BOARDING,
  ORG_CHART,
  DEVELOPMENT_INFORMATION,
} from "./components/Public/Links";

const JIRA_LOGO = require("./img/jira.svg");
const CONFLUENCE_LOGO = require("./img/confluence.svg");

const SystemIcon = (link) => {
  return (
    <i className="icon">
      <img src={link} style={{ width: "100%", filter: "grayscale(1)" }} />
    </i>
  );
};

const VerticalSidebar = ({
  onClick,
  animation,
  direction,
  visible,
  logout,
}) => (
  <Sidebar
    onClick={onClick}
    as={Menu}
    animation={animation}
    direction={direction}
    // icon="labeled"
    inverted
    vertical
    visible={visible}
    width="thin"
  >
    <Menu.Item>
      <Icon name="home" />
      <Link to="/" />
      Home
    </Menu.Item>
    {/* <Menu.Item>
      <Icon name="ticket" />
      <Link to="/jira" />
      Franchise
    </Menu.Item> */}
    {/*<Menu.Item>*/}
    {/*  <Icon name="refresh" />*/}
    {/*  <Link to="/updateTicket" />*/}
    {/*  Ticket Update*/}
    {/*</Menu.Item>*/}
    {/* <Menu.Item>
      <Icon name="mobile alternate" />
      <Link to="/app" />
      App
    </Menu.Item>
    <Menu.Item>
      <Icon name="file" />
      <Link to="/incident" />
      Incident
    </Menu.Item> */}
    <Menu.Item>
      <Icon name="chart line" />
      <Link to="/live" />
      Deployment Tickets
    </Menu.Item>
    {/* <Menu.Item>
      <Icon name="translate" />
      <Link to="/translate" />
      Translator
    </Menu.Item>
    <Menu.Item>
      <Icon name="aws" />
      <Link to="/awsStatus" />
      AWS Status
    </Menu.Item>
    <Menu.Item>
      <Icon name="jenkins" />
      <Link to="/jenkinsStatus" />
      Jenkins Status
    </Menu.Item>
    <Menu.Item>
      <Icon name="file alternate outline" />
      <Link to="/worksheet" />
      Worksheet
    </Menu.Item>
    <Menu.Item>
      <Icon name="file pdf outline" />
      <Link to="/supportForm" />
      Support Form
    </Menu.Item> */}
    <Menu.Item>
      <Icon name="calendar" />
      <Link to="/calendar" />
      Staff Calendar
    </Menu.Item>
    {/*<Menu.Item>*/}
    {/*  <Icon name="random" />*/}
    {/*  <Link to="/grouping" />*/}
    {/*  Grouping*/}
    {/*</Menu.Item>*/}
    <Menu.Item>
      <Icon name="flag" />
      <a href={DEPLOYMENT_PLAN} target="_blank" />
      Deployment Plan
    </Menu.Item>
    <Menu.Item>
      <Icon name="tty" />
      <a href={SUPPORT_REFERENCE} target="_blank" />
      Support Reference
    </Menu.Item>

    <Menu.Item>
      {SystemIcon(JIRA_LOGO)}
      <a href={JIRA_LINK} target="_blank" />
      Jira
    </Menu.Item>
    <Menu.Item>
      {SystemIcon(CONFLUENCE_LOGO)}
      <a href={CONFLUENCE_LINK} target="_blank" />
      Confluence
    </Menu.Item>

    <Menu.Item>
      <Icon name="info circle" />
      <a href={GENERAL_COMPANY_INFO} target="_blank" />
      Company Information
    </Menu.Item>
    <Menu.Item>
      <Icon name="vcard" />
      <a href={ON_BOARDING} target="_blank" />
      On-boarding
    </Menu.Item>
    <Menu.Item>
      <Icon name="group" />
      <a href={ORG_CHART} target="_blank" />
      Organization Chart
    </Menu.Item>
    <Menu.Item>
      <Icon name="bug" />
      <a href={DEVELOPMENT_INFORMATION} target="_blank" />
      Development Information
    </Menu.Item>
  </Sidebar>
);

class App extends React.Component {
  state = {
    // isLogin: false,
    animation: "overlay",
    direction: "left",
    dimmed: false,
    visible: false,
    isFetching: true,
    isOldDomain: false,
    bearer: "",
  };

  handleAnimationChange = (animation) => () =>
    this.setState((prevState) => ({ animation, visible: !prevState.visible }));

  toggleLoginState = () => {
    const userContext = this.context;
    userContext.toggleLoginState();
    // this.setState({
    //   isLogin: !this.state.isLogin
    // });
  };

  logout = () => {
    const userContext = this.context;
    userContext.toggleLoginState(false);
    // this.setState({
    //   isLogin: false
    // });
  };

  checkLoginUser = async () => {
    const token = localStorage.getItem("token");
    if (!token) return "";
    const oauthAccessToken = token.split("|")[0];
    const oauthAccessTokenSecret = token.split("|")[1];
    return await new Promise((resolve) => {
      axios
        .get("/api/sessions/getCurrentUser", {
          params: {
            oauthAccessToken,
            oauthAccessTokenSecret,
          },
        })
        .then(function (response) {
          const data = response.data;
          // console.log(data);
          if (data.statusCode === 401) {
            resolve("");
          } else {
            resolve(data);
          }
        });
    });
  };

  detectOldAndNewDomain = () => {
    const origin = window.location.origin;
    const newHome = "https://plus.orion.co.com";
    const oldHome = "orion-dev-tools.ap-northeast-1.elasticbeanstalk.com";
    if (origin.includes(oldHome)) {
      this.setState({
        isOldDomain: true,
      });
      return true;
    }
  };

  async componentDidMount() {
    let userContext = this.context;
    const userData = await this.checkLoginUser();
    const name = userData.displayName;
    const bearer = userData.token;
    const token = localStorage.getItem("token");

    localStorage.setItem("bearer", bearer);

    this.detectOldAndNewDomain();

    // const name = localStorage.getItem("name");
    // const name = userContext.username;
    userContext.setUsername(name);
    userContext.setBearer(bearer);

    if (token && userData) {
      // this.setState({
      //   isLogin: true
      // });
      userContext.toggleLoginState(true);
      // await addOnlineUser(name);
    }

    window.addEventListener("beforeunload", async function (e) {
      // const confirmationMessage = "You sure you want to leave?";

      // (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      // return confirmationMessage; //Webkit, Safari, Chrome
      // if (confirmationMessage) {
      await removeOnlineUsers(name);
      // }
    });

    this.setState({
      isFetching: false,
    });
  }

  render() {
    const {
      animation,
      dimmed,
      direction,
      visible,
      isFetching,
      isOldDomain,
      isDarkTheme,
    } = this.state;
    const userContext = this.context;
    const vertical = direction === "bottom" || direction === "top";
    // const { pathname } = this.props.location;

    return (
      <UserContext.Consumer>
        {(context) => (
          <ThemeProvider theme={context.isDarkTheme ? darkTheme : lightTheme}>
            <GlobalStyles />
            <Page>
              {userContext.isLogin ? (
                <Sidebar.Pushable>
                  {vertical ? null : (
                    <VerticalSidebar
                      onClick={this.handleAnimationChange("slide along")}
                      animation={animation}
                      direction={direction}
                      visible={visible}
                      logout={this.logout}
                    />
                  )}

                  <Sidebar.Pusher dimmed={dimmed && visible}>
                    <MenuToggle
                      className="menu-toggle"
                      onClick={this.handleAnimationChange("slide along")}
                    >
                      <Icon name="bars" />
                    </MenuToggle>

                    <PageContainer context={context}>
                      {/*<Segment basic>*/}
                      <Route exact path="/" component={Home} />
                      <Route exact path="/app" component={AppTicket} />
                      <Route exact path="/jira" component={Jira} />
                      <Route exact path="/incident" component={Incident} />
                      <Route exact path="/live/:date?" component={Live} />
                      <Route
                        exact
                        path="/updateTicket"
                        component={UpdateTicket}
                      />
                      <Route exact path="/translate" component={Translate} />
                      <Route exact path="/awsStatus" component={Aws} />
                      <Route exact path="/jenkinsStatus" component={Jenkins} />
                      <Route exact path="/grouping" component={Grouping} />
                      <Route exact path="/worksheet" component={Worksheet} />
                      <Route
                        exact
                        path="/timeline/:project?"
                        component={Timeline}
                      />
                      <Route
                        exact
                        path="/supportForm"
                        component={SupportForm}
                      />
                      <Route exact path="/calendar" component={WhyzeCalendar} />
                      {/*</Segment>*/}
                    </PageContainer>
                  </Sidebar.Pusher>
                </Sidebar.Pushable>
              ) : isFetching ? (
                <Fetching />
              ) : (
                <Login toggleLoginState={this.toggleLoginState} />
              )}
            </Page>
          </ThemeProvider>
        )}
      </UserContext.Consumer>
    );
  }
}

App.contextType = UserContext;

export default withRouter(App);
