import * as React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import UserContext from "../../Context";
import axios from "axios";

class Worksheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      worksheetAvailable: false
    };
  }
  async componentDidMount() {
    const userContext = this.context;
    const username = userContext.username;
    const that = this;
    await axios
      .get(`/api/checkWorksheetAvailable?username=${username}`)
      .then(function(response) {
        const data = response && response.data;
        const { worksheetAvailable } = data;
        that.setState({
          worksheetAvailable
        });
      });
  }

  render() {
    const { worksheetAvailable } = this.state;
    if (!worksheetAvailable) return null;
    return (
      <Link className="dashboard-item" to="/worksheet">
        <div className="label">
          Worksheet
          <div className="desc">To check progress on each Developer</div>
        </div>
        <Icon name="file alternate outline" />
      </Link>
    );
  }
}

Worksheet.contextType = UserContext;

export default Worksheet;
