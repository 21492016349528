import * as React from "react";
import Ajax from "../../Public/Ajax";
import UserContext from "../../../Context";
import cogoToast from "cogo-toast";
import moment from "moment";
import _ from "lodash";
import { Label, Table, Tab, Icon, Button, Popup } from "semantic-ui-react";
import { Loading } from "../../Public/Styled";

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      renderQueue: [],
      activeTabIndex: 0,
      activeText: "",
      isFetching: true,
      syncing: false,
      sheetId: ""
    };
  }
  doFetch = async date => {
    const self = this;
    const token = localStorage.getItem("token");
    const userContext = this.context;
    const username = userContext.username;
    // const { api, extraParams } = this.props;
    await Ajax(`/api/listTicketsByTeams?username=${username}`, {
      method: "GET",
      params: {
        date: date,
        token: token
        // ...extraParams
      }
    })
      .then(async function(response) {
        const data = response.data;

        self.setState({
          data: data,
          sheetId: response.sheetId,
          isFetching: false
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  syncWorkSheet = async ({ name, tickets, weeks }) => {
    const self = this;
    const userContext = this.context;
    await Ajax(`/api/google/setWorkSheet`, {
      method: "POST",
      data: {
        name: name,
        username: userContext.username,
        tickets: tickets,
        weeks: weeks
        // date: date,
        // token: token
        // ...extraParams
      }
    })
      .then(function(response) {
        const { message } = response;
        cogoToast.success(message);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  syncAll = async () => {
    const { data, activeTabIndex, activeText } = this.state;
    const self = this;
    const userContext = this.context;
    this.setState({
      syncing: true
    });
    await Ajax(`/api/google/syncAllWorkSheet`, {
      method: "POST",
      data: {
        username: userContext.username,
        tickets: data[activeTabIndex],
        weeks: activeText
      }
    })
      .then(function(response) {
        const { message } = response;
        cogoToast.success(message);
        self.setState({
          syncing: false
        });
      })
      .catch(function(error) {
        // console.log(error);
      });
  };

  async componentDidMount() {
    const date = moment().format("YYYY-M-D");
    await this.doFetch(date);

    let currentDate = moment().format("YYYY-M-D");
    const weeks = this.state.data.map((item, index) => {
      let dateOfWeeks = item.date;
      const startDate = moment(dateOfWeeks.split(" - ")[0], "YYYY-M-D");
      const endDate = moment(dateOfWeeks.split(" - ")[1], "YYYY-M-D");
      const currentActive = moment(currentDate).isBetween(
        startDate,
        endDate,
        null,
        "[]"
      );
      const tabDisplayTitle = `${moment(startDate).format(
        "DD MMM "
      )} - ${moment(endDate).format("DD MMM")}`;

      if (currentActive) {
        console.log(index);
        this.setState({
          activeTabIndex: index,
          activeText: tabDisplayTitle
        });
      }

      let assignees = item.data
        .sort((a, b) => {
          if (b.assignee > a.assignee) {
            return -1;
          }
          if (a.assignee > b.assignee) {
            return 1;
          }
          return 0;
        })
        .map(assignee => {
          const tickets = assignee.ticket.map(ticket => {
            const backgroundColor = {
              background: ticket.details.color
            };
            const ticketUrl =
              "https://aquariux.atlassian.net/browse/" + ticket.key;
            return (
              <>
                <span className="single-ticket">
                  <span style={backgroundColor} className="circle">
                    &nbsp;
                  </span>
                  <Label
                    style={{
                      borderRadius: "25px",
                      marginRight: "5px"
                    }}
                    image
                  >
                    <img src={ticket.details.projectAvatarUrl} />
                    <span>{ticket.key}</span>
                  </Label>
                  <a href={ticketUrl} target="_blank">
                    {ticket.summary}
                  </a>
                  <br />
                </span>
              </>
            );
          });

          return (
            <>
              <Table.Body>
                <Table.Row className="listing-row">
                  <Table.Cell verticalAlign="top">
                    <Label className="dev-label">{assignee.assignee}</Label>
                    <Label className="ticket-no">
                      <span className="ticket-content">
                        {assignee.ticket.length}
                      </span>
                    </Label>
                    <Popup
                      content="This will sync with current your worksheet activities to Google Sheet"
                      position="bottom left"
                      trigger={
                        <Button
                          icon
                          size="mini"
                          color="primary"
                          onClick={() =>
                            this.syncWorkSheet({
                              name: assignee.assignee,
                              tickets: assignee.ticket,
                              weeks: tabDisplayTitle
                            })
                          }
                        >
                          <Icon name="sync" />
                        </Button>
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>{tickets}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </>
          );
        });
      let renderTable;
      if (item.data.length === 0) {
        renderTable = (
          <div key={index} className="listing-content">
            <Icon name="smile outline" /> No tickets
          </div>
        );
      } else {
        renderTable = (
          <div key={index} className="listing-content">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>Assignee</Table.HeaderCell>
                  <Table.HeaderCell>Current Tickets</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {assignees}
            </Table>
          </div>
        );
      }
      return {
        menuItem: `${tabDisplayTitle}`,
        render: () => <Tab.Pane key={item.date}>{renderTable}</Tab.Pane>
      };
    });

    this.setState({
      renderQueue: weeks
    });
  }

  handleChange = (e, data) => {
    const { activeIndex, panes } = data;
    const { menuItem } = panes[activeIndex];
    this.setState({
      activeTabIndex: activeIndex,
      activeText: menuItem
    });
  };

  render() {
    const {
      renderQueue,
      activeTabIndex,
      sheetId,
      isFetching,
      activeText,
      syncing
    } = this.state;
    const panes = renderQueue;
    return (
      <>
        {/*Today's date: {currentDate} <br />*/}
        {isFetching ? (
          <Loading>
            {Array.from(Array(10).keys()).map((item, i) => {
              return <div className="loading-item" key={i} />;
            })}
          </Loading>
        ) : (
          <>
            <div
              style={{
                position: "absolute",
                right: 20,
                top: 23,
                display: "flex",
                alignItems: "center"
              }}
            >
              <Button
                color="blue"
                className="sheet-shortcut"
                disabled={syncing}
                onClick={this.syncAll}
              >
                <Icon name="sync" loading={syncing} />
                Sync all
              </Button>
              <Button
                color="green"
                className="sheet-shortcut"
                href={`https://docs.google.com/spreadsheets/d/${sheetId}`}
                target="_blank"
              >
                <Icon name="google" />
                Worksheet
              </Button>
            </div>

            <Tab
              menu={{ pointing: true }}
              panes={panes}
              // defaultActiveIndex={0}
              activeIndex={activeTabIndex}
              onTabChange={(e, data) => this.handleChange(e, data)}
            />
          </>
        )}
      </>
    );
  }
}

List.contextType = UserContext;

export default List;
