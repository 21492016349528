import styled from "styled-components";

export const WorksheetContainer = styled.div`
  padding: 20px;
  box-sizing: border-box;
  .jenkins-wrapper {
    width: 1200px;
    margin: auto;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
    .tab-wrapper {
      border-bottom: 0;
      display: flex;
      align-items: center;
      .tab-item {
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }
  .listing-row {
    margin: 0 auto 20px;
    .listing-label {
      font-weight: bold;
      font-size: 25px;
      margin: 20px 0;
    }
  }
    .listing-content {
      line-height: 30px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
  }
      .ticket-no {
        background: #ab414f;
        border-radius: 50%;
        color: #ffffff;
        margin-left: -15px;
        margin-top: -10px;
        width: 20px;
        height: 20px;
        padding: 6px 0;
        font-size: 9px;
        position: absolute;
        text-align: center;
      }
      .single-ticket {
        flex-direction: column;
        width: 100%;
        position: relative;
        .ticket-name {
          /* background-color: #dddddd; */
          padding: 3px 5px;
          margin: 0 5px;
          border-radius: 10px;
          font-weight: bold;
        }
        .circle {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: inline-block;
          position: absolute;
          bottom: 4px;
          right: -14px;
        }
      }
`;
