import * as React from "react";
import { Header, UserDropdown, UserDropdownMenu, Santa } from "./Styled";
import UserContext from "../../Context";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Snow from "./snow";
import CNY from "./CNY";
import DarkModeToggle from "./DarkModeToggle";

class PageHeader extends React.Component {
  state = {
    username: "",
    count: 1,
    showSnow: false,
    showFireworks: false,
    showCNY: false,
  };
  componentDidMount() {
    const userContext = this.context;
    // this.bindMouseMoveEvent();
    if (userContext) {
      this.setState({
        username: userContext.username,
      });
    }
  }

  bindMouseMoveEvent = () => {
    const hoverEffect = document.querySelector(".hover-effect");
    document.querySelector(".logo").addEventListener("mousemove", function (e) {
      const top = e.clientY - 15;
      const left = e.clientX - 15;
      hoverEffect.style.top = top + "px";
      hoverEffect.style.left = left + "px";
    });
  };

  logout = () => {
    const userContext = this.context;
    userContext.toggleLoginState(false);
  };

  easter = () => {
    const { count } = this.state;
    let tempCount = count;
    if (tempCount > 7) {
      // swal({
      //   content: "img",
      //   attributes: {
      //     src: "http://www.dacoromania.org/upload/o/48/481747_rage-meme-png.png",
      //   },
      // });
      // swal({
      //   closeOnClickOutside: false,
      //   content: {
      //     element: "img",
      //     attributes: {
      //       src: easterImg,
      //       style: "width: 300px",
      //     },
      //   },
      //   text: "Y U Do this ?",
      // });
      tempCount = 0;
    } else {
      tempCount += 1;
    }
    this.setState({
      count: tempCount,
    });
  };

  showSnow = (e) => {
    e.preventDefault();
    this.setState({
      showSnow: !this.state.showSnow,
    });
  };

  showFireworks = (e) => {
    e.preventDefault();
    this.setState({
      showFireworks: !this.state.showFireworks,
    });
  };

  showCNY = (e) => {
    e.preventDefault();
    this.setState({
      showCNY: !this.state.showCNY,
    });
  };

  toggleDarkTheme = () => {
    this.context.toggleDarkTheme();
  };

  render() {
    const { children } = this.props;
    const { username, count, showSnow, showFireworks, showCNY } = this.state;
    return (
      <>
        {showSnow && (
          <>
            <Snow />
            <Santa />
          </>
        )}
        {/*{showFireworks && (*/}
        {/*  <div className="pyro">*/}
        {/*    <div className="before" />*/}
        {/*    <div className="after" />*/}
        {/*  </div>*/}
        {/*)}*/}

        {showCNY && <CNY />}
        <Header>
          <Link to="/" className="logo">
            {/*<div className="dropdown">*/}
            {/*  <button onClick={(e) => this.showSnow(e)}>*/}
            {/*    {showSnow ? "Clear" : "Make"} <Icon name="snowflake" />*/}
            {/*  </button>*/}
            {/*</div>*/}
            {/*<div className="dropdown">*/}
            {/*  <button onClick={e => this.showFireworks(e)}>*/}
            {/*    {showFireworks ? "Clear" : "Make"}{" "}*/}
            {/*    <Icon name="fire extinguisher" />*/}
            {/*  </button>*/}
            {/*</div>*/}
            {/*<div className="dropdown">*/}
            {/*  <button onClick={e => this.showCNY(e)}>*/}
            {/*    {showFireworks ? "Clear" : "Make"}{" "}*/}
            {/*    <img src={fu} style={{ width: 16 }} />*/}
            {/*  </button>*/}
            {/*</div>*/}
          </Link>
          <span className="hover-effect" />
          {children}
          <UserDropdown>
            {username}
            <Icon name="angle down" />
            <UserDropdownMenu className="user-menu">
              <a className="user-menu-item" onClick={() => this.logout()}>
                Logout
              </a>
            </UserDropdownMenu>
          </UserDropdown>
          <DarkModeToggle
            isDarkTheme={this.context.isDarkTheme}
            toggleDarkTheme={this.toggleDarkTheme}
          />
        </Header>
      </>
    );
  }
}

PageHeader.contextType = UserContext;

export default PageHeader;
