import * as React from "react";
import { Icon, Segment } from "semantic-ui-react";
import DevFilterButton from "./DevFilterButton";

const reduceFn = (acc, curr) => {
  return curr[1] === true ? acc.concat(curr) : acc;
};

const ListFilter = props => {
  const {
    devMap,
    filterFunc,
    filterFuncSingle,
    filtered,
    list,
    resetFilterPreventEventDefault
  } = props;

  return (
    <Segment vertical>
      {(Object.entries(devMap).reduce(reduceFn, []).length > 0 || filtered) && (
        <span className="reset-btn" onClick={resetFilterPreventEventDefault}>
          <strong>Reset</strong>
          <Icon name="refresh" />
        </span>
      )}
      {list.map(item => {
        return (
          <DevFilterButton
            key={item.key}
            selected={devMap[item.key] && devMap[item.key] === true}
            filterFunc={filterFunc}
            filterFuncSingle={filterFuncSingle}
            isOnline={item && item.isOnline}
            item={item}
          />
        );
      })}
    </Segment>
  );
};

export default ListFilter;
