export const firebaseConfig = {
  apiKey: "AIzaSyBJDbkQ0AWmwxD2UXA0xU7OpnoDBzzw4tg",
  authDomain: "orion-dev-to.firebaseapp.com",
  databaseURL: "https://orion-dev-to.firebaseio.com",
  projectId: "orion-dev-to",
  storageBucket: "orion-dev-to.appspot.com",
  messagingSenderId: "638878219254",
  appId: "1:638878219254:web:116d8d7b10f7ea235c65d7"
};

/**
 * Temp for testing
 * @type {{storageBucket: string, apiKey: string, messagingSenderId: string, appId: string, projectId: string, databaseURL: string, authDomain: string}}
 */
export const firebaseConfig2 = {
  apiKey: "AIzaSyAF_iLTJvSBr-OO9C2cjPlJifzA7kUt-Sg",
  authDomain: "orion-dev-tools-2.firebaseapp.com",
  databaseURL: "https://orion-dev-tools-2.firebaseio.com",
  projectId: "orion-dev-tools-2",
  storageBucket: "orion-dev-tools-2.appspot.com",
  messagingSenderId: "855415732515",
  appId: "1:855415732515:web:9d797160bd514358433496"
};
