import { firebaseConfig, firebaseConfig2 } from "../Firebase/credentials";
import * as React from "react";
const firebase = require("firebase");

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

/**
 * Get firebase collection
 * @type {firebase.firestore.CollectionReference}
 */

export const listOnlineUsers = async callback => {
  const ref = firebase.database().ref("onlineUsers");

  // Attach an asynchronous callback to read the data at our posts reference
  await ref.on(
    "value",
    function(snapshot) {
      // console.log(snapshot.val());
      callback(snapshot.val());
    },
    function(errorObject) {
      console.log("The read failed: " + errorObject.code);
    }
  );
};

export const addOnlineUser = async username => {
  await firebase
    .database()
    .ref("onlineUsers/" + btoa(username))
    .set({
      username: username,
      lastLogin: new Date().toString()
    });
};

export const removeOnlineUsers = async username => {
  const userRef = firebase
    .database()
    .ref("onlineUsers/" + btoa(username));
  userRef
    .remove()
    .then(function() {
      console.log("Remove succeeded.");
    })
    .catch(function(error) {
      console.log("Remove failed: " + error.message);
    });
};

class OnlineUsers extends React.Component {
  render() {
    return <span>Online</span>;
  }
}

export default OnlineUsers;
