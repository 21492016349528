import * as React from "react";
import { Label } from "semantic-ui-react";

const labelStyle = { userSelect: "none", borderRadius: "25px" };
const imgStyle = { borderRadius: "50%", background: "#fff" };

const ProjectFilterButton = props => {
  const {
    filterFunc,
    filterFuncSingle,
    item,
    projectAvatarUrl,
    selected
  } = props;
  return (
    <Label
      color={selected ? "blue" : null}
      className="dev-label"
      as="a"
      image
      onClick={e => {
        e.preventDefault();
        filterFunc(item);
      }}
      style={labelStyle}
    >
      <img
        onClick={e => {
          e.stopPropagation();
          filterFuncSingle(item);
        }}
        className="hover-thing"
        src={projectAvatarUrl}
        style={imgStyle}
      />
      {item}
    </Label>
  );
};

export default ProjectFilterButton;
