import * as React from "react";
import Listing from "../Public/Listing";

class Incident extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const api = "/api/incident";
    return (
      <Listing
        api={api}
        extraParams={{ maxResult: 300 }}
        title="Incident Issues"
      />
    );
  }
}
export default Incident;
