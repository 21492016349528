import * as React from "react";
import PageHeader from "../Header";
import { Container, Icon } from "semantic-ui-react";
import JiraForm from "./form";
import { withRouter } from "react-router-dom";

class AppTicket extends React.Component {
  componentDidMount() {
    document.title = "App Version Update";
  }
  render() {
    return (
      <>
        <PageHeader>
          <span className="title">App Version Update</span>
        </PageHeader>
        <Container>
          <JiraForm />
        </Container>
      </>
    );
  }
}

export default withRouter(AppTicket);
