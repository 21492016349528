import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import cogoToast from "cogo-toast";
import { mainUrl } from "../../util";
import { Label } from "semantic-ui-react";
import ResolutionBadge from "./ResolutionBadge";
import MergeRequestBadge from "./MergeRequestBadge";
import EmoStickman from "./EmoStickman";

const MainIssue = (props) => {
  const {
    index,
    value,
    filterFunc,
    filterAssignee,
    solo,
    isSolod,
    overflow,
  } = props;
  const mainKey = value.key;
  const mainTitle = value.title;
  const mainAssignee = value.assignee;
  const mainUsername = value.username;
  const issues = value.issue;

  const total = issues.length;
  const count = index;

  return (
    value &&
    issues.map((value, index, i) => {
      const {
        assignee,
        username,
        status,
        color,
        userInfo,
        resolution,
        mrLink,
        projectAvatarUrl,
      } = value.details;

      return (
        <tr id={mainKey} key={index}>
          {index === 0 && (
            <>
              <td
                onClick={() => solo(mainKey)}
                rowSpan={total}
                className={`solo-td no-padding ${
                  isSolod ? "solo-td-solod" : ""
                }`}
              />
              <td
                rowSpan={total}
                className={`striped ${count === 0 ? "first" : ""}`}
              >
                <a
                  href={`${mainUrl}${mainKey}`}
                  target="_blank"
                  className="main-issue"
                >
                  {mainKey} - {mainTitle}
                </a>
                <span
                  className="avatar"
                  onClick={(e) => {
                    e.preventDefault();
                    filterAssignee(mainAssignee);
                  }}
                >
                  {mainAssignee}
                </span>
                {overflow && <EmoStickman mainKey={mainKey} />}
              </td>
            </>
          )}

          <td
            className={`${color} ${
              index === issues.length - 1 ? "striped" : ""
            } ${count === 0 ? "first" : ""}`}
          >
            <div className="sub-issue" key={index + value}>
              <a
                className="text"
                href={`${mainUrl}${value.key}`}
                target="_blank"
              >
                {value.key}
                <span>-</span>
                {value.summary}
              </a>

              <span className="copy-func">
                {/*<CopyToClipboard*/}
                {/*  text={`| ${value.key} - ${value.summary} | !${*/}
                {/*    mrLink ? mrLink.split("/").pop() : ""*/}
                {/*  } | @${userInfo.key} |`}*/}
                {/*  onCopy={() => {*/}
                {/*    cogoToast.success("Copied!");*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <i class="copy outline icon"></i>*/}
                {/*</CopyToClipboard>*/}
              </span>

              {mrLink && (
                <span className="merge-request">
                  <a href={mrLink} target="_blank">
                    <MergeRequestBadge fill="#333" />
                  </a>
                </span>
              )}

              <ResolutionBadge resolution={resolution} status={status} />
              {/*<span className="status">{status}</span>*/}
            </div>
          </td>
          <td
            className={`${color} ${
              index === issues.length - 1 ? "striped" : ""
            } ${count === 0 ? "first" : ""}`}
          >
            <Label
              className="dev-label"
              as="a"
              image
              onClick={(e) => {
                e.preventDefault();
                filterFunc(assignee);
              }}
              style={{
                userSelect: "none",
                borderRadius: "25px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <img
                src={projectAvatarUrl}
                style={{ borderRadius: "50%", background: "#fff" }}
              />
              {assignee}
            </Label>
          </td>
          {/*<td className={color}>{status}</td>*/}
        </tr>
      );
    })
  );
};

export default MainIssue;
