import * as React from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

const height = 30;

const DarkModeButton = styled("span")`
  height: ${height}px;
  width: ${height * 2 - 4}px;
  margin: 0 12px;
  border-radius: 30px;
  background: ${({theme}) => theme.darkModeButton};
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    outline: none;
    border: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &:checked {
      + label {
        transform: translateX(0) translateY(-50%);
        background-color: #333;
        .button-wrapper {
          transform: translateY(0);
        }
      }
    }
  }
  label {
    position: absolute;
    width: ${height - 4}px;
    height: ${height - 4}px;
    border-radius: 50%;
    background-color: #f1e771;
    transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(100%) translateY(-50%);
    left: 2px;
    top: 50%;
    overflow: hidden;
    pointer-events: none;
    .sun {
      color: #ff8f00;
    }
    .moon {
      color: #ffd500;
    }
    .button-wrapper {
      height: ${(height - 4) * 2}px;
      transform: translateY(-50%);
      transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
      > i {
        width: ${height - 4}px;
        height: ${height - 4}px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

class DarkModeToggle extends React.Component {
  render() {
    const { isDarkTheme, toggleDarkTheme } = this.props;
    return (
      <DarkModeButton>
        <input
          id="darkModeToggle"
          type="checkbox"
          checked={isDarkTheme}
          onChange={toggleDarkTheme}
        />
        <label htmlFor="darkModeToggle">
          {
            <div className="button-wrapper">
              <Icon className="moon" name="moon" />
              <Icon className="sun" name="sun" />
            </div>
          }
        </label>
      </DarkModeButton>
    );
  }
}

export default DarkModeToggle;
