import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const TranslateFooter = styled.div``;

export const TranslateList = styled.div`
  padding: 20px 0;
  height: calc(100vh - 40px);
  overflow: auto;
  box-sizing: border-box;
  .list-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin-top: 10px;
    border-top: 1px solid #ececec;
    //height: 30px;
  }

  .add-new-key {
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: auto;
  }
  .update-modal {
    margin-left: auto;
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    & + .add-new-key {
      margin-left: 4px;
    }
  }
  .online-users {
    display: flex;
    align-items: center;
    z-index: 10;
    cursor: default;
    .online-user-item {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 16px;
      position: relative;
      color: #fff;
      animation: fadeInRight 200ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
      @keyframes fadeInRight {
        from {
          opacity: 0;
          transform: translateX(20%);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
      }
      > strong {
        > b {
          text-transform: lowercase;
        }
      }
      &:hover {
        .desc {
          opacity: 1;
          visibility: visible;
        }
      }
      &:not(:last-child) {
        margin-right: 8px;
      }
      > .desc {
        padding: 4px 6px;
        background-color: #000;
        color: #fff;
        position: absolute;
        top: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
        transition: all 200ms ease-in-out;
        opacity: 0;
        visibility: hidden;
        border-radius: 4px;
        text-transform: initial;
        &:before {
          content: "";
          border: 6px solid transparent;
          border-bottom: 6px solid #000;
          position: absolute;
          top: -12px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    > li {
      //margin: 5px;
      //padding: 10px;
      list-style: none;
      display: flex;
      font-size: 16px;
      min-height: 26px;
      position: relative;
      //min-height: 24px;
      //margin-bottom: 8px;
      &.header {
        background-color: #ececec !important;
        .count {
          background-color: #ececec !important;
          color: #333;
          display: flex;
          align-items: center;
        }
        .translate-label {
          background-color: #ececec !important;
          color: #333;
          display: flex;
          align-items: center;
          .filter {
            margin-left: auto;
            width: 150px;
            height: 20px;
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: 20px;
            margin-right: -15px;
            input {
              background: transparent;
              border: 0;
              outline: none;
              width: 100%;
              height: 100%;
              font-size: 14px;
              padding: 0 12px;
              box-sizing: border-box;
              &::placeholder {
                color: #999;
              }
            }
          }
        }
        .input-wrapper {
          //padding: 0 12px;
          width: 100%;
          .header-input {
            display: flex;
            align-items: center;
            color: #333;
            font-weight: bold;
          }
        }
      }
      &:hover {
        background-color: rgba(222, 235, 255, 0.51);
        .translate-label {
          background-color: rgba(222, 235, 255, 0.51);
        }
      }

      &:last-child {
        border-bottom: 2px solid #ececec;
      }

      .error-message {
        color: red;
        margin-top: 6px;
      }

      &.error {
        background: #ffe4e4;

        .translate-label {
          flex: 1;
        }
        .translate-value {
          flex: 1;
        }
      }
      .count {
        width: 40px;
        border-top: 2px solid #ececec;
        border-left: 2px solid #ececec;
        border-right: 2px solid #ececec;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fafafa;
        color: #999;
        font-size: 14px;
      }
      .translate-label {
        //flex: 1;
        border-top: 2px solid #ececec;
        border-right: 2px solid #ececec;
        padding: 2px 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        background-color: #fafafa;
        font-weight: bold;
        color: #999;
        font-size: 14px;
        flex-basis: 25%;
      }
      .translate-value {
        flex: 1;
        display: flex;
        align-items: center;
        height: auto;
        .input-wrapper {
          height: 100%;
          position: relative;
          box-sizing: border-box;
          border-top: 2px solid #ececec;
          border-right: 2px solid #ececec;
          flex-grow: 1;
          &:not(:last-child) {
            //margin-right: 8px;
          }
          .header-input,
          input,
          textarea {
            border: 0;
            outline: none;
            width: 100%;
            max-width: 100%;
            background: transparent;
            height: 100%;
            max-height: 100%;
            box-sizing: border-box;
            padding: 4px 12px;
            font-size: 14px;
            resize: none;
          }
          &.locked {
            border-width: 2px;
            border-style: solid;
            background-color: #fff;
            margin-left: -2px;
            margin-bottom: -2px;
            position: relative;
            z-index: 10;
            &.non-editable {
              pointer-events: none;
              background-color: #ececec;
            }
          }

          .editor {
            position: absolute;
            transform: translateY(calc(-100% - 2px));
            right: -2px;
            top: 0;
            padding: 0 4px;
            box-sizing: border-box;
            height: 16px;
            font-size: 12px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px 4px 0 0;
            color: #fff;
          }
        }
      }
      .action {
        position: absolute;
        top: 50%;
        right: -26px;
        transform: translateY(-50%);
        color: darkgreen;
      }
    }
  }
`;
