import * as React from "react";
import { useEffect, useState } from "react";
import PageHeader from "../Header";
import {
  Container,
  Icon,
  Label,
  Segment,
  Button,
  Input,
  Form,
  Header,
  Checkbox,
  Modal
} from "semantic-ui-react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Flex, TranslateList, TranslateFooter } from "./Styled";
import {
  createi18n,
  geti18n,
  listi18n,
  setObserver,
  lockInput,
  unlockInput
} from "./liveEdit";
import { debounce } from "lodash";
import { Loading } from "../Public/Styled";
import { listOnlineUsers } from "../Public/OnlineUsers";
import styled from "styled-components";
import { InputContent } from "./InputField";
import { stringToHslColor } from "./util";
import NewKeyInputModal from "./NewKeyInput";
import ContextMenu from "./ContextMenu";
import UpdateModal from "./UpdateModal";

const projectList = [
  { id: "backend3", label: "Back Office" },
  { id: "vietnam", label: "Vietnam" }
];
class Translate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isFetching: true,
      onlineUsers: [],
      searchValue: "",
      isFiltered: false,
      filteredData: null,
      originalData: null,
      popupOpen: false,
      newEnKey: "",
      newZhKey: "",
      newVnKey: "",
      disableNewEnKey: false,
      addingKey: false,
      autoTranslate: false,
      showEnTranslate: false,
      showZhTranslate: false,
      showVnTranslate: false,
      selectedProject: "backend3"
    };
  }

  async componentDidMount() {
    document.title = "Translator";
    const autoTranslate = localStorage.getItem("autoTranslate");
    this.setState({
      autoTranslate: autoTranslate === "true"
    });
    await this.getData();
  }

  syncUpDataWithGitlab = async (en, zh, vn) => {
    const { selectedProject } = this.state;
    console.log(en);
    await createi18n({
      sourceKey: en,
      en: en,
      zh: zh,
      vn: vn,
      project: selectedProject,
      firstInit: true
    });
  };

  translateText = async (text, lang) => {
    return new Promise(async function(resolve) {
      await axios
        .get(`/api/translate?text=${text}&lang=${lang}`)
        .then(response => {
          resolve(response.data);
        });
    });
  };

  generateTranslationFromGoogleSheet = async () => {
    const that = this;
    await axios.get("/api/google/translateSheet").then(res => {
      const data = res.data;

      data.map(item => {
        const en = item.en;
        const cn = item.zh;
        const vn = item.vn;

        that.syncUpDataWithGitlab(en, cn, vn);
      });
    });
  };

  regenerateFromGit = async language => {
    const that = this;
    await axios.get("/api/getTranslateFile?language=" + language).then(res => {
      Object.entries(res.data).map(async (item, index) => {
        const en = item[0];
        const cn = item[1];
        // if (index <= 10) {

        const vn = await that.translateText(en, "en-vi");

        await that.syncUpDataWithGitlab(en, cn, vn);
        // }
      });

      // this.setState({
      //   data: Object.entries(res.data)
      // });
    });
  };

  fetchi18n = async () => {
    const that = this;
    const { selectedProject } = this.state;
    console.log("Fetching");
    await listi18n(function(data) {
      const processedData = Object.entries(data[selectedProject]).map(item => {
        return item[1];
      });
      that.setState({
        data: processedData,
        originalData: processedData,
        isFetching: false
      });
    });
  };

  async getData(language) {
    const { selectedProject } = this.state;
    const that = this;
    if (!language) {
      language = "zh";
    }

    await listOnlineUsers(function(data) {
      const listOfUser = Object.entries(data).map(item => atob(item[0]));
      that.setState({
        onlineUsers: listOfUser
      });
    });

    this.setState({
      data: null
    });

    // this.regenerateFromGit(language);
    // this.generateTranslationFromGoogleSheet()

    await this.fetchi18n();

    // await setObserver(function(data) {
    //   const key = encodeURIComponent(data.en.text);
    //   console.log(data);
    //   that.setState({
    //     data: {
    //       [key]: data
    //     }
    //   });
    // });
  }

  handleSearch = value => {
    const { originalData } = this.state;
    const newData = originalData.filter(item => {
      return (
        (item &&
          item.en &&
          item.en.text.toLowerCase().includes(value.toLowerCase())) ||
        (item &&
          item.zh &&
          item.zh.text.toLowerCase().includes(value.toLowerCase())) ||
        (item &&
          item.vn &&
          item.vn.text.toLowerCase().includes(value.toLowerCase()))
      );
    });
    this.setState({
      searchValue: value,
      data: newData,
      filteredData: newData,
      isFiltered: true
    });
    if (value === "") {
      this.setState({
        isFiltered: false
      });
    }
  };

  handleSelectProject = async currentSelected => {
    await this.fetchi18n();
    this.setState({
      selectedProject: currentSelected
    });
  };

  render() {
    const {
      data,
      isFetching,
      isFiltered,
      onlineUsers,
      originalData,
      filteredData,
      selectedProject
    } = this.state;

    const useData = isFiltered
      ? originalData.filter(item => {
          const originalItem = item;
          return filteredData.find(
            item => item.en.text === originalItem.en.text
          );
        })
      : originalData;
    return (
      <>
        <PageHeader>
          <span className="title">Translator (RC)</span>
        </PageHeader>

        {/*<button onClick={() => this.getData("zh")}>中文</button>*/}
        {/*<button onClick={() => this.getData("en")}>英文</button>*/}
        {/*<button onClick={() => this.getData("vi")}>越南文</button>*/}
        {isFetching ? (
          <Loading>
            {Array.from(Array(10).keys()).map((item, i) => {
              return <div className="loading-item" key={i} />;
            })}
          </Loading>
        ) : (
          <>
            <TranslateList>
              <Container>
                <Flex>
                  <>
                    {projectList.map((item, index) => {
                      return (
                        <Label
                          style={{ cursor: "pointer" }}
                          key={item.id}
                          color={selectedProject === item.id ? "blue" : ""}
                          onClick={() => this.handleSelectProject(item.id)}
                        >
                          {item.label}
                        </Label>
                      );
                    })}
                  </>
                  <Input
                    icon="search"
                    placeholder="Search your name..."
                    // onKeyUp={this.handleSearch}
                    onKeyUp={e => this.handleSearch(e.target.value)}
                    style={{ marginLeft: "auto" }}
                  />
                </Flex>
                <div className="list-wrapper">
                  {onlineUsers && (
                    <div className="online-users">
                      {onlineUsers.map(item => {
                        const nameArr = item.split(".");
                        const name =
                          nameArr.length > 1 ? (
                            <strong>
                              {nameArr[0][0]}
                              {nameArr[1][0]}
                            </strong>
                          ) : (
                            <strong>
                              {nameArr[0][0]}
                              <b>{nameArr[0][1]}</b>
                            </strong>
                          );
                        return (
                          <span
                            key={item}
                            className="online-user-item"
                            style={{
                              background: stringToHslColor(item, 100, 30)
                            }}
                          >
                            {name}
                            <div className="desc">{item}</div>
                          </span>
                        );
                      })}
                    </div>
                  )}
                  <UpdateModal />
                  <NewKeyInputModal originalData={originalData} />
                </div>
                {/*<ContextMenuTrigger id="some_unique_identifier">*/}
                <ul>
                  <li className="header">
                    <div className="count">#</div>
                    <div className="translate-label">
                      English (Key Label)
                      {/*<div className="filter">*/}
                      {/*  <input*/}
                      {/*    type="search"*/}
                      {/*    placeholder="Search for key label"*/}
                      {/*    onChange={e => this.handleSearch(e.target.value)}*/}
                      {/*  />*/}
                      {/*</div>*/}
                    </div>
                    <div className="translate-value">
                      <div className="input-wrapper">
                        <span className="header-input">English</span>
                      </div>
                      <div className="input-wrapper">
                        <span className="header-input">Chinese</span>
                      </div>
                      <div className="input-wrapper">
                        <span className="header-input">Vietnamese</span>
                      </div>
                    </div>
                  </li>
                  {useData ? (
                    useData.map((val, index) => {
                      return (
                        <InputContent
                          key={index}
                          value={val}
                          index={index}
                          isFiltered={isFiltered}
                          selectedProject={selectedProject}
                        />
                      );
                    })
                  ) : (
                    <div>No data</div>
                  )}
                </ul>
                <ContextMenu selectedProject={selectedProject} />
              </Container>
            </TranslateList>
          </>
        )}
      </>
    );
  }
}

export default withRouter(Translate);
