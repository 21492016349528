import * as React from "react";
import PageHeader from "../Header";
import { Container, Icon } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import AWSStatus from "./Status";
import { AWSStatusContainer } from "./Styled";

class AppTicket extends React.Component {
  componentDidMount() {
    document.title = "AWS Status";
  }
  render() {
    return (
      <>
        <PageHeader>
          <span className="title">AWS Status</span>
        </PageHeader>
        <AWSStatusContainer>
          <div className="aws-wrapper">
            <AWSStatus />
          </div>
        </AWSStatusContainer>
      </>
    );
  }
}

export default withRouter(AppTicket);
