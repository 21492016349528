import axios from "axios";
import swal from "sweetalert";

let requestArray = [];
const CancelToken = axios.CancelToken;

axios.defaults.withCredentials = true;

export default async (...args) => {
  let [url, opt] = args;
  if (typeof url !== "string" || typeof opt !== "object")
    throw "Argument type not acceptable";
  let { handle, method } = opt;
  // handle = !isDefined(handle) ? true : handle
  //handle = _.isBoolean(handle) && !handle ? false : true
  method = method ? method : "GET";

  const token = localStorage.getItem("token");
  const bearer = localStorage.getItem("bearer");

  opt.cancelable = opt.cancelable ? opt.cancelable : true;
  opt.method = method;
  opt.headers = {
    "Content-Type": "application/json",
    // Cookie: `token=${cipheredToken};`
    token: token,
    Authorization: `Bearer ${bearer}`
  };
  opt.withCredentials = true;
  delete opt.handle;
  //delete opt.method
  //delete opt.data
  //console.log(opt)
  if (opt.cancelable) {
    opt.cancelToken = new CancelToken(function executor(c) {
      requestArray.push(c);
    });
  }

  try {
    const result = await axios(url, opt);
    const data = await result.data;
    return data;
  } catch (err) {
    const { response } = err;
    if (handle && response) {
      const {
        status,
        data: { message }
      } = response;
      if (status !== 200) {
        await swal({
          title: "错误",
          text: message ? message : status.toString(),
          icon: "warning"
        });
      }
    }
    throw err;
  }
};
