import * as React from "react";
import {
  Button,
  Checkbox,
  Form,
  Header,
  Icon,
  Input,
  Modal,
  Message
} from "semantic-ui-react";
import styled from "styled-components";
import { createi18n } from "./liveEdit";
import axios from "axios";
import { processColon } from "./util";
import Help from "./Help";

const InputField = styled(Input)`
  .ui.button {
    display: none;
    &:first-of-type {
      margin-left: 4px;
    }
  }
  &.auto-translate {
    .ui.button {
      display: none;
    }
  }
  &.manual-translate {
    &:hover {
      .ui.button {
        display: block;
      }
    }
  }
`;

class NewKeyInputModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceKey: "",
      newEnKey: "",
      newZhKey: "",
      newVnKey: "",
      disableNewEnKey: false,
      disableNewZhKey: false,
      disableNewVnKey: false,
      addingKey: false,
      autoTranslate: false,
      showEnTranslate: false,
      showZhTranslate: false,
      showVnTranslate: false,
      disableInput: false,
      duplicateError: false,
      selectedProject: "backend3"
    };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleChangeKey = (e, { value }) =>
    this.setState({ newEnKey: processColon(value), sourceKey: value });

  handleBlur = async type => {
    const {
      newEnKey,
      newZhKey,
      newVnKey,
      disableInput,
      autoTranslate
    } = this.state;
    let english, chinese, vietnamese;

    if (autoTranslate) {
      this.setState({
        disableInput: true
      });
      switch (type) {
        case "en":
          if (newEnKey === "") return;
          chinese = await this.translate(newEnKey, "en-zh");
          vietnamese = await this.translate(newEnKey, "en-vi");

          break;
        case "zh":
          if (newZhKey === "") return;
          english = await this.translate(newZhKey, "zh-en");
          vietnamese = await this.translate(newZhKey, "zh-vi");
          break;
        case "vn":
          if (newVnKey === "") return;
          english = await this.translate(newVnKey, "vi-en");
          chinese = await this.translate(newVnKey, "vi-zh");
          break;
        default:
          break;
      }
    }

    this.setState({
      newEnKey: english || newEnKey,
      newZhKey: chinese || newZhKey,
      newVnKey: vietnamese || newVnKey,
      disableInput: false
    });
  };

  translate = async (text, lang) => {
    return new Promise(async function(resolve) {
      await axios
        .get(`/api/translate?text=${text}&lang=${lang}`)
        .then(response => {
          resolve(response.data);
        });
    });
  };

  saveNewKey = async () => {
    const { originalData } = this.props;
    const {
      sourceKey,
      newEnKey,
      newZhKey,
      newVnKey,
      selectedProject
    } = this.state;
    const that = this;
    if (newEnKey === "") return;

    let duplicated = await originalData.find(
      item => item && item.en && item.en.text === sourceKey
    );

    that.setState({
      duplicateError: duplicated
    });

    if (!duplicated) {
      this.setState({
        addingKey: true
      });

      await createi18n({
        sourceKey,
        en: newEnKey,
        zh: newZhKey,
        vn: newVnKey,
        project: selectedProject
      });

      this.setState({
        sourceKey: "",
        newEnKey: "",
        newZhKey: "",
        newVnKey: "",
        addingKey: false,
        popupOpen: false,
        duplicateError: false
      });
    }
  };

  handleOpen = () => {
    this.setState({ popupOpen: true });
  };

  handleClose = () => {
    this.setState({ popupOpen: false });
  };

  componentDidMount() {
    const autoTranslate = localStorage.getItem("autoTranslate");
    if (autoTranslate) {
      this.setState({
        autoTranslate: autoTranslate === "true"
      });
    }
  }

  render() {
    const {
      popupOpen,
      newEnKey,
      newZhKey,
      newVnKey,
      disableInput,
      addingKey,
      autoTranslate,
      sourceKey,
      duplicateError
    } = this.state;

    return (
      <Modal
        closeIcon
        trigger={
          <Button
            color="blue"
            className="add-new-key"
            onClick={this.handleOpen}
          >
            <Icon name="add" />
            Add
          </Button>
        }
        open={popupOpen}
        onClose={this.handleClose}
        size="small"
      >
        <Header style={{ display: "flex" }}>
          <h3>Add a new key</h3>
          <Checkbox
            style={{
              marginLeft: "auto"
            }}
            label="Auto translate"
            toggle
            checked={autoTranslate}
            onChange={() => {
              this.setState({
                autoTranslate: !autoTranslate
              });
              localStorage.setItem("autoTranslate", !autoTranslate);
            }}
          />
        </Header>
        <Modal.Content>
          <Help />
          <Form autoComplete="off">
            <Form.Field>
              <h4>English (Key)</h4>
              <InputField
                className={
                  autoTranslate ? "auto-translate" : "manual-translate"
                }
                loading={disableInput}
                disabled={disableInput}
                placeholder="Prefix:Hello"
                name="newEnKey"
                value={sourceKey}
                onChange={this.handleChangeKey}
                onBlur={() => {
                  if (newEnKey === "") return;
                  return this.handleBlur("en");
                }}
              >
                <input />
                <input disabled value={newEnKey} />
                <Button
                  onClick={async () => {
                    if (newEnKey === "") return;
                    this.setState({
                      newZhKey: await this.translate(newEnKey, "en-zh")
                    });
                  }}
                >
                  中文
                </Button>
                <Button
                  onClick={async () => {
                    if (newEnKey === "") return;
                    this.setState({
                      newVnKey: await this.translate(newEnKey, "en-vi")
                    });
                  }}
                >
                  Tiếng Việt
                </Button>
              </InputField>
              {duplicateError && (
                <Message color="red">{`"${newEnKey}" was duplicated`}</Message>
              )}
            </Form.Field>
            <Form.Field>
              <h4>Chinese</h4>
              <InputField
                className={
                  autoTranslate ? "auto-translate" : "manual-translate"
                }
                loading={disableInput}
                disabled={disableInput}
                placeholder="你好"
                name="newZhKey"
                value={newZhKey}
                onChange={this.handleChange}
                onBlur={() => {
                  if (newZhKey === "") return;
                  return this.handleBlur("zh");
                }}
              >
                <input />
                <Button
                  onClick={async () => {
                    if (newZhKey === "") return;
                    this.setState({
                      newEnKey: await this.translate(newZhKey, "zh-en")
                    });
                  }}
                >
                  English
                </Button>
                <Button
                  onClick={async () => {
                    if (newZhKey === "") return;
                    this.setState({
                      newVnKey: await this.translate(newZhKey, "zh-vi")
                    });
                  }}
                >
                  Tiếng Việt
                </Button>
              </InputField>
            </Form.Field>
            <Form.Field>
              <h4>Vietnamese</h4>
              <InputField
                className={
                  autoTranslate ? "auto-translate" : "manual-translate"
                }
                loading={disableInput}
                disabled={disableInput}
                placeholder="Xin chào"
                name="newVnKey"
                value={newVnKey}
                onChange={this.handleChange}
                onBlur={() => {
                  if (newVnKey === "") return;
                  return this.handleBlur("vn");
                }}
              >
                <input />
                <Button
                  onClick={async () => {
                    if (newVnKey === "") return;
                    this.setState({
                      newEnKey: await this.translate(newVnKey, "vi-en")
                    });
                  }}
                >
                  English
                </Button>
                <Button
                  onClick={async () => {
                    if (newVnKey === "") return;
                    this.setState({
                      newZhKey: await this.translate(newVnKey, "vi-zh")
                    });
                  }}
                >
                  中文
                </Button>
              </InputField>
            </Form.Field>
            <Form.Field>
              <Button
                onClick={this.saveNewKey}
                primary
                loading={disableInput || addingKey}
                disabled={disableInput || addingKey}
                type="submit"
              >
                Add
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    newEnKey: "",
                    newZhKey: "",
                    newVnKey: "",
                    duplicateError: false
                  });
                }}
                loading={disableInput || addingKey}
                disabled={disableInput || addingKey}
                type="clear"
              >
                Clear
              </Button>
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default NewKeyInputModal;
