import styled from "styled-components";

export const LoginWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //background: linear-gradient(to bottom, #0747a6, #549eff);
  background: linear-gradient(to bottom, #001b53, #00132f);

  > form {
    width: 250px;
    //background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    //border-radius: 8px;
    //padding: 20px;
    box-sizing: border-box;
    @media screen and (max-width: 400px) {
      width: 80%;
    }
  }

  > canvas {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .logo-wrapper {
    margin: 0 auto 10px;
    position: relative;
    user-select: none;
    padding-bottom: 15px;

    &:before {
      content: "";
      width: 4px;
      height: 18px;
      background-color: #fff;
      position: absolute;
      font-size: 36px;
      font-weight: bold;
      color: #fff;
      right: -30px;
      top: 0;
      animation: 2s scaleIn cubic-bezier(0.4, 0, 0.2, 1) forwards;
      box-shadow: -2px -4px 24px #2185d0;
    }

    &:after {
      content: "";
      width: 18px;
      height: 4px;
      background-color: #fff;
      position: absolute;
      font-size: 36px;
      font-weight: bold;
      color: #fff;
      right: -37px;
      top: 7px;
      animation: 2s scaleIn cubic-bezier(0.4, 0, 0.2, 1) forwards;
      box-shadow: 2px 4px 24px #2185d0;
    }

    @keyframes movingShadow {
      0% {
        box-shadow: 2px 4px 24px #2185d0;
      }
      25% {
        box-shadow: 2px -4px 24px #2185d0;
      }
      50% {
        box-shadow: -2px -4px 24px #2185d0;
      }
      75% {
        box-shadow: -2px 4px 24px #2185d0;
      }
      100% {
        box-shadow: 2px 4px 24px #2185d0;
      }
    }
    @keyframes scaleIn {
      from {
        opacity: 0;
        transform: scale(2) rotate(180deg);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }

    .main-logo {
      display: block;
    }

    .logo-text {
      display: block;
      text-align: center;
      text-transform: uppercase;
      //margin-top: -5px;
      padding-top: 5px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: bold;
      color: #ccc;
    }
  }
`;

export const LoadingText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  box-sizing: border-box;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  h3 {
    margin: 0;
    font-size: 14px;
    text-align: center;
    display: block;
  }
  .support-text {
    font-size: 12px;
    color: #ccc;
  }
  .countdown-timer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: #2185d0;
    transition: all 100ms ease-in-out;
  }
`;
