import styled from "styled-components";
import { injectGlobal, keyframes } from "styled-components";

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 100px;
`;

export const DetailsWrapper = styled.div`
  //padding: 20px 0;
  box-sizing: border-box;
`;

const deletingAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
    max-height: 500px;
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
    max-height: 0;
  }
`;

export const MainWrapper = styled.div`
  position: relative;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 20px;
  &.deleting {
    animation: ${deletingAnimation} 300ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }

  &:hover {
    .delete-issue {
      opacity: 1;
      visibility: visible;
    }
  }
  .delete-issue {
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    border: 0;
    outline: 0;
    background-color: #ff7b74;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -10px;
    color: #fff;
    padding: 0;
    font-size: 16px;
    top: 35px;
    border-radius: 4px;
    transition: all 200ms ease-in-out;
    > i {
      margin: 0;
    }
  }
  .ticket-type {
    position: absolute;
    top: -4px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -10px;
    padding: 4px 8px;
    background: #0747a6;
    color: #deebff;
    font-weight: bold;
    &:before {
      content: "";
      position: absolute;
      top: 25px;
      right: 0;
      border-top: 5px solid #2684ff;
      border-right: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid #2684ff;
    }
  }
`;

export const LabelTitle = styled.label`
  color: #0747a6;
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding: 20px;
  box-sizing: border-box;
  display: block;
  background-color: #deebff;
`;

export const SummaryWrapper = styled.div`
  padding: 20px;
`;
