export const mainUrl = "https://aquariux.atlassian.net/browse/";

export const RESOLUTION_WONT_DO = "10001";
export const DUPLICATED = "10002";
export const CANNOT_REPRODUCE = "10003";
export const QA_PASSED = "10010";
export const ONLY_IN_SIT = "10101";
export const DECLINED = "10200";
export const RESOLUTION_DONE = "10000";

// TODO: map more resolutions
export const renderResolutionText = resolution => {
  switch (resolution) {
    case RESOLUTION_WONT_DO:
      return "Won't Do";
    case RESOLUTION_DONE:
      return "Done";
    case DUPLICATED:
      return "Duplicated";
    case CANNOT_REPRODUCE:
      return "Cannot Reproduce";
    case ONLY_IN_SIT:
      return "Only in SIT";
    case QA_PASSED:
      return "QA Passed";
    case DECLINED:
      return "Declined";
    default:
      return null;
  }
};

export const parseListOfTicketsForProjects = listOfMainTickets => {
  if (!listOfMainTickets) return [];
  // object to store all project keys found
  const projectKeyObj = {};
  listOfMainTickets.forEach(mainTicket => {
    mainTicket.issue.forEach(subIssue => {
      const project = subIssue.key.split("-")[0];
      if (!projectKeyObj[project]) {
        projectKeyObj[project] = {
          projectAvatarUrl: subIssue.details.projectAvatarUrl
        };
      }
    });
  });
  return projectKeyObj;
};
