import styled from "styled-components";

export const SupportFormContainer = styled.div`
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  .sub-header {
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }
  > .btn {
    margin-bottom: -36px;
  }
  //.jenkins-wrapper {
  //  width: 1200px;
  //  margin: auto;
  //  @media screen and (max-width: 1200px) {
  //    width: 100%;
  //  }
  //  .tab-wrapper {
  //    border-bottom: 0;
  //    display: flex;
  //    align-items: center;
  //    .tab-item {
  //      cursor: pointer;
  //      white-space: nowrap;
  //    }
  //  }
  //}
`;

const borderRadius = 4;
export const TableWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  table {
    margin: 1rem;
    width: calc((100% / 5) - 2rem);
    //border: 1px solid #ececec;
    border-radius: ${borderRadius}px;
    box-sizing: border-box;
    border-collapse: collapse;
    //background-color: #fff;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
    thead {
      tr {
        &:first-child {
          th {
            background-color: #deebff;
            &:first-child {
              border-top-left-radius: ${borderRadius}px;
            }
            &:last-child {
              border-top-right-radius: ${borderRadius}px;
            }
          }
        }
      }
    }
    tbody {
      tr {
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: ${borderRadius}px;
            }
            &:last-child {
              border-bottom-right-radius: ${borderRadius}px;
            }
          }
        }
      }
    }
    th,
    td {
      text-align: left;
      font-size: 1em;
      height: 2em;
      padding: 0.5rem;
      box-sizing: border-box;
      overflow: hidden;
    }
    td {
      color: #666;
      background-color: #fff;
    }
  }
`;
