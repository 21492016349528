import * as React from "react";
import {
  Button,
  Segment,
  Grid,
  Divider,
  Header,
  Icon,
  Modal
} from "semantic-ui-react";
import axios from "axios";
import cogoToast from "cogo-toast";

const updateArr = [
  {
    name: "SIT",
    platform: "SIT"
  },
  {
    name: "UAT",
    platform: "UAT"
  },
  { name: "PROD", platform: "production" }
];

class UpdateModal extends React.Component {
  state = {
    popupOpen: false
  };
  handleOpen = () => {
    this.setState({ popupOpen: true });
  };

  handleClose = () => {
    this.setState({ popupOpen: false });
  };

  handleUpdate = async ({ platform, lang, project }) => {
    await axios
      .get(
        `api/i18n/update?lang=${lang}&project=${project}&platform=${platform}`
      )
      .then(function(data) {
        cogoToast.success(`Updated ${project} ${platform} ${lang}`);
      });
  };

  handleMerge = async ({ source, destination, lang, project }) => {
    await axios
      .get(
        `api/i18n/merge?lang=${lang}&project=${project}&source=${source}&destination=${destination}`
      )
      .then(function(data) {
        cogoToast.success(`Merged ${lang} from ${source} to ${destination} for ${project}`);
      });
  };

  render() {
    const { popupOpen } = this.state;
    const project = "backend3";
    return (
      <Modal
        closeIcon
        trigger={
          <Button
            color="blue"
            className="update-modal"
            onClick={this.handleOpen}
          >
            <Icon name="refresh" />
            Update
          </Button>
        }
        open={popupOpen}
        onClose={this.handleClose}
        size="small"
      >
        <Header style={{ display: "flex" }}>
          <h3>Update or Merge</h3>
        </Header>
        <Modal.Content>
          <Segment placeholder>
            <Grid columns={2} stackable textAlign="center">
              <Divider vertical>Or</Divider>

              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  <Segment.Group>
                    {updateArr.map(item => {
                      return (
                        <Segment key={item.name}>
                          <h3>Update to {item.name}</h3>
                          <Button.Group>
                            <Button
                              onClick={() =>
                                this.handleUpdate({
                                  platform: item.platform,
                                  lang: "en",
                                  project: project
                                })
                              }
                            >
                              English
                            </Button>
                            <Button.Or />
                            <Button
                              onClick={() =>
                                this.handleUpdate({
                                  platform: item.platform,
                                  lang: "zh",
                                  project: project
                                })
                              }
                            >
                              中文
                            </Button>
                            <Button.Or />
                            <Button
                              onClick={() =>
                                this.handleUpdate({
                                  platform: item.platform,
                                  lang: "vn",
                                  project: project
                                })
                              }
                            >
                              Tiếng Việt
                            </Button>
                          </Button.Group>
                        </Segment>
                      );
                    })}
                  </Segment.Group>
                </Grid.Column>

                <Grid.Column>
                  <Segment.Group>
                    <Segment>
                      <h3>Merge SIT to UAT</h3>
                      <Button.Group>
                        <Button
                          onClick={() =>
                            this.handleMerge({
                              source: "SIT",
                              destination: "UAT",
                              lang: "en",
                              project: "backend3"
                            })
                          }
                        >
                          English
                        </Button>
                        <Button.Or />
                        <Button
                          onClick={() =>
                            this.handleMerge({
                              source: "SIT",
                              destination: "UAT",
                              lang: "zh",
                              project: "backend3"
                            })
                          }
                        >
                          中文
                        </Button>
                        <Button.Or />
                        <Button
                          onClick={() =>
                            this.handleMerge({
                              source: "SIT",
                              destination: "UAT",
                              lang: "vn",
                              project: "backend3"
                            })
                          }
                        >
                          Tiếng Việt
                        </Button>
                      </Button.Group>
                    </Segment>
                    <Segment>
                      <h3>Merge SIT to PROD</h3>
                      <Button.Group>
                        <Button
                          onClick={() =>
                            this.handleMerge({
                              source: "SIT",
                              destination: "production",
                              lang: "en",
                              project: "backend3"
                            })
                          }
                        >
                          English
                        </Button>
                        <Button.Or />
                        <Button
                          onClick={() =>
                            this.handleMerge({
                              source: "SIT",
                              destination: "production",
                              lang: "zh",
                              project: "backend3"
                            })
                          }
                        >
                          中文
                        </Button>
                        <Button.Or />
                        <Button
                          onClick={() =>
                            this.handleMerge({
                              source: "SIT",
                              destination: "production",
                              lang: "vn",
                              project: "backend3"
                            })
                          }
                        >
                          Tiếng Việt
                        </Button>
                      </Button.Group>
                    </Segment>
                  </Segment.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }
}

export default UpdateModal;
