import React from "react";
import PIXICloth from "./PIXICloth";
import styled from "styled-components";

const fireCracker = require("./img/CNY/fire-cracker.gif");
const hamster = require("./img/CNY/rat.png");

const CNYWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 10;
  .fire-cracker {
    position: absolute;
    width: 200px;
    top: 20px;
    left: 40px;
    animation: dropdown-firecracker 600ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
  .fire-cracker-2 {
    position: absolute;
    width: 200px;
    top: 20px;
    right: 40px;
    animation: dropdown-firecracker 800ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
  .rat {
    position: absolute;
    top: 10%;
    left: 40%;
    transform: translateX(-50%);
    width: 200px;
    z-index: -1;
    animation: ratShaking 10ms infinite alternate;
  }
  @keyframes ratShaking {
    from {
      transform: translate(calc(-50% - 4px), 1px);
    }
    from {
      transform: translate(calc(-50% + 4px), -1px);
    }
  }
  @keyframes dropdown-firecracker {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

class CNY extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <CNYWrapper>
        <PIXICloth />
        <img src={hamster} className="rat" />
        <img src={fireCracker} className="fire-cracker" />
        <img src={fireCracker} className="fire-cracker-2" />
      </CNYWrapper>
    );
  }
}
export default CNY;
