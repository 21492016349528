import * as React from "react";
import { Segment } from "semantic-ui-react";
import ProjectFilterButton from "./ProjectFilterButton";

const ListFilterProject = props => {
  const { filterFunc, filterFuncSingle, list, projectMap } = props;
  return (
    <Segment vertical>
      {Object.entries(list).map(([projectKey, values]) => {
        return (
          <ProjectFilterButton
            projectAvatarUrl={values.projectAvatarUrl}
            key={projectKey}
            selected={projectMap[projectKey] && projectMap[projectKey] === true}
            filterFunc={filterFunc}
            filterFuncSingle={filterFuncSingle}
            item={projectKey}
          />
        );
      })}
    </Segment>
  );
};
export default ListFilterProject;
