export const lightTheme = {
  body: "#f7faff",
  text: "#363537",
  dashboardItem: "#fff",
  dashboardItemHover: "#f0fdff",
  grey: "#666",
  lightGrey: "#aaa",
  loadingBg: "#f5f5f5",
  tableBorderColor: "rgba(34,36,38,.1)",
  labelColor: "#0052cc",
  tableHeaderBg: "#f9fafb",
  darkModeButton: "#ccc",
  textBrightness: "none",
  avatarBg: "#fffdf0",
  avatarColor: "#0052cc",
  segmentBg: "#fff",
  textColor: "rgba(0,0,0,.87)",
  awsSuccessBg: "rgba(234, 244, 230, 1)",
  awsFailBg: "rgba(245, 223, 223, 1)",
  segmentBorderColor: "#d4d4d5",
  selectBg: "rgba(0,0,0,.03)",
  iconFilter: "initial"
};

export const darkTheme = {
  body: "#121212",
  text: "#FFFFFF",
  grey: "#fff",
  lightGrey: "#fff",
  loadingBg: "#333",
  labelColor: "#ffffff",
  tableBorderColor: "#222",
  tableHeaderBg: "#222",
  darkModeButton: "#000",
  dashboardItem: "#212121",
  dashboardItemHover: "#2b2b2b",
  headerBg: "#000000",
  buttonBgColor: "#222",
  buttonHoverBgColor: "#0747a6",
  tonalColor: "#BB86FC",
  textBrightness: "brightness(2.0)",
  avatarBg: "#222",
  avatarColor: "#fff",
  segmentBg: "#222",
  textColor: "#fff",
  stepBg: "#212121",
  stepSecondBg: "#333",
  inputBackground: "#333",
  awsSuccessBg: "rgba(234, 244, 230, 0.3)",
  awsFailBg: "rgba(245, 223, 223,0.3)",
  segmentBorderColor: "#333",
  selectBg: "rgba(255,255,255,.03)",
  iconFilter: "invert(1)"
};
