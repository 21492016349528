import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }
  .ui.image.label,
  .ui.label {
    color: ${({ theme }) => theme.text};
    cursor: pointer;
    background-color: ${({ theme }) => theme.buttonBgColor};
    &:hover {
      background-color: ${({ theme }) => theme.buttonHoverBgColor};
      color: ${({ theme }) => theme.text};
      img {
        opacity: 1;
      }
    }
    &.ticket-no {
      color: #fff;
    }
    img {
      opacity: 0.8;
    }
  }
  .ui.blue.label {
    background-color: ${({ theme }) => theme.buttonHoverBgColor} !important;
  }

  .solo-td {
    width: 10px !important;
    background-color: ${({ theme }) => theme.darkModeButton};
    &:hover {
      background-color: ${({ theme }) => theme.dashboardItemHover};
    }
    &:active {
      background-color: ${({ theme }) => theme.avatarColor};
    }
  }
  .solo-td-solod {
    background-color: ${({ theme }) => theme.avatarColor};
  }

  .ui.menu .active.item {
    color: ${({ theme }) => theme.textColor};
  }

  .ui.selection.dropdown {
    background: ${({ theme }) => theme.segmentBg};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.segmentBorderColor};
  }

  .ui.selection.visible.dropdown > .text:not(.default) {
    color: ${({ theme }) => theme.textColor};
  }

  .ui.selection.dropdown .menu > .item {
    border-top-color: ${({ theme }) => theme.segmentBorderColor};
  }

  .ui.dropdown .menu .selected.item,
  .ui.dropdown.selected {
    background: ${({ theme }) => theme.selectBg};
    color: ${({ theme }) => theme.textColor};
  }

  .ui.dropdown .menu > .item:hover {
    color: ${({ theme }) => theme.textColor};
  }

  .ui.dropdown .menu {
    background: ${({ theme }) => theme.segmentBg};
  }

  .ui.dropdown .menu > .item {
    color: ${({ theme }) => theme.textColor};
  }

  .ui.segment {
    background: ${({ theme }) => theme.segmentBg};
    color: ${({ theme }) => theme.textColor};
  }

  .ui.tabular.menu {
    border-bottom-color: ${({ theme }) => theme.segmentBorderColor};
  }

  .ui.attached.tabular.menu .item {
    color: ${({ theme }) => theme.textColor};
  }

  .ui.attached.tabular.menu .item.active {
    background: ${({ theme }) => theme.segmentBg};
    border-color: ${({ theme }) => theme.segmentBorderColor};
  }

  .ui.attached.segment {
    border-color: ${({ theme }) => theme.segmentBorderColor};
  }

  .ui.form {
    .field {
      & > label {
        color: ${({ theme }) => theme.textColor};
      }
    }
    input[type="text"] {
      background: ${({ theme }) => theme.inputBackground};
    }
    input[type="acronym"] {
      background: ${({ theme }) => theme.inputBackground};
    }
    input[type="officialurl"] {
      background: ${({ theme }) => theme.inputBackground};
    }
  }

  .ui.checkbox {
    label {
      color: ${({ theme }) => theme.textColor};
    }
    & + label {
      color: ${({ theme }) => theme.textColor};
    }
  }

  .ui.toggle.checkbox {
    input:checked ~ .box {
      color: ${({ theme }) => theme.textColor} !important;
    }
    input:checked ~ label {
      color: ${({ theme }) => theme.textColor} !important;
    }
  }

  .ui.menu {
    background: ${({ theme }) => theme.segmentBg};
    color: ${({ theme }) => theme.textColor};
    a.item {
      color: ${({ theme }) => theme.textColor};
      &:hover {
        color: ${({ theme }) => theme.textColor};
        background: ${({ theme }) => theme.buttonHoverBgColor};
      }
    }
  }

  .ui.menu .active.item {
    color: ${({ theme }) => theme.textColor};
    background: ${({ theme }) => theme.buttonHoverBgColor};
    font-weight: bold;
    &:hover {
      color: ${({ theme }) => theme.textColor};
      background: ${({ theme }) => theme.buttonHoverBgColor};
    }
  }

  .ui.steps {
    .step.active {
      background: ${({ theme }) => theme.stepBg};
      color: ${({ theme }) => theme.textColor};
      .title {
        filter: ${({ theme }) => theme.textBrightness};
      }
      .icon {
        color: ${({ theme }) => theme.textColor};
      }
      &:after {
        background: ${({ theme }) => theme.stepBg};
      }
    }
    .step {
      background: ${({ theme }) => theme.stepSecondBg};
      color: ${({ theme }) => theme.textColor};
      .description {
        color: ${({ theme }) => theme.textColor};
      }
    }
  }

  .ui.table {
    background: ${({ theme }) => theme.headerBg};
    a {
      filter: ${({ theme }) => theme.textBrightness};
    }
    tbody:nth-child(even) {
      background: ${({ theme }) => theme.body} !important;
    }
    tbody:nth-child(odd) {
      background: ${({ theme }) => theme.dashboardItem} !important;
    }
  }

  .ui.table thead th {
    background: ${({ theme }) => theme.tableHeaderBg} !important;
    filter: ${({ theme }) => theme.textColor} !important;
  }
  .listing-content th {
    background: ${({ theme }) => theme.headerBg} !important;
    color: ${({ theme }) => theme.textColor} !important;
  }
  .handsontable th, .handsontable td {
    color: #000;
  }
`;
