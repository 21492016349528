import * as React from "react";
import PageHeader from "../Header";
import { Container, Icon } from "semantic-ui-react";
import JiraForm from "./form";
import { withRouter } from "react-router-dom";

class UpdateTicket extends React.Component {
  componentDidMount() {
    document.title = "Update Ticket";
  }
  render() {
    return (
      <>
        <PageHeader>
          <span className="title">Update Ticket</span>
        </PageHeader>
        <Container>
          <JiraForm />
        </Container>
      </>
    );
  }
}

export default withRouter(UpdateTicket);
