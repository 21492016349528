import * as React from "react";
import PageHeader from "../Header";
import { SupportFormContainer, TableWrapper } from "./Styled";
import {
  Button,
  Icon,
  Input,
  Select,
  Segment,
  Modal,
  Image,
  Tab,
  Message
} from "semantic-ui-react";
import UserContext from "../../Context";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.css";
import axios from "axios";
import cogoToast from "cogo-toast";

const allowedUser = ["eeling.yam", "mason"];

const tutorialGif = require("./tutorial.gif");

const monthsMap = [
  { key: "January", value: "January", text: "January" },
  { key: "February", value: "February", text: "February" },
  { key: "March", value: "March", text: "March" },
  { key: "April", value: "April", text: "April" },
  { key: "May", value: "May", text: "May" },
  { key: "June", value: "June", text: "June" },
  { key: "July", value: "July", text: "July" },
  { key: "August", value: "August", text: "August" },
  { key: "September", value: "September", text: "September" },
  { key: "October", value: "October", text: "October" },
  { key: "November", value: "November", text: "November" },
  { key: "December", value: "December", text: "December" }
];

const InfoMessage = () => {
  return (
    <Message color="blue">
      Should there be any{" "}
      <strong>public holidays that happen to fall on a weekday</strong>, please
      replace the days in the header with an “H”, eg. “Tu” to “H”
      <br />
      *Please indicate your respective duty columns with <strong>“S”</strong>.
    </Message>
  );
};

class SupportForm extends React.Component {
  constructor(props) {
    super(props);
    this.handsontableData = Handsontable.helper.createEmptySpreadsheetData(
      30,
      32
    );
    this.handsontableData2 = Handsontable.helper.createEmptySpreadsheetData(
      30,
      32
    );
    const currentMonthIndex = new Date().getMonth();
    this.state = {
      currentMonth:
        monthsMap[
          currentMonthIndex === 0 ? monthsMap.length - 1 : currentMonthIndex - 1
        ].value,
      currentMonth2: monthsMap[currentMonthIndex].value,
      popupOpen: true,
      department: ""
    };
  }

  handleClose = () => {
    this.setState({
      popupOpen: false
    });
  };

  getDepartment = username => {
    axios.get("/api/getDepartment?name=" + username).then(res => {
      const department = res.data.department;
      this.setState({
        department: department
      });
    });
  };

  async componentDidMount() {
    document.title = "SupportForm";

    const userContext = this.context;

    this.setState({
      currentUser: userContext.username
    });
    this.getDepartment(userContext.username);
  }

  generatePdf = data => {
    const { department } = this.state;
    axios
      .post("/api/generatePDF?department=" + department, data)
      .then(function(response) {
        let pdfWindow = window.open("");
        pdfWindow.document.write(
          "<html style='margin: 0'><body style='margin: 0'><iframe width='100%' height='100%' style='border: 0;outline: none' frameborder='0' src='" +
            encodeURI(response.data) +
            "'></iframe></body></html>"
        );
        // if (response.data.error) {
        //   cogoToast.error("Login failed");
        // } else {
        //   self.setState({
        //     currSteps: nextStep,
        //     token: btoa(`${username}:${password}`)
        //   });
        //   cogoToast.success("Login success");
        // }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  getData = () => {
    const data = this.handsontableData;
    const reducedData = data.reduce((acc, item, index) => {
      const name = item[0];
      const newArr = item.filter((it, ind) => ind >= 26);
      newArr.unshift(name);
      acc.push(newArr);
      return acc;
    }, []);
    const data2 = this.handsontableData2;
    const reducedData2 = data2.reduce((acc, item, index) => {
      const newArr = item.filter((it, ind) => ind <= 25);
      acc.push(newArr);
      return acc;
    }, []);
    const { currentMonth, currentMonth2 } = this.state;
    // const data = mockData;
    const weeks = reducedData[1];
    const weeks2 = reducedData2[1];

    const weekends = weeks.reduce((acc, item, index) => {
      if (item.includes("Su") || item.includes("Sa") || item.includes("H")) {
        acc.push(index);
      }
      return acc;
    }, []);

    const weekends2 = weeks2.reduce((acc, item, index) => {
      if (item.includes("Su") || item.includes("Sa") || item.includes("H")) {
        acc.push(index);
      }
      return acc;
    }, []);

    /**
     * Data of Week 1
     * @type {any}
     */
    const sortedData = reducedData.reduce((acc, item, index) => {
      const hasDuty = item.filter(item => item); // To filter out team
      /**
       * Because name located at the first column
       * date at the end of the array
       * so index !== date
       * need to extract this according to specific index
       */
      const dates = reducedData[0];
      const dutyDate = item.reduce((acc, item, index) => {
        if (
          item !== "" &&
          item === "S" &&
          weekends.find(item => item === index)
        ) {
          const isPublicHoliday = weeks[index] === "H";
          console.log(weeks, index);
          acc.push({
            date: dates[index],
            isPublicHoliday: isPublicHoliday,
            month: {
              text: currentMonth,
              value:
                monthsMap.findIndex(item => item.value === currentMonth) + 1
            }
          });
        }
        return acc;
      }, []);

      if (index >= 2 && hasDuty.length > 1 && dutyDate.length !== 0) {
        const name = item[0];
        acc.push({
          name: name,
          month: {
            text: currentMonth,
            value: monthsMap.findIndex(item => item.value === currentMonth) + 1
          },
          year: new Date().getFullYear(),
          totalDuty: dutyDate.length,
          dutyDate: dutyDate
        });
      }

      return acc;
    }, []);

    /**
     * Data of Week 2
     * @type {any}
     */
    const sortedData2 = reducedData2.reduce((acc, item, index) => {
      const hasDuty = item.filter(item => item); // To filter out team
      const dutyDate = item.reduce((acc, item, index) => {
        if (
          item !== "" &&
          item === "S" &&
          weekends2.find(item => item === index)
        ) {
          const isPublicHoliday = weeks2[index] === "H";
          acc.push({
            date: index,
            isPublicHoliday: isPublicHoliday,
            month: {
              text: currentMonth2,
              value:
                monthsMap.findIndex(item => item.value === currentMonth2) + 1
            }
          });
        }
        return acc;
      }, []);
      if (index >= 2 && hasDuty.length > 1 && dutyDate.length !== 0) {
        const name = item[0];
        acc.push({
          name: name,
          month: {
            text: currentMonth2,
            value: monthsMap.findIndex(item => item.value === currentMonth2) + 1
          },
          year: new Date().getFullYear(),
          totalDuty: dutyDate.length,
          dutyDate: dutyDate
        });
      }

      return acc;
    }, []);

    let uniqueName = [];
    let combinedFilterArray = [];
    const combinedArray = [...sortedData, ...sortedData2];

    combinedArray.filter(item => {
      const name = item.name;
      if (uniqueName.find(item => item === name)) {
        const existingData = combinedFilterArray.find(
          item => item.name === name
        );
        const dutyDate = existingData.dutyDate;
        const combinedDutyDate = [...dutyDate, ...item.dutyDate];

        existingData.totalDuty = combinedDutyDate.length;
        existingData.dutyDate = combinedDutyDate;
      } else {
        uniqueName.push(name);
        combinedFilterArray.push(item);
      }
    });

    console.log(combinedFilterArray);

    this.generatePdf(combinedFilterArray);
  };

  handleChange = (e, { value }, name) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    const { currentMonth, currentMonth2, popupOpen } = this.state;
    return (
      <>
        <PageHeader>
          <span className="title">Support Report Generator</span>
        </PageHeader>
        <SupportFormContainer>
          <Message color="blue">
            As finance’s closing falls on the{" "}
            <strong>26th of each month</strong>, kindly make sure to copy 2
            consecutive months of duty sheet before then
          </Message>
          <Button
            className="btn"
            onClick={this.getData}
            color="blue"
            floated={"right"}
          >
            Generate
          </Button>
          <Tab
            panes={[
              {
                menuItem: currentMonth,
                render: () => (
                  <Tab.Pane>
                    <InfoMessage />
                    <Segment style={{ zIndex: 1000 }}>
                      <Select
                        placeholder="Select month"
                        options={monthsMap}
                        defaultValue={currentMonth}
                        value={currentMonth}
                        onChange={(e, value) =>
                          this.handleChange(e, value, "currentMonth")
                        }
                      />
                    </Segment>
                    <HotTable
                      id="hot"
                      data={this.handsontableData}
                      colHeaders={true}
                      rowHeaders={true}
                    />
                  </Tab.Pane>
                )
              },
              {
                menuItem: currentMonth2,
                render: () => (
                  <Tab.Pane>
                    <InfoMessage />
                    <Segment style={{ zIndex: 1000 }}>
                      <Select
                        placeholder="Select month"
                        options={monthsMap}
                        defaultValue={currentMonth2}
                        value={currentMonth2}
                        onChange={(e, value) =>
                          this.handleChange(e, value, "currentMonth2")
                        }
                      />
                    </Segment>
                    <HotTable
                      id="hot2"
                      data={this.handsontableData2}
                      colHeaders={true}
                      rowHeaders={true}
                    />
                  </Tab.Pane>
                )
              }
            ]}
          />
        </SupportFormContainer>
        <Modal closeIcon open={popupOpen} onClose={this.handleClose}>
          <Modal.Header>Simple user guide</Modal.Header>
          <Modal.Content>
            <Image src={tutorialGif} fluid />
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

SupportForm.contextType = UserContext;

export default SupportForm;
