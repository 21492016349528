import styled from "styled-components";

export const Fetching = styled.div`
  min-height: 100%;
  width: 100%;
  position: relative;
`;

export const Page = styled.div`
  min-height: 100%;
  width: 100%;
  position: relative;
  .pusher {
    min-height: 100vh;
  }

  .sidebar {
    .item {
      &.logout {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
      a {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      @media screen and (min-width: 400px) {
        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
`;

export const MenuToggle = styled.button`
  cursor: pointer;
  width: 52px;
  height: 52px;
  border: 0;
  outline: none;
  background: transparent;
  border-radius: 0;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  @media screen and (min-width: 400px) {
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`;

export const PageContainer = styled.div`
  /* background: #fff; */
  background: ${({ theme }) => theme.body};
  //height: calc(100vh - 40px);
  //padding-top: 40px;
`;

export const DashBoardContaier = styled.div`
  //padding: 20px;
  //display: grid;
  //grid-column-gap: 8px;
  //grid-row-gap: 8px;
  //grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  //grid-template-rows: auto;

  padding: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.body};
  @media screen and (max-width: 400px) {
    //grid-template-columns: 50% 50%;
  }
  .sub-title {
    color: ${({ theme }) => theme.text};
    font-size: 1rem;
    margin-left: 10px;
  }
  .home-wrapper {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 30px;
  }
  .dashboard-item {
    display: flex;
    //flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc((100% / 4) - 8px);
    height: 160px;
    cursor: pointer;
    //border-radius: 5px;
    //border: 1px solid rgba(34, 36, 38, 0.1);
    box-sizing: border-box;
    transition: all 200ms ease-in-out;
    align-items: center;
    justify-content: center;
    //flex-direction: column;
    margin: 0 4px 8px;
    background-color: ${({ theme }) => theme.dashboardItem};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    padding: 0 20px;
    @media screen and (max-width: 1200px) {
      flex-basis: calc((100% / 2) - 8px);
    }
    @media screen and (max-width: 600px) {
      height: 100px;
      flex-basis: 100%;
    }
    &:hover {
      background-color: ${({ theme }) => theme.dashboardItemHover};
    }
    > i {
      font-size: 50px;
      line-height: 50px;
      opacity: 0.4;
      position: relative;
      color: ${({ theme }) => theme.grey};
      margin-left: 8px;
      //margin-top: auto;
    }
    > img {
      width: 55px;
      opacity: 0.8;
      position: relative;
      filter: grayscale(1);
      margin-left: 8px;
      //margin-top: auto;
    }
    .label {
      //margin-top: auto;
      //display: flex;
      //align-items: center;
      //justify-content: center;
      //border-top: 1px solid rgba(34, 36, 38, 0.1);
      //width: 100%;
      height: 50px;
      font-weight: bold;
      font-size: 14px;
      margin-right: auto;
      color: ${({ theme }) => theme.labelColor};
      .desc {
        color: ${({ theme }) => theme.lightGrey};
        font-size: 12px;
      }
    }
  }
`;

export const WorkspaceWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  .workspace-item {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc((100% / 4) - 8px);
    height: 160px;
    cursor: pointer;
    //border-radius: 5px;
    //border: 1px solid rgba(34, 36, 38, 0.1);
    box-sizing: border-box;
    transition: all 200ms ease-in-out;
    align-items: center;
    justify-content: center;
    //flex-direction: column;
    margin: 0 4px 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    padding: 0 20px;
    @media screen and (max-width: 1200px) {
      flex-basis: calc((100% / 2) - 8px);
    }
    @media screen and (max-width: 600px) {
      height: 100px;
      flex-basis: 100%;
    }
    &:hover {
      background-color: #deebff;
    }
    > i {
      font-size: 50px;
      line-height: 50px;
      opacity: 0.4;
      position: relative;
      color: #666;
      margin-left: 8px;
      //margin-top: auto;
    }
    .label {
      //margin-top: auto;
      //display: flex;
      //align-items: center;
      //justify-content: center;
      //border-top: 1px solid rgba(34, 36, 38, 0.1);
      //width: 100%;
      height: 50px;
      font-weight: bold;
      font-size: 14px;
      margin-right: auto;
      .desc {
        color: #aaa;
        font-size: 12px;
      }
    }
  }
`;
