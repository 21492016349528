import * as React from "react";
import Listing from "../Public/Listing";

class Live extends React.Component {
  render() {
    const api = "/api/search";
    return (
      <Listing
        showDatePicker
        api={api}
        title="Weekly Deployment Issues"
        showAlarm={true}
      />
    );
  }
}
export default Live;
