import * as React from "react";
import { Label } from "semantic-ui-react";

const labelStyle = { userSelect: "none", borderRadius: "25px" };
const imgStyle = { borderRadius: "50%", background: "#fff" };

const DevFilterButton = props => {
  const { item, selected, filterFunc, filterFuncSingle, isOnline } = props;
  const username = item.key;
  const displayName = item.value && item.value.displayName;
  const avatarUrls = item.value && item.value.avatarUrls;

  return (
    <Label
      color={selected ? "blue" : null}
      key={username}
      className={`dev-label ${isOnline ? "online" : ""}`}
      as="a"
      image
      onClick={e => {
        e.preventDefault();
        filterFunc(username);
      }}
      style={labelStyle}
    >
      <img
        onClick={e => {
          e.stopPropagation();
          filterFuncSingle(username);
        }}
        className="hover-thing"
        src={avatarUrls && avatarUrls["48x48"]}
        style={imgStyle}
      />
      {displayName}
    </Label>
  );
};

export default React.memo(DevFilterButton);
