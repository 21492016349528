import {
  GanttComponent,
  Inject,
  Resize,
  Toolbar,
  Sort,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-gantt";
import React, { useState } from "react";
import { JIRA_BASE_URL } from "../Public/Links";

function Gantt({ tasks }) {
  const [taskFields, setTaskFields] = useState({
    id: "TaskID",
    name: "TaskName",
    startDate: "StartDate",
    duration: "Duration",
    progress: "Progress",
    child: "subtasks",
  });

  /**
   category: "FINGAME-9 [FINGAME] FINGAME Credit - Member Design"
   colorIndex: 0
   fromDate: null
   key: "FINGAME-9"
   name: "Start - SIT"
   statusColorIndex: 0
   statusColorName: "green"
   statusText: "Done"
   toDate: null
   */

  const gridTemplate = (props) => {
    const { TaskID } = props;
    return (
      <a href={`${JIRA_BASE_URL}/browse/${TaskID}`} target="_blank">
        {TaskID}
      </a>
    );
  };

  return (
    <GanttComponent
      style={{
        marginTop: 20,
      }}
      dataSource={tasks}
      height={window.innerHeight - 140}
      taskFields={taskFields}
      editSettings={{
        allowEditing: false,
      }}
      splitterSettings={{
        columnIndex: 4,
      }}
      toolbar={["ZoomIn", "ZoomOut", "ZoomToFit"]}
      allowResizing
      allowSorting
      includeWeekend
    >
      <ColumnsDirective>
        <ColumnDirective
          field="TaskID"
          headerText="Issue Key"
          width="150"
          template={gridTemplate}
        />
        <ColumnDirective
          field="TaskName"
          headerText="Task Name"
          minWidth="250"
          width="280"
        />
        <ColumnDirective field="StartDate" width="100" />
        <ColumnDirective field="Duration" width="100" />
        <ColumnDirective field="Progress" width="100" />
      </ColumnsDirective>
      <Inject services={[Resize, Toolbar, Sort]} />
    </GanttComponent>
  );
}

export default Gantt;
