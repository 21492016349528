import * as React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import {
  Button,
  Checkbox,
  Form,
  Step,
  Segment,
  Icon,
  Message,
  Transition
} from "semantic-ui-react";
import axios from "axios";
import cogoToast from "cogo-toast";

import {
  Header,
  FormWrapper,
  DetailsWrapper,
  MainWrapper,
  LabelTitle,
  SummaryWrapper
} from "./Styled";
import PoweredByJira from "../../Public/PoweredByJira";

export default class JiraForm extends React.Component {
  state = {
    mainTicket: "",
    date: {
      sit: "",
      uat: "",
      prod: ""
    },
    displayDate: {
      sit: "",
      uat: "",
      prod: ""
    },
    token: "",
    isFetching: false
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  updateJiraTicket = async () => {
    const { mainTicket, date } = this.state;
    const self = this;
    const token = localStorage.getItem("token");

    await axios
      .get("/api/updateIssue", {
        params: {
          issue: mainTicket,
          prodDate: date.prod,
          sitDate: date.sit,
          uatDate: date.uat,
          token: token
        }
      })
      .then(function(response) {
        self.setState({
          isFetching: false
        });

        if (response && response.success) {
          cogoToast.error(response);
        } else {
          cogoToast.success("Successfully updated Jira Ticket");
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleSubmit = () => {
    const { mainTicket } = this.state;

    if (mainTicket) {
      this.setState({
        isFetching: true
      });
      this.updateJiraTicket();
    } else {
      cogoToast.error("Please fill in all the details");
    }
  };

  handleDateChange = (selectedDate, key) => {
    const { date, displayDate } = this.state;
    const formattedDate = selectedDate &&  moment(selectedDate).format("YYYY-MM-DD") || "";

    this.setState({
      date: {
        ...date,
        [key]: formattedDate
      },
      displayDate: {
        ...displayDate,
        [key]: selectedDate
      }
    });
  };

  render() {
    const { mainTicket, displayDate, isFetching } = this.state;
    return (
      <FormWrapper style={{ width: 500, margin: "auto" }}>
        <Segment attached>
          <Form onSubmit={this.handleSubmit}>
            <Form.Field>
              <Form.Input
                label={"Main Ticket"}
                placeholder="XIANJIN-1234"
                name="mainTicket"
                value={mainTicket}
                onChange={this.handleChange}
              />
            </Form.Field>

            <Form.Field>
              <label>SIT Date</label>
              <div className="ui input">
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  selected={displayDate.sit}
                  popperPlacement="top-end"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px"
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                      boundariesElement: "viewport"
                    }
                  }}
                  onChange={e => this.handleDateChange(e, "sit")}
                />
              </div>
            </Form.Field>

            <Form.Field>
              <label>UAT Date</label>
              <div className="ui input">
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  selected={displayDate.uat}
                  popperPlacement="top-end"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px"
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                      boundariesElement: "viewport"
                    }
                  }}
                  onChange={e => this.handleDateChange(e, "uat")}
                />
              </div>
            </Form.Field>

            <Form.Field>
              <label>Production Date</label>
              <div className="ui input">
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  selected={displayDate.prod}
                  popperPlacement="top-end"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px"
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                      boundariesElement: "viewport"
                    }
                  }}
                  onChange={e => this.handleDateChange(e, "prod")}
                />
              </div>
            </Form.Field>
            <Form.Field>
              <Transition
                visible={!isFetching}
                animation="fade up"
                duration={300}
              >
                <Button
                  primary
                  loading={isFetching}
                  disabled={isFetching}
                  type="submit"
                >
                  Submit
                </Button>
              </Transition>
            </Form.Field>
          </Form>
        </Segment>
        <PoweredByJira />
      </FormWrapper>
    );
  }
}
