import { firebaseConfig, firebaseConfig2 } from "../Firebase/credentials";
const firebase = require("firebase");

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const listGrouping = async callback => {
  const ref = firebase.database().ref("grouping");
  // Attach an asynchronous callback to read the data at our posts reference
  await ref.on(
    "value",
    function(snapshot) {
      // console.log(snapshot.val());
      callback(snapshot.val());
    },
    function(errorObject) {
      console.log("The read failed: " + errorObject.code);
    }
  );
};

export const createGrouping = async data => {
  return await firebase
    .database()
    .ref(`grouping`)
    .set(data);
};
