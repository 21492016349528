import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { lockInput, unlockInput, finaliseDraft } from "./liveEdit";
import { stringToHslColor } from "./util";
import { Icon } from "semantic-ui-react";
import cogoToast from "cogo-toast";
import UserContext from "../../Context";
import { processColon } from "./util";

const pushCredentialUsers = [
  "joanne",
  "jincheng.su",
  "eeling.yam",
  "sally.khoo",
  "jerome.tham",
  "mason"
];

export const InputContent = React.memo(function MyComponent(props) {
  const data = props.value;
  const selectedProject = props.selectedProject;

  const userContext = useContext(UserContext);
  // const currentUser = localStorage.getItem("name");
  const currentUser = userContext.username;

  const [enVal, setEnTVal] = useState(data && data.en && data.en.text);
  const [tVal, setTVal] = useState(data && data.zh && data.zh.text);
  const [tVnVal, setVnTVal] = useState(data && data.vn && data.vn.text);
  const [isDraftZhState, setDraftZhState] = useState(
    data && data.zh && data.zh.action && data.zh.action.isDraft
  );
  const [isDraftVnState, setDraftVnState] = useState(
    data && data.vn && data.vn.action && data.vn.action.isDraft
  );

  const isLocked = data && data.zh && data.zh.action && data.zh.action.editing;
  const isLockedVn =
    data && data.vn && data.vn.action && data.vn.action.editing;

  const isLockedUsername =
    data && data.zh && data.zh.action && data.zh.action.username;

  const isLockedUsernameVn =
    data && data.vn && data.vn.action && data.vn.action.username;

  const enText = data && data.en && data.en.text;
  const zhText = data && data.zh && data.zh.text;
  const vnText = data && data.vn && data.vn.text;

  const isDraftZh = data && data.zh && data.zh.action && data.zh.action.isDraft;
  const isDraftVn = data && data.vn && data.vn.action && data.vn.action.isDraft;

  const isDraft = isDraftZh || isDraftVn;

  const [isDraftState, setDraftState] = useState(isDraft);
  const [error, setError] = useState("");

  const [duplicateError, setDuplicateError] = useState("");

  function checkParams() {
    const primaryKey = "{{key}}";
    if (enText.includes(primaryKey)) {
      if (!tVal.includes(primaryKey)) {
        debugger;
        console.log("error");
        setError("error");
      } else {
        setError("");
      }
    }
  }

  async function handleFocus(i18nId, language, username) {
    await lockInput(i18nId, language, username, selectedProject);
  }

  async function handleBlur(i18nId, language, changes) {
    if (
      currentUser === isLockedUsername ||
      currentUser === isLockedUsernameVn
    ) {
      await unlockInput(i18nId, language, changes, selectedProject);
    }
  }

  async function handleFinaliseDraft(i18nId, username) {
    const allowedToPush = pushCredentialUsers.find(
      item => item === currentUser
    );
    if (allowedToPush) {
      await finaliseDraft(i18nId, username, selectedProject);
      cogoToast.success("Update successfully");
    } else {
      cogoToast.error("Unauthorized to update");
    }
  }

  useEffect(() => {
    setEnTVal(enText);
    setTVal(zhText);
    setVnTVal(vnText);
    setDraftZhState(isDraftZh);
    setDraftVnState(isDraftVn);
    setDraftState(isDraft);
  }, [enText, zhText, vnText, isDraftZh, isDraftVn, isDraft]);

  const editable = currentUser === isLockedUsername && isLocked;
  const editableVn = currentUser === isLockedUsernameVn && isLockedVn;

  async function showHistory(i18nId) {
    console.log(i18nId);
  }

  return (
    <li className={error} data-key={enVal}>
      <div className="count">{Number(props.index) + 1}</div>
      <div className="translate-label">{enVal}</div>
      <div className="translate-label">{enVal && processColon(enVal)}</div>
      <div className={`translate-value`}>
        <div
          className={`input-wrapper ${isLocked ? "locked" : ""} ${
            editable ? "editable" : "non-editable"
          }`}
          style={
            isLocked
              ? { borderColor: stringToHslColor(isLockedUsername, 100, 30) }
              : {}
          }
        >
          {isLockedUsername !== "" && (
            <span
              className="editor"
              style={{
                background: stringToHslColor(isLockedUsername, 100, 30)
              }}
            >
              {isLockedUsername}
            </span>
          )}

          <textarea
            data-key={enVal}
            key={enText}
            // defaultValue={data && data.zh && data.zh.text}
            value={tVal}
            onChange={e => setTVal(e.target.value)}
            tabIndex={editable ? 0 : -1}
            onFocus={() => handleFocus(enText, "zh", currentUser)}
            onBlur={() => handleBlur(enText, "zh", tVal)}
          />
        </div>
        <div
          className={`input-wrapper ${isLockedVn ? "locked" : ""} ${
            editableVn ? "editable" : "non-editable"
          }`}
          style={
            isLockedVn
              ? { borderColor: stringToHslColor(isLockedUsernameVn, 100, 30) }
              : {}
          }
        >
          {isLockedUsernameVn !== "" && (
            <span
              className="editor"
              style={{
                background: stringToHslColor(isLockedUsernameVn, 100, 30)
              }}
            >
              {isLockedUsernameVn}
            </span>
          )}
          <textarea
            rows="1"
            data-key={enVal}
            key={enText}
            value={tVnVal}
            onChange={e => setVnTVal(e.target.value)}
            tabIndex={editable ? 0 : -1}
            onFocus={() => handleFocus(enText, "vn", currentUser)}
            onBlur={() => handleBlur(enText, "vn", tVnVal)}
          />
        </div>
        {error && (
          <div
            className="error-message"
            dangerouslySetInnerHTML={{
              __html: "缺少 &lcub;&lcub;key&rcub;&rcub;, 请检查"
            }}
          />
        )}
      </div>
      {isDraftState && (
        <div
          className="action"
          onClick={() => handleFinaliseDraft(enText, currentUser)}
        >
          <Icon name="check circle" />
        </div>
      )}
    </li>
  );
});
