import * as React from "react";
import {
  Button,
  Checkbox,
  Form,
  Step,
  Segment,
  Icon,
  Message,
  Transition
} from "semantic-ui-react";
import axios from "axios";
import cogoToast from "cogo-toast";

import {
  Header,
  FormWrapper,
  DetailsWrapper,
  MainWrapper,
  LabelTitle,
  SummaryWrapper
} from "./Styled";
import PoweredByJira from "../Public/PoweredByJira";

export default class JiraForm extends React.Component {
  checkboxArr = [];
  state = {
    currSteps: 2,
    franchiseName: "",
    acronym: "",
    officialUrl: "",
    responseData: "",
    responseDataVisibleMap: [],
    username: "",
    password: "",
    mainTicket: "",
    createTicket: "",
    token: "",
    checkboxArrState: [],
    networkCheck: true,
    isFetching: false
  };

  handleClick = key => {
    if (key === 2) {
      this.setState({
        currSteps: key,
        // checkboxArrState: [],
        isFetching: false
      });
    } else {
      this.setState({ currSteps: key });
    }
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleLogin = () => {
    const { username, password } = this.state;

    if (username !== "" && password !== "") {
      this.setState({ username: username, password: password });
      this.doLogin();
    } else {
      cogoToast.error("Please enter login information");
    }
  };

  doLogin = () => {
    const { username, password, currSteps } = this.state;
    let nextStep = currSteps + 1;
    const self = this;
    axios
      .post("/api/jira/login", {
        username: username,
        password: password
      })
      .then(function(response) {
        if (response.data.error) {
          cogoToast.error("Login failed");
        } else {
          self.setState({
            currSteps: nextStep,
            token: btoa(`${username}:${password}`)
          });
          cogoToast.success("Login success");
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleSubmit = () => {
    const {
      franchiseName,
      acronym,
      mainTicket,
      officialUrl,
      checkboxArrState
    } = this.state;

    if (
      franchiseName !== "" &&
      acronym !== "" &&
      mainTicket !== "" &&
      officialUrl !== "" &&
      checkboxArrState.length !== 0
    ) {
      this.setState({
        franchiseName: franchiseName,
        acronym: acronym,
        officialUrl: officialUrl,
        mainTicket: mainTicket,
        isFetching: true
      });
      this.getJiraIssueList(franchiseName, acronym);
    } else {
      cogoToast.error("Please fill in all the details");
    }
  };

  handleResponseData = responseData => {
    this.setState({
      responseData: responseData
    });
  };

  getJiraIssueList = async (franchiseName, acronym) => {
    const {
      checkboxArrState,
      currSteps,
      officialUrl,
      networkCheck
    } = this.state;
    let nextStep = currSteps + 1;
    const self = this;
    const franchiseType = checkboxArrState.join(",");
    await axios
      .post("/api/jira/list", {
        franchiseName: franchiseName,
        acronym: acronym,
        franchiseType: franchiseType,
        officialUrl: officialUrl,
        networkCheck: networkCheck
      })
      .then(function(response) {
        console.log(response);
        let responseDataVisibleMap = [];
        for (let i in response.data) {
          responseDataVisibleMap.push(true);
        }
        self.setState({
          currSteps: nextStep,
          responseData: response.data,
          responseDataVisibleMap: responseDataVisibleMap
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  deleteIssue = index => {
    const { responseData, responseDataVisibleMap } = this.state;
    const processedData = responseData;

    let responseDataVisibleMapTemp = responseDataVisibleMap;
    responseDataVisibleMapTemp[index] = false;

    // TO-DO
    // Visible map is to render animation
    this.setState({
      responseDataVisibleMap: responseDataVisibleMapTemp
    });

    // Remove element after animation end
    setTimeout(() => {
      processedData.splice(index, 1);
      responseDataVisibleMapTemp.splice(index, 1);
      this.setState({
        responseData: processedData,
        responseDataVisibleMap: responseDataVisibleMapTemp
      });
      console.log(responseData);
    }, 300);
  };

  handleChangeCheckbox = (key, isChecked) => {
    if (isChecked) {
      this.checkboxArr.push(key);
    } else {
      this.checkboxArr = this.checkboxArr.filter(function(item) {
        return item !== key;
      });
    }
    this.setState({
      checkboxArrState: this.checkboxArr
    });
  };

  toggleNetworkCheck = () => {
    this.setState({
      networkCheck: !this.state.networkCheck
    });
  };

  createMarkup = HTMLString => {
    return { __html: HTMLString };
  };

  createJiraTicket = () => {
    const { responseData, mainTicket } = this.state;
    // console.log(responseData);
    const token = localStorage.getItem("token");
    axios
      .post("/api/jira/createTicket", {
        data: responseData,
        token: token,
        mainTicket: mainTicket
      })
      .then(function(response) {
        console.log(response);
        if (response.errorMessages) {
          cogoToast.error(response.errorMessages[0]);
        } else {
          cogoToast.success("Successfully created Jira Ticket");
        }
      })
      .catch(function(error) {
        cogoToast.error("Failed to create Jira Ticket");
      });
  };
  render() {
    const {
      franchiseName,
      acronym,
      officialUrl,
      responseData,
      responseDataVisibleMap,
      currSteps,
      username,
      password,
      mainTicket,
      networkCheck,
      isFetching,
      checkboxArrState
    } = this.state;

    // const Box = posed.div({
    //   visible: { opacity: 1, flip: true },
    //   hidden: { opacity: 0, flip: true }
    // });

    return (
      <FormWrapper>
        <Step.Group widths={3}>
          {/*<Step active={currSteps === 1} key="1" completed={currSteps > 1}>*/}
          {/*  <Icon name="user outline" />*/}
          {/*  <Step.Content>*/}
          {/*    <Step.Title>Login</Step.Title>*/}
          {/*    <Step.Description>Login to Jira</Step.Description>*/}
          {/*  </Step.Content>*/}
          {/*</Step>*/}

          <Step
            active={currSteps === 2}
            key="2"
            completed={currSteps > 2}
            onClick={currSteps > 2 ? () => this.handleClick(2) : null}
          >
            <Icon name="info circle" />
            <Step.Content>
              <Step.Title>Info</Step.Title>
              <Step.Description>
                Information required to open ticket
              </Step.Description>
            </Step.Content>
          </Step>

          <Step
            active={currSteps === 3}
            key="3"
            completed={currSteps > 3}
            onClick={currSteps >= 3 ? () => this.handleClick(3) : null}
          >
            <Icon name="list alternate outline" />
            <Step.Content>
              <Step.Title>Ticket</Step.Title>
              <Step.Description>Double check the information</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
        <Segment attached>
          {currSteps === 1 && (
            <Form onSubmit={this.handleLogin}>
              <Form.Field>
                <label>Username</label>
                <Form.Input
                  placeholder="Username"
                  name="username"
                  value={username}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Password</label>
                <Form.Input
                  placeholder="Password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <Button primary type="submit">
                  Submit
                </Button>
              </Form.Field>
            </Form>
          )}
          {/*Steps 2*/}
          {currSteps === 2 && (
            <Form onSubmit={this.handleSubmit}>
              <Form.Field>
                <label>Franchise Name</label>
                <Form.Input
                  placeholder="迪士尼"
                  name="franchiseName"
                  value={franchiseName}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Acronym</label>
                <Form.Input
                  placeholder="dsn"
                  type="acronym"
                  name="acronym"
                  value={acronym}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Official Url</label>
                <Form.Input
                  placeholder="example.com"
                  type="officialUrl"
                  name="officialUrl"
                  value={officialUrl}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Main Ticket</label>
                <Form.Input
                  placeholder="XIANJIN-1234"
                  type="text"
                  name="mainTicket"
                  value={mainTicket}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Field>
                  <Checkbox
                    style={{ marginRight: 20 }}
                    checked={checkboxArrState.some(item => item === "official")}
                    onChange={(e, data) =>
                      this.handleChangeCheckbox("official", data.checked)
                    }
                    label="Official"
                  />
                  <Checkbox
                    style={{ marginRight: 20 }}
                    checked={checkboxArrState.some(item => item === "ydl")}
                    onChange={(e, data) =>
                      this.handleChangeCheckbox("ydl", data.checked)
                    }
                    label="YDL"
                  />
                  <Checkbox
                    style={{ marginRight: 20 }}
                    checked={checkboxArrState.some(item => item === "wdl")}
                    onChange={(e, data) =>
                      this.handleChangeCheckbox("wdl", data.checked)
                    }
                    label="WDL"
                  />
                </Form.Field>
                <Form.Field style={{ textAlign: "right" }}>
                  <Checkbox
                    checked={networkCheck}
                    toggle
                    onChange={() => this.toggleNetworkCheck()}
                    label={<label>Toggle network check</label>}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field>
                {!isFetching && (
                  <Transition
                    visible={!isFetching}
                    animation="fade up"
                    duration={300}
                  >
                    <Button
                      primary
                      loading={isFetching}
                      disabled={isFetching}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Transition>
                )}
              </Form.Field>
              <Transition
                visible={isFetching}
                animation="fade down"
                duration={500}
              >
                <div>
                  <Message icon color="blue">
                    <Icon name="circle notched" loading />
                    <Message.Content>
                      <Message.Header>Just one second</Message.Header>
                      Checking domain availability
                    </Message.Content>
                  </Message>
                </div>
              </Transition>
            </Form>
          )}

          {/*Steps 3*/}
          <Transition
            visible={currSteps === 3}
            animation="fade right"
            duration={500}
          >
            <DetailsWrapper>
              <Form>
                {responseData &&
                  responseData.map((item, index) => {
                    const summary = item.summary.replace(/\n/gi, "<br/>");
                    console.log(responseDataVisibleMap);
                    return (
                      <MainWrapper
                        key={Date.now() + item.title}
                        className={
                          responseDataVisibleMap[index] ? "" : "deleting"
                        }
                      >
                        <LabelTitle>{item.title}</LabelTitle>
                        <button
                          className="delete-issue"
                          onClick={this.deleteIssue.bind(this, index)}
                        >
                          <Icon name="delete" />
                        </button>
                        <span className="ticket-type">{item.type}</span>
                        <SummaryWrapper
                          dangerouslySetInnerHTML={this.createMarkup(summary)}
                        />
                      </MainWrapper>
                    );
                  })}
                <Form.Field>
                  <Button
                    primary
                    type="submit"
                    onClick={() => this.createJiraTicket()}
                  >
                    Submit
                  </Button>
                </Form.Field>
              </Form>
            </DetailsWrapper>
          </Transition>
        </Segment>
        <PoweredByJira />
      </FormWrapper>
    );
  }
}
