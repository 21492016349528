import { firebaseConfig, firebaseConfig2 } from "../../Firebase/credentials";
const firebase = require("firebase");

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

/**
 * Get firebase collection
 * @type {firebase.firestore.CollectionReference}
 */
const i18nRef = firebase.firestore().collection("i18n");
const i18nDB = firebase.database();

const searchi18n = key => {
  return (
    i18nRef
      .where("en", "==", key)
      // .where("editing", "==", false)
      .get()
  );
};

// export const listi18n = async () => {
//   return await i18nRef.get().then(querySnapshot => {
//     // arr.push(
//     return querySnapshot.docs.map(doc => {
//       return doc.data();
//     });
//     // );
//   });
// };

export const listi18n = async callback => {
  const ref = firebase.database().ref("i18n");
  // Attach an asynchronous callback to read the data at our posts reference
  await ref.on(
    "value",
    function(snapshot) {
      // console.log(snapshot.val());
      callback(snapshot.val());
    },
    function(errorObject) {
      console.log("The read failed: " + errorObject.code);
    }
  );
};

export const geti18n = async key => {
  // const key = request.query.key;
  await searchi18n(key).then(docs => docs.forEach(doc => console.log(doc.id)));
};

// export const createi18n = async ({ en, zh, vn }) => {
//   console.log({ en, zh, vn });
//   const i18nId = en;
//   await i18nRef.doc(i18nId).set({
//     en: en,
//     zh: zh,
//     vn: vn
//   });
//   return i18nId;
// };

export const createi18n = async ({
  sourceKey,
  en,
  zh,
  vn,
  project,
  firstInit
}) => {
  return await firebase
    .database()
    .ref(`i18n/${project}/${btoa(sourceKey)}`)
    .set({
      en: {
        text: sourceKey,
        action: {
          username: "",
          editing: false,
          isDraft: false,
          lastUpdate: [
            {
              lastModified: new Date().toString(),
              updatedText: sourceKey,
              username: "Initial value"
            }
          ]
        }
      },
      zh: {
        text: zh,
        action: {
          username: "",
          editing: false,
          isDraft: firstInit ? false : true,
          lastUpdate: [
            {
              lastModified: new Date().toString(),
              updatedText: zh,
              username: "Initial value"
            }
          ]
        }
      },
      vn: {
        text: vn,
        action: {
          username: "",
          editing: false,
          isDraft: firstInit ? false : true,
          lastUpdate: [
            {
              lastModified: new Date().toString(),
              updatedText: vn,
              username: "Initial value"
            }
          ]
        }
      }
    });
};

// export const createi18n = async () => {
//   createi18n().then(id => console.log(id));
// };

const firebaseArrayOps = firebase.firestore.FieldValue;

const lockField = async (i18nKey, language, username, project) => {
  await firebase
    .database()
    .ref(`i18n/${project}/${btoa(i18nKey)}`)
    .child(language)
    .child("action")
    .update({ editing: true, username: username });
};

const unlockField = async (i18nKey, language, changes, project) => {
  const originalKey = await new Promise(resolve => {
    firebase
      .database()
      .ref(`i18n/${project}/${btoa(i18nKey)}`)
      .on(
        "value",
        function(snapshot) {
          resolve(snapshot.val());
        },
        function(errorObject) {
          resolve("");
        }
      );
  });

  const originalValue = originalKey[language];

  const oldUpdateDetails =
    (originalValue &&
      originalValue.action &&
      originalValue.action.lastUpdate) ||
    []; // Last update might not exist

  const lastIndex = (oldUpdateDetails && oldUpdateDetails.length - 1) || 0;

  const originalText =
    (oldUpdateDetails &&
      oldUpdateDetails[lastIndex] &&
      oldUpdateDetails[lastIndex].updatedText) ||
    originalValue.text;

  /**
   * Set updated text then only check
   */

  // console.log(changes, oldUpdateDetails);
  const hasChange = changes !== originalText;
  const config = {
    [language]: {
      text: changes,
      action: {
        editing: false,
        isDraft: hasChange,
        lastUpdate: oldUpdateDetails,
        username: ""
      }
    }
  };

  await firebase
    .database()
    .ref(`i18n/${project}/${btoa(i18nKey)}`)
    // .child(language)
    .update(config);
};

export const lockInput = async (i18nId, language, username, project) => {
  // const i18nDocRef = i18nRef.doc(i18nId);
  await lockField(i18nId, language, username, project);
  // return i18nDocRef.onSnapshot(callback);
};

export const unlockInput = async (i18nId, language, changes, project) => {
  // const i18nDocRef = i18nRef.doc(i18nId);
  await unlockField(i18nId, language, changes, project);
  // return i18nDocRef.onSnapshot(callback);
};

export const finaliseDraft = async (i18nKey, username, project) => {
  const originalValueZH = await new Promise(resolve => {
    firebase
      .database()
      .ref(`i18n/${project}/${btoa(i18nKey)}/zh`)
      .on(
        "value",
        function(snapshot) {
          resolve(snapshot.val());
        },
        function(errorObject) {
          resolve("");
        }
      );
  });

  const originalValueVN = await new Promise(resolve => {
    firebase
      .database()
      .ref(`i18n/${project}/${btoa(i18nKey)}/vn`)
      .on(
        "value",
        function(snapshot) {
          resolve(snapshot.val());
        },
        function(errorObject) {
          resolve("");
        }
      );
  });

  let updateDetailsZHArr =
    (originalValueZH &&
      originalValueZH.action &&
      originalValueZH.action.lastUpdate) ||
    [];

  let updateDetailsVNArr =
    (originalValueVN &&
      originalValueVN.action &&
      originalValueVN.action.lastUpdate) ||
    [];

  const lastUpdateConfig = {
    username: username,
    updatedText: originalValueZH.text,
    lastModified: new Date().toString()
  };

  const lastUpdateConfigVN = {
    username: username,
    updatedText: originalValueVN.text,
    lastModified: new Date().toString()
  };

  const MAX_LENGTH = 10;

  updateDetailsZHArr.push(lastUpdateConfig);
  updateDetailsVNArr.push(lastUpdateConfigVN);

  if (updateDetailsZHArr.length >= MAX_LENGTH) {
    updateDetailsZHArr = updateDetailsZHArr.splice(
      updateDetailsZHArr.length - MAX_LENGTH,
      MAX_LENGTH
    );
  }

  if (updateDetailsVNArr.length >= MAX_LENGTH) {
    updateDetailsVNArr = updateDetailsVNArr.splice(
      updateDetailsVNArr.length - MAX_LENGTH,
      MAX_LENGTH
    );
  }

  const config = {
    zh: {
      text: originalValueZH.text,
      action: {
        editing: false,
        isDraft: false,
        username: "",
        lastUpdate: updateDetailsZHArr
      }
    },
    vn: {
      text: originalValueVN.text,
      action: {
        editing: false,
        isDraft: false,
        username: "",
        lastUpdate: updateDetailsVNArr
      }
    }
  };

  await firebase
    .database()
    .ref(`i18n/${project}/${btoa(i18nKey)}`)
    // .child(language)
    .update(config);
};

// export const setObserver = async () => {
//   // const i18nDocRef = i18nRef.doc(i18nId);
//   // await incrementUserCount(i18nDocRef, userId);
//   // return i18nRef.onSnapshot(callback);
//   return i18nRef.onSnapshot(function(snapshot) {
//     console.log(snapshot);
//     snapshot.docChanges().forEach(function(change) {
//       if (change.type === "added") {
//         console.log("New: ", change.doc.data());
//       }
//       if (change.type === "modified") {
//         console.log("Modified: ", change.doc.data());
//       }
//       if (change.type === "removed") {
//         console.log("Removed: ", change.doc.data());
//       }
//     });
//   });
// };

export const getEditHistory = async (i18nKey, project) => {
  return await new Promise(resolve => {
    firebase
      .database()
      .ref(`i18n/${project}/${btoa(i18nKey)}`)
      .on(
        "value",
        function(snapshot) {
          resolve(snapshot.val());
        },
        function(errorObject) {
          resolve("");
        }
      );
  });
};

export const setObserver = async callback => {
  const commentsRef = firebase.database().ref("i18n");
  await commentsRef.on("child_changed", function(data) {
    // setCommentValues(postElement, data.key, data.val().text, data.val().author);
    // console.log(data);
    callback(data.val());
  });

  // commentsRef.on("child_added", function(data) {
  //   // addCommentElement(postElement, data.key, data.val().text, data.val().author);
  //   // console.log("New: ", data);
  //   return data.val();
  // });

  // firebase
  //   .database()
  //   .ref("i18n")
  //   .off();

  // commentsRef.on("child_removed", function(data) {
  //   // deleteComment(postElement, data.key);
  //   // console.log(data);
  //   return data.val();
  // });
};

// Assuming i18nObserver was assigned the return value of
// setObserver...
// i18nObserver();
