import * as React from "react";
import Ajax from "./Ajax";
import cogoToast from "cogo-toast";
import { TableWrapper, Table, Loading } from "./Styled";
import axios from "axios";

import DatePicker from "react-datepicker";
import moment from "moment";
import { Icon, Button, Message } from "semantic-ui-react";

import "react-datepicker/dist/react-datepicker.css";
import PageHeader from "../Header";
import { withRouter } from "react-router-dom";
import { remove } from "lodash";
import { getData, addData, removeData } from "../Public/GetFirebaseData";

import MainIssue from "./MainIssue";
import { parseListOfTicketsForProjects } from "../../util";
import ListFilter from "./ListFilter";
import ListFilterProject from "./ListFilterProject";
import PoweredByJira from "./PoweredByJira";

import { listOnlineUsers } from "./OnlineUsers";
import UserContext from "../../Context";

class Listing extends React.Component {
  loggedInUser = false;

  constructor(props) {
    super(props);
    this.state = {
      soloItem: null,
      projectMap: {},
      devMap: {},
      isFetching: true,
      startDate: new Date(),
      listOfTicketsOrig: [],
      listOfTickets: [],
      listOfDevs: [],
      loggedInUser: "",
      alarmSet: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async handleChange(date) {
    const formattedDate = moment(date).format("YYYY-M-D");
    this.props.history.push("/live/" + formattedDate);
    this.setState(
      {
        isFetching: true,
        devMap: {},
        projectMap: {},
        soloItem: null,
        startDate: moment(date).toDate()
      },
      async () => {
        await this.doFetch(formattedDate);
      }
    );
  }

  setSchedule = async () => {
    const { startDate } = this.state;
    const userContext = this.context;
    const formattedDate = moment(startDate).format("YYYY-M-D");

    await axios
      .get(
        `/api/cron/start?username=${userContext.username}&date=${formattedDate}`
      )
      .then(function(response) {
        if (response) {
          const message = response.data.message;
          cogoToast.success(message);
        }
      });
  };

  deleteSchedule = async () => {
    const userContext = this.context;

    await axios
      .get(`/api/cron/stop?username=${userContext.username}`)
      .then(function(response) {
        if (response) {
          const message = response.data.message;
          cogoToast.success(message);
        }
      });

    this.setState({
      alarmSet: false
    });
  };

  async componentDidMount() {
    const { match, showDatePicker, title, showAlarm } = this.props;
    // const loggedInUser = localStorage.getItem("name");
    const userContext = this.context;
    const that = this;

    // console.log(userContext);

    this.loggedInUser = userContext.username;

    if (showAlarm) {
      await getData({
        table: "cron",
        callback: function(data) {
          data &&
            Object.entries(data).forEach(item => {
              const { name, time } = item[1];
              if (name === userContext.username) {
                that.setState({
                  alarmSet: true
                });
              }
            });
        }
      });
    }

    this.setState({
      loggedInUser: userContext.username
    });

    document.title = title;
    if (showDatePicker) {
      const date = match && match.params && match.params.date;

      const formattedDate = moment(new Date()).format("YYYY-M-D");
      if (!date) {
        this.props.history.push("/live/" + formattedDate);
      }
      this.setState({
        startDate: date ? new Date(date.replace(/-/g, "/")) : new Date()
      });
      await this.doFetch(date ? date : formattedDate);
    } else {
      await this.doFetch();
    }
  }

  sortDevs = listOfDevs => {
    let listOfDevsSorted = listOfDevs.sort((a, b) => {
      if (a.key > b.key) return 1;
      else if (a.key < b.key) return -1;
      else return 0;
    });

    const loggedInDev = remove(
      listOfDevsSorted,
      item => item.key === this.state.loggedInUser
    );

    const allDevsSorted = loggedInDev.concat(listOfDevsSorted);
    return allDevsSorted;
  };

  doFetch = async date => {
    const self = this;
    const token = localStorage.getItem("token");
    const { api, extraParams } = this.props;
    await Ajax(api, {
      method: "GET",
      params: {
        date: date,
        token: token,
        ...extraParams
      }
    })
      .then(async function(response) {
        if (response.error) {
          cogoToast.error("Failed to load data");
        } else {
          const listOfProjects = parseListOfTicketsForProjects(
            response && response && response.data
          );

          const listOfDevs = response && response && response.listOfDevs;

          // process list of devs to add online status
          let processedListOfDev = [];

          /**
           * This function is to get the list of online users,
           * comment out to remove bugs
           * @type {T[]}
           */
          // await listOnlineUsers(async function(data) {
          processedListOfDev = listOfDevs.map(item => {
            // if (data[btoa(item.key)]) {
            //   return Object.assign({}, item, {
            //     isOnline: true
            //   });
            // } else {
            return item;
            // }
          }, []);
          self.setState({
            isFetching: false,
            listOfTickets: response && response && response.data,
            listOfTicketsOrig: response && response && response.data,
            listOfProjects,
            listOfDevs: self.sortDevs(processedListOfDev)
          });
          // });
          cogoToast.success("Successful");
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  filterFuncSingle = username => {
    this.filterFunc(username, { single: true });
  };

  filterFuncSingleProject = project => {
    this.filterFunc(null, { isProject: true, project, single: true });
  };

  filterFuncProject = project => {
    this.filterFunc(null, { isProject: true, project });
  };

  filterFunc = (username, options) => {
    const { single = false, isProject = false, project } = options || {};
    const { listOfTicketsOrig: listOfTickets, devMap, projectMap } = this.state;
    const alreadySelectedDev = devMap[username] && devMap[username] === true;

    const alreadySelectedProject =
      projectMap[project] && projectMap[project] === true;

    const newDevMap =
      // if isProject, keep old devMap
      isProject === true
        ? devMap
        : // else, do logic to create new devMap
        single === true
        ? // force only single
          { [username]: true }
        : alreadySelectedDev
        ? Object.assign({}, devMap, { [username]: false })
        : Object.assign({}, devMap, { [username]: true });

    const newProjectMap =
      // if not isProject, keep old projectMap
      isProject === false
        ? projectMap
        : // else, do logic to create new projectMap
        single === true
        ? { [project]: true }
        : alreadySelectedProject
        ? Object.assign({}, projectMap, { [project]: false })
        : Object.assign({}, projectMap, { [project]: true });

    const devMapList = Object.entries(newDevMap).reduce(reduceFn, []);
    const shouldCheckDevMap = devMapList.length > 0;

    const projectMapList = Object.entries(newProjectMap).reduce(reduceFn, []);
    const shouldCheckProjectMap = projectMapList.length > 0;

    const filtered =
      [...projectMapList, ...devMapList].length > 0
        ? listOfTickets.reduce((acc, curr) => {
            const processed = Object.assign({}, curr, {
              issue: curr.issue.filter(curr2 => {
                const projectKey = curr2.key.split("-")[0];

                const inDevMap =
                  newDevMap[curr2.details.assignee] &&
                  newDevMap[curr2.details.assignee] === true;

                const inProjectMap =
                  newProjectMap[projectKey] &&
                  newProjectMap[projectKey] === true;

                // TODO: not sure if this can be written better
                if (shouldCheckDevMap && shouldCheckProjectMap) {
                  return inDevMap && inProjectMap;
                } else if (shouldCheckDevMap) {
                  return inDevMap;
                } else if (shouldCheckProjectMap) {
                  return inProjectMap;
                }
              })
            });
            return processed.issue.length > 0 ? acc.concat(processed) : acc;
          }, [])
        : listOfTickets;

    const newState = {
      devMap: newDevMap,
      projectMap: newProjectMap,
      listOfTickets: filtered,
      filtered: true
    };
    this.setState(newState);
  };

  resetFilterPreventEventDefault = e => {
    e.preventDefault();
    this.resetFilter(null);
  };

  filterAssignee = mainUsername => {
    const { listOfTicketsOrig: listOfTickets } = this.state;
    const filtered = listOfTickets.filter(item => {
      return item.assignee === mainUsername;
    });
    // return processed.issue.length > 0 ? acc.concat(processed) : acc;
    this.setState({
      listOfTickets: filtered,
      filtered: true,
      devMap: {},
      soloItem: null,
      projectMap: {}
    });
  };

  resetFilter = solo => {
    this.setState(
      {
        devMap: {},
        projectMap: {},
        soloItem: solo || null,
        filtered: false,
        listOfTickets: this.state.listOfTicketsOrig
      },
      () => {
        if (solo) {
          // setTimeout(() => {
          document.getElementById(solo).scrollIntoView({ behavior: "smooth" });
          // }, 300);
        }
      }
    );
  };

  solo = item => {
    this.resetFilter(item);
  };

  render() {
    const {
      devMap,
      filtered,
      isFetching,
      listOfDevs,
      listOfProjects,
      listOfTickets,
      listOfTicketsOrig,
      projectMap,
      soloItem,
      startDate,
      alarmSet
    } = this.state;
    const { showDatePicker, title, showAlarm } = this.props;
    const countMainTicket = `(${listOfTickets.length}/${listOfTicketsOrig.length})`;
    const countSubTicket = `(${listOfTickets.reduce(
      reduceFn2,
      0
    )}/${listOfTicketsOrig.reduce(reduceFn2, 0)})`;
    return (
      <>
        <PageHeader className="prod">
          <span className="title">{title}</span>
          {/*{showAlarm && (*/}
          {/*  <Button*/}
          {/*    disabled={alarmSet || isFetching}*/}
          {/*    compact*/}
          {/*    inverted*/}
          {/*    size="tiny"*/}
          {/*    icon="alarm"*/}
          {/*    content="Alert me"*/}
          {/*    onClick={this.setSchedule}*/}
          {/*    style={{ marginRight: 10 }}*/}
          {/*  />*/}
          {/*)}*/}
          {showDatePicker && (
            <div className="date-picker-wrapper">
              <Icon name="calendar alternate" />
              <DatePicker
                dateFormat="dd-MM-yyyy"
                selected={startDate}
                popperPlacement="top-end"
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: "5px, 10px"
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                    boundariesElement: "viewport"
                  }
                }}
                onChange={this.handleChange}
              />
            </div>
          )}
        </PageHeader>

        {isFetching ? (
          <Loading>
            {Array.from(Array(10).keys()).map((item, i) => {
              return <div className="loading-item" key={i} />;
            })}
          </Loading>
        ) : (
          <TableWrapper>
            {alarmSet && (
              <Message color="blue">
                <div className="header">
                  A scheduler has been set for deployment on{" "}
                  <strong>{moment(startDate).format("DD-MM-YYYY")}</strong>.
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    In case any failure on deployment (Steps that marked as
                    failed in Google Sheets), Plus Robot will call you
                    immediately.
                  </div>
                  <Button
                    compact
                    size="mini"
                    color="blue"
                    style={{ marginLeft: "auto" }}
                    onClick={this.deleteSchedule}
                  >
                    Cancel
                  </Button>
                </div>
              </Message>
            )}
            {listOfDevs.length !== 0 && (
              <ListFilter
                devMap={devMap}
                filterFunc={this.filterFunc}
                filterFuncSingle={this.filterFuncSingle}
                filtered={filtered}
                list={listOfDevs}
                resetFilterPreventEventDefault={
                  this.resetFilterPreventEventDefault
                }
              />
            )}

            {Object.keys(listOfProjects).length !== 0 && (
              <ListFilterProject
                projectMap={projectMap}
                filterFunc={this.filterFuncProject}
                filterFuncSingle={this.filterFuncSingleProject}
                list={listOfProjects}
              />
            )}

            <Table>
              <thead>
                <tr>
                  <td className="no-padding" />
                  <td>Main Ticket {countMainTicket}</td>
                  <td>Sub Ticket {countSubTicket}</td>
                  <td>Assignee</td>
                </tr>
              </thead>
              <tbody>
                {listOfTickets &&
                  listOfTickets.map((value, index) => {
                    const overflow = (value.overflow);
                    return (
                      <MainIssue
                        isSolod={soloItem === value.key}
                        solo={this.solo}
                        index={index}
                        key={index}
                        filterFunc={this.filterFuncSingle}
                        filterAssignee={this.filterAssignee}
                        value={value}
                        overflow={overflow}
                      />
                    );
                  })}
                {listOfTickets.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={4}>
                      <Icon name="smile outline" />
                      No issue will live on this day
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <PoweredByJira />
          </TableWrapper>
        )}
      </>
    );
  }
}

const reduceFn2 = (acc, curr) => {
  return acc + curr.issue.length;
};

const reduceFn = (acc, curr) => {
  return curr[1] === true ? acc.concat(curr) : acc;
};

Listing.contextType = UserContext;

export default withRouter(Listing);
