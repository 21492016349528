import styled from "styled-components";

export const AWSStatusContainer = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.body};
  .aws-wrapper {
    width: 1200px;
    margin: auto;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
    .tab-wrapper {
      border-bottom: 0;
      display: flex;
      align-items: center;
      .tab-item {
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }
`;

const arr = new Array(100);

export const AWSList = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-left: -4px;
  margin-right: -4px;
  .aws-item {
    //display: flex;
    //align-items: center;
    border-bottom: 1px solid #ccc;
    //display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc((100% / 5) - 8px);
    //height: 160px;
    //cursor: pointer;
    //border-radius: 5px;
    //border: 1px solid rgba(34, 36, 38, 0.1);
    box-sizing: border-box;
    transition: all 200ms ease-in-out;
    //align-items: center;
    //justify-content: center;
    //flex-direction: column;
    margin: 0 4px 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    //padding: 0 20px;
    border-radius: 4px;
    overflow: hidden;
    @media screen and (max-width: 800px) {
      flex-basis: 100%;
      &:hover {
        .aws-application-details {
          max-height: 200px;
        }
      }
    }
    &.success {
      background-color:  ${({ theme }) => theme.awsSuccessBg};
      border: 1px solid #61a269;
      .aws-application-name {
        .aws-application-status {
          color: #61a269;
        }
      }
    }
    &.failed {
      background-color:  ${({ theme }) => theme.awsFailBg};
      border: 1px solid #d03643;
      .aws-application-name {
        .aws-application-status {
          color: #d03643;
        }
      }
    }

    &.loading {
      background-color: #ececec;
      border: 1px solid #aaa;
      .aws-application-name {
        .aws-application-status {
          color: #aaa;
        }
      }
    }
    &.docker {
      background-color: #e6ecf4;
      border: 1px solid #678ca2;
      .aws-application-name {
        .aws-application-status {
          color: #678ca2;
        }
      }
    }
    &.unknown {
      background-color: #f4f29f;
      border: 1px solid #a28c4e;
      .aws-application-name {
        .aws-application-status {
          color: #a28c4e;
        }
      }
    }

    .aws-application-name {
      padding: 12px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-weight: bold;
      display: flex;
      .aws-application-status {
        margin-left: auto;
      }
    }
    .aws-application-details {
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
      @media screen and (max-width: 800px) {
        max-height: 0;
      }
      .status-wrapper {
        display: flex;
        align-items: center;
        //padding: 0 12px;
        box-sizing: border-box;
        position: relative;
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .label {
          //width: 120px;
          padding: 4px 6px;
          box-sizing: border-box;
          //border-right: 1px solid rgba(0, 0, 0, 0.1);
          position: absolute;
          top: 0;
          left: 0;
          font-size: 10px;
        }
        .content {
          padding: 20px 6px 4px;
          box-sizing: border-box;
        }
      }
    }
  }
`;
