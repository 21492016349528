import * as React from "react";
import PageHeader from "../Header";
import { Container, Icon } from "semantic-ui-react";
import JiraForm from "../form";
import { withRouter } from "react-router-dom";

class Jira extends React.Component {
  componentDidMount() {
    document.title = "Franchise Issue Generator";
  }
  render() {
    return (
      <>
        <PageHeader>
          <span className="title">Franchise Issue Generator</span>
        </PageHeader>
        <Container>
          <JiraForm />
        </Container>
      </>
    );
  }
}

export default withRouter(Jira);
