import { firebaseConfig, firebaseConfig2 } from "../Firebase/credentials";
const firebase = require("firebase");

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const getData = async ({ table, callback }) => {
  const ref = firebase.database().ref(table);
  // Attach an asynchronous callback to read the data at our posts reference
  await ref.on(
    "value",
    function(snapshot) {
      // console.log(snapshot.val());
      callback(snapshot.val());
    },
    function(errorObject) {
      console.log("The read failed: " + errorObject.code);
    }
  );
};

export const addData = async ({ table, key, value }) => {
  await firebase
    .database()
    .ref(table + "/" + btoa(key))
    .set(value);
};

export const removeData = async ({ table, key }) => {
  const userRef = firebase.database().ref(table + "/" + btoa(key));
  userRef
    .remove()
    .then(function() {
      console.log("Remove succeeded.");
    })
    .catch(function(error) {
      console.log("Remove failed: " + error.message);
    });
};
