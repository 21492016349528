export { processColon } from '../../sharedutil'
export function stringToHslColor(str, s, l) {
  if (str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  } else {
    return "hsl(" + 180 + ", " + s + "%, " + l + "%)";
  }
}
