import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import PageHeader from "../Header";
import { Icon, Menu, Sidebar } from "semantic-ui-react";
import { DashBoardContaier } from "./Styled";
import RebootWorkspace from "./RebootWorkspace";
import Worksheet from "./Worksheet";
import {
  DEPLOYMENT_PLAN,
  SUPPORT_REFERENCE,
  JIRA_LINK,
  CONFLUENCE_LINK,
  ADAXES_LINK,
  GENERAL_COMPANY_INFO,
  ON_BOARDING,
  ORG_CHART,
  DEVELOPMENT_INFORMATION,
} from "../Public/Links";

/**
 * Logo
 */
const JIRA_LOGO = require("../../img/jira.svg");
const CONFLUENCE_LOGO = require("../../img/confluence.svg");
const ADAXES_LOGO = require("../../img/adaxes.svg");

class Home extends React.Component {
  componentDidMount() {
    document.title = "aquariux+";
  }

  render() {
    return (
      <>
        <PageHeader>
          <span className="title">Dashboard</span>
        </PageHeader>
        <DashBoardContaier>
          <h3 className="sub-title">Work Related</h3>
          <div className="home-wrapper">
            {/* <Link className="dashboard-item" to="/jira">
              <div className="label">
                Franchise Issue Generator
                <div className="desc">To create new franchise tickets</div>
              </div>
              <Icon name="ticket" />
            </Link> */}
            {/*<Link className="dashboard-item" to="/updateTicket">*/}
            {/*  <div className="label">*/}
            {/*    Ticket Update*/}
            {/*    <div className="desc">*/}
            {/*      To update sub tickets based on main ticket*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <Icon name="refresh" />*/}
            {/*</Link>*/}
            {/* <Link className="dashboard-item" to="/app">
              <div className="label">
                App Version Update
                <div className="desc">
                  To create DBA tickets that will update iOS and Android App
                  version
                </div>
              </div>
              <Icon name="mobile alternate" />
            </Link>
            <Link className="dashboard-item" to="/incident">
              <div className="label">
                Incident Ticket
                <div className="desc">To list down all incident tickets</div>
              </div>
              <Icon name="file alternate outline" />
            </Link> */}
            <Link className="dashboard-item" to="/live">
              <div className="label">
                {/* Weekly Deployment Issue */}
                Deployment Tickets
                <div className="desc">
                  To list down all issues that will be deployed to production
                </div>
              </div>
              <Icon name="chart line" />
            </Link>
            <Link className="dashboard-item" to="/timeline">
              <div className="label">
                {/* Weekly Deployment Issue */}
                Timeline
                <div className="desc">
                  To breakdown the progress of the Story
                </div>
              </div>
              <Icon name="chart bar outline" />
            </Link>
            {/* <Link className="dashboard-item" to="/translate">
              <div className="label">
                Translator (Beta)
                <div className="desc">
                  To collaborate the translation for back office and member site
                </div>
              </div>
              <Icon name="translate" />
            </Link>
            <Link className="dashboard-item" to="/awsStatus">
              <div className="label">
                AWS Status
                <div className="desc">
                  To check environment status during deployment
                </div>
              </div>
              <Icon name="aws" />
            </Link>
            <Link className="dashboard-item" to="/jenkinsStatus">
              <div className="label">
                Jenkins Status
                <div className="desc">To check frontend jenkins job status</div>
              </div>
              <Icon name="jenkins" />
            </Link>
            <Worksheet />
            <Link className="dashboard-item" to="/supportForm">
              <div className="label">
                Support Form Generator
                <div className="desc">To generate monthly support report</div>
              </div>
              <Icon name="file pdf outline" />
            </Link> */}
            <Link className="dashboard-item" to="/calendar">
              <div className="label">
                Staff Calendar
                <div className="desc">
                  To view staff calendar of whole company
                </div>
              </div>
              <Icon name="calendar" />
            </Link>
            {/*<Link className="dashboard-item" to="/grouping">*/}
            {/*  <div className="label">*/}
            {/*    Grouping*/}
            {/*    <div className="desc">To form groups for events</div>*/}
            {/*  </div>*/}
            {/*  <Icon name="random" />*/}
            {/*</Link>*/}
            {/*<RebootWorkspace />*/}
            <a
              className="dashboard-item"
              href={DEPLOYMENT_PLAN}
              target="_blank"
            >
              <div className="label">
                Deployment Plan
                <div className="desc">
                  To plan the deployment steps in Google Sheets
                </div>
              </div>
              <Icon name="flag" />
            </a>

            <a
              className="dashboard-item"
              href={SUPPORT_REFERENCE}
              target="_blank"
            >
              <div className="label">
                Support Reference
                <div className="desc">To refer who's on duty</div>
              </div>
              <Icon name="tty" />
            </a>
          </div>

          <h3 className="sub-title">Systems</h3>
          <div className="home-wrapper">
            <a className="dashboard-item" href={JIRA_LINK} target="_blank">
              <div className="label">
                Jira
                <div className="desc">Company's Jira</div>
              </div>
              <img src={JIRA_LOGO} />
            </a>
            <a
              className="dashboard-item"
              href={CONFLUENCE_LINK}
              target="_blank"
            >
              <div className="label">
                Confluence
                <div className="desc">Company's Confluence</div>
              </div>
              <img src={CONFLUENCE_LOGO} />
            </a>
            <a
              className="dashboard-item"
              href={ADAXES_LINK}
              target="_blank"
            >
              <div className="label">
                Adaxes
                <div className="desc">Company's Adaxes</div>
              </div>
              <img src={ADAXES_LOGO} />
            </a>

          </div>

          <h3 className="sub-title">New Employee Section</h3>
          <div className="home-wrapper">
            <a
              className="dashboard-item"
              href={GENERAL_COMPANY_INFO}
              target="_blank"
            >
              <div className="label">
                General Company Information
                <div className="desc">Information about the company</div>
              </div>
              <Icon name="info circle" />
            </a>
            <a className="dashboard-item" href={ON_BOARDING} target="_blank">
              <div className="label">
                On-boarding
                <div className="desc">On-boarding for new joiners</div>
              </div>
              <Icon name="vcard" />
            </a>
            <a className="dashboard-item" href={ORG_CHART} target="_blank">
              <div className="label">
                Organization Chart
                <div className="desc">Organization Chart of the company</div>
              </div>
              <Icon name="group" />
            </a>
            <a
              className="dashboard-item"
              href={DEVELOPMENT_INFORMATION}
              target="_blank"
            >
              <div className="label">
                Development Information
                <div className="desc">Useful information for developers</div>
              </div>
              <Icon name="bug" />
            </a>
          </div>
        </DashBoardContaier>
      </>
    );
  }
}

export default withRouter(Home);
