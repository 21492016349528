import * as React from "react";
import UserProvider from "./Context";

class AppProvider extends React.Component {
  state = {
    username: "",
    isLogin: false,
    bearer: "",
    isDarkTheme: JSON.parse(localStorage.getItem("isDarkTheme")) || false
  };
  render() {
    return (
      <UserProvider.Provider
        value={{
          username: this.state.username,
          isLogin: this.state.isLogin,
          bearer: this.state.bearer,
          isDarkTheme: this.state.isDarkTheme,
          setUsername: username => {
            this.setState({
              username
            });
          },
          setBearer: bearer => {
            this.setState({
              bearer
            });
          },
          toggleLoginState: state => {
            if (state === false) {
              localStorage.setItem("token", "");
              localStorage.setItem("oauth", "0");
            }
            this.setState({
              isLogin: state ? state : !this.state.isLogin
            });
          },
          toggleDarkTheme: () => {
            const isDarkTheme = !this.state.isDarkTheme;
            localStorage.setItem("isDarkTheme", isDarkTheme);
            this.setState({
              isDarkTheme: !this.state.isDarkTheme
            });
          }
        }}
      >
        {this.props.children}
      </UserProvider.Provider>
    );
  }
}

export default AppProvider;
