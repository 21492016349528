import * as React from "react";

const PIXI = require("pixi.js");
const banner1Path = require("./img/CNY/banner1.png");

class PIXICloth extends React.Component {
  componentDidMount() {
    const { position } = this.props;

    const globalWidth =
      position === "top" ? window.innerWidth : window.innerWidth;
    const globalHeight = position === "top" ? 200 : window.innerHeight - 35;

    let mesh;
    let cloth;
    let spacingX = 2;
    let spacingY = 2;
    let accuracy = 1;

    let opts = {
      image: banner1Path,
      gravity: 981,
      friction: 0.99,
      bounce: 0,
      pointsX: 20,
      pointsY: 9,
      renderCloth: true,
      mouseInfluence: 50,
      pinCorners: true,
      randomImage() {
        this.image =
          "https://unsplash.it/400/400?image=" +
          Math.floor(Math.random() * 1100);
        loadTexture();
      }
    };

    let canvas = document.createElement("canvas");
    if (position === "top") {
      Object.assign(canvas.style, {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        opacity: 0
      });
    } else if (position === "right") {
      Object.assign(canvas.style, {
        position: "fixed",
        top: 200,
        right: 0,
        bottom: 0,
        zIndex: 1,
        opacity: 0
      });
    } else if (position === "left") {
      Object.assign(canvas.style, {
        position: "fixed",
        top: 200,
        left: 0,
        bottom: 0,
        zIndex: 1,
        opacity: 0
      });
    } else {
      Object.assign(canvas.style, {
        position: "fixed",
        top: 40,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 1,
        opacity: 0
      });
    }

    let ctx = canvas.getContext("2d");
    document.getElementById("cnycanvas").appendChild(canvas);

    ctx.strokeStyle = "#555";

    let mouse = {
      down: false,
      x: 0,
      y: 0,
      px: 0,
      py: 1
    };

    /*////////////////////////////////////////*/

    let stage = new PIXI.Container();
    let renderer = PIXI.autoDetectRenderer(globalWidth, globalHeight, {
      transparent: true
    });

    if (position === "top") {
      Object.assign(renderer.view.style, {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10
      });
    } else if (position === "right") {
      Object.assign(renderer.view.style, {
        position: "fixed",
        top: 200,
        right: 0,
        bottom: 0,
        zIndex: 10
      });
    } else if (position === "left") {
      Object.assign(renderer.view.style, {
        position: "fixed",
        top: 200,
        left: 0,
        bottom: 0,
        zIndex: 10
      });
    } else {
      Object.assign(renderer.view.style, {
        position: "fixed",
        top: 40,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 10
      });
    }

    document.getElementById("cnycanvas").insertBefore(renderer.view, canvas);
    renderer.render(stage);

    canvas.width = renderer.width;
    canvas.height = renderer.height;

    /*////////////////////////////////////////*/

    function loadTexture() {
      console.log("loading texture", opts.image);

      document.body.className = "loading";

      let texture = new PIXI.Texture.fromImage(opts.image);
      if (!texture.requiresUpdate) {
        texture.update();
      }

      texture.on("error", function() {
        console.error("AGH!");
      });

      texture.on("update", function() {
        document.body.className = "";

        console.log("texture loaded");

        if (mesh) {
          stage.removeChild(mesh);
        }

        mesh = new PIXI.mesh.Plane(this, opts.pointsX, opts.pointsY);
        mesh.width = this.width;
        mesh.height = this.height;

        spacingX = mesh.width / (opts.pointsX - 1);
        spacingY = mesh.height / (opts.pointsY - 1);

        cloth = new Cloth(
          opts.pointsX - 1,
          opts.pointsY - 1,
          !opts.pinCorners,
          window.innerWidth / 2 - mesh.width / 2,
          1
        );

        stage.addChild(mesh);
      });
    }

    loadTexture(opts.image);

    (function update() {
      requestAnimationFrame(update);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (cloth) {
        cloth.update(0.016);
      }
      renderer.render(stage);
    })(0);

    /*////////////////////////////////////////*/

    class Point {
      constructor(x, y) {
        this.x = x;
        this.y = y;
        this.px = x;
        this.py = y;
        this.vx = 0;
        this.vy = 0;
        this.pinX = null;
        this.pinY = null;

        this.constraints = [];
      }

      update(delta) {
        if (this.pinX && this.pinY) return this;

        if (mouse.down) {
          let dx = this.x - mouse.x;
          let dy = this.y - mouse.y;
          let dist = Math.sqrt(dx * dx + dy * dy);

          if (mouse.button === 1 && dist < opts.mouseInfluence) {
            this.px = this.x - (mouse.x - mouse.px);
            this.py = this.y - (mouse.y - mouse.py);
          } else if (dist < mouse.cut) {
            this.constraints = [];
          }
        }

        this.addForce(0, opts.gravity);

        let nx = this.x + (this.x - this.px) * opts.friction + this.vx * delta;
        let ny = this.y + (this.y - this.py) * opts.friction + this.vy * delta;

        this.px = this.x;
        this.py = this.y;

        this.x = nx;
        this.y = ny;

        this.vy = this.vx = 0;

        if (this.x >= canvas.width) {
          this.px = canvas.width + (canvas.width - this.px) * opts.bounce;
          this.x = canvas.width;
        } else if (this.x <= 0) {
          this.px *= -1 * opts.bounce;
          this.x = 0;
        }

        if (this.y >= canvas.height) {
          this.py = canvas.height + (canvas.height - this.py) * opts.bounce;
          this.y = canvas.height;
        } else if (this.y <= 0) {
          this.py *= -1 * opts.bounce;
          this.y = 0;
        }

        return this;
      }

      draw() {
        let i = this.constraints.length;
        while (i--) this.constraints[i].draw();
      }

      resolve() {
        if (this.pinX && this.pinY) {
          this.x = this.pinX;
          this.y = this.pinY;
          return;
        }

        this.constraints.forEach(constraint => constraint.resolve());
      }

      attach(point) {
        this.constraints.push(new Constraint(this, point));
      }

      free(constraint) {
        this.constraints.splice(this.constraints.indexOf(constraint), 1);
      }

      addForce(x, y) {
        this.vx += x;
        this.vy += y;
      }

      pin(pinx, piny) {
        this.pinX = pinx;
        this.pinY = piny;
      }

      unpin() {
        this.pinX = null;
        this.pinY = null;
      }
    }

    /*////////////////////////////////////////*/

    class Constraint {
      constructor(p1, p2, length) {
        this.p1 = p1;
        this.p2 = p2;
        this.length = length || spacingX;
      }

      resolve() {
        let dx = this.p1.x - this.p2.x;
        let dy = this.p1.y - this.p2.y;
        let dist = Math.sqrt(dx * dx + dy * dy);

        if (dist < this.length) return;

        let diff = (this.length - dist) / dist;

        //if (dist > tearDist) this.p1.free(this)

        let mul = diff * 0.5 * (1 - this.length / dist);

        let px = dx * mul;
        let py = dy * mul;

        !this.p1.pinX && (this.p1.x += px);
        !this.p1.pinY && (this.p1.y += py);
        !this.p2.pinX && (this.p2.x -= px);
        !this.p2.pinY && (this.p2.y -= py);

        return this;
      }

      draw() {
        ctx.moveTo(this.p1.x, this.p1.y);
        ctx.lineTo(this.p2.x, this.p2.y);
      }
    }

    /*////////////////////////////////////////*/

    let count = 0;

    class Cloth {
      constructor(clothX, clothY, free, x, y) {
        this.points = [];

        let startX = x ? x : canvas.width / 2 - (clothX * spacingX) / 2;
        let startY = y ? y : canvas.height * 0.2;

        for (let y = 0; y <= clothY; y++) {
          for (let x = 0; x <= clothX; x++) {
            let point = new Point(
              startX + x * spacingX - spacingX * Math.sin(y),
              y * spacingY + startY + (y !== 0 ? 5 * Math.cos(x) : 0)
            );
            !free && y === 0 && point.pin(point.x, point.y);
            x !== 0 && point.attach(this.points[this.points.length - 1]);
            y !== 0 && point.attach(this.points[x + (y - 1) * (clothX + 1)]);

            this.points.push(point);
          }
        }
      }

      update(delta) {
        let i = accuracy;

        while (i--) {
          this.points.forEach(point => {
            point.resolve();
          });
        }

        ctx.beginPath();

        this.points.forEach((point, i) => {
          point.update(delta * delta);

          if (opts.renderCloth) {
            point.draw();
          }

          if (mesh) {
            i *= 2;
            mesh.vertices[i] = point.x;
            mesh.vertices[i + 1] = point.y;
          }
        });

        ctx.stroke();
      }
    }

    function pointerMove(e) {
      let pointer = e.touches ? e.touches[0] : e;
      mouse.px = mouse.x || pointer.clientX;
      mouse.py = mouse.y || pointer.clientY;
      mouse.x = pointer.clientX;
      mouse.y = pointer.clientY;
    }

    function pointerDown(e) {
      mouse.down = true;
      mouse.button = 1;
      pointerMove(e);
    }

    function pointerUp(e) {
      mouse.down = false;
      mouse.px = null;
      mouse.py = null;
      console.log("pointer up");
    }

    document
      .getElementById("cnycanvas")
      .addEventListener("mousedown", pointerDown);
    document
      .getElementById("cnycanvas")
      .addEventListener("touchstart", pointerDown);

    document
      .getElementById("cnycanvas")
      .addEventListener("mousemove", pointerMove);
    document
      .getElementById("cnycanvas")
      .addEventListener("touchmove", pointerMove);

    document.getElementById("cnycanvas").addEventListener("mouseup", pointerUp);
    document
      .getElementById("cnycanvas")
      .addEventListener("touchend", pointerUp);
    document
      .getElementById("cnycanvas")
      .addEventListener("mouseleave", pointerUp);

    window.onresize = function(event) {
      const globalWidth =
        position === "top" ? window.innerWidth : window.innerWidth;
      const globalHeight = position === "top" ? 200 : window.innerHeight - 35;

      renderer.resize(globalWidth, globalHeight);
      // renderer.width = globalWidth;
      // renderer.height = globalHeight;
      canvas.width = renderer.width;
      canvas.height = renderer.height;
    };
  }
  render() {
    return (
      <div
        id="cnycanvas"
        style={{
          position: "fixed",
          zIndex: 100,
          pointerEvents: "all"
        }}
      />
    );
  }
}

export default PIXICloth;
