import React from "react";

const OrionLogo = (props) => {
  const width = props.width || "100%";
  const height = props.height || "100%";
  const color = props.color || "#888";
  const className = props.className || undefined;
  return (
    <svg
      viewBox="0 0 256 56"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path
        d="M224.826 12.524h8.07l10.69 14.42-12.74 16.47h-8.07l12.54-16.27-10.49-14.62z"
        fill="#4c75f1"
      />
      <path
        d="M246.636 12.384h8.38l-9.48 12.39-4.21-5.78 5.31-6.61zM247.366 43.524h8.38l-10.47-14.06-4.2 5.79 6.29 8.27z"
        fill="#fff"
      />
      <path
        d="M33.15 44.802V31.71c0-6.509-.53-9.082-2.573-12.26-2.876-4.54-8.325-7.417-13.85-7.417-9.156 0-16.724 7.568-16.724 16.65 0 9.005 7.492 16.573 16.27 16.573 3.406 0 6.509-1.135 9.46-3.406v2.952h7.417zM16.879 19.299c5.222 0 9.157 4.162 9.157 9.61 0 5.147-4.087 9.082-9.233 9.082-5.297 0-9.384-4.087-9.384-9.308 0-5.298 4.162-9.384 9.46-9.384zM72.35 55.548V31.71c0-6.509-.53-9.082-2.573-12.26-2.875-4.54-8.324-7.417-13.849-7.417-9.157 0-16.724 7.568-16.724 16.65 0 9.005 7.492 16.573 16.27 16.573 3.406 0 6.509-1.135 9.46-3.406v13.698h7.416zm-16.27-36.25c5.222 0 9.157 4.163 9.157 9.612 0 5.146-4.087 9.08-9.233 9.08-5.297 0-9.384-4.086-9.384-9.307 0-5.298 4.162-9.384 9.46-9.384zM78.804 12.488v17.784c0 4.919.816 7.416 3.264 10.14 2.774 3.103 6.936 4.844 11.424 4.844 4.488 0 8.731-1.816 11.424-4.995 2.285-2.573 3.264-5.373 3.264-9.46V12.488h-7.997v16.195c0 3.632-.326 5.146-1.306 6.66-1.06 1.588-3.264 2.648-5.467 2.648-2.121 0-4.325-1.135-5.304-2.725-.98-1.589-1.306-3.405-1.306-7.113V12.488h-7.996zM147.423 44.802V31.71c0-6.509-.53-9.082-2.573-12.26-2.876-4.54-8.325-7.417-13.85-7.417-9.156 0-16.724 7.568-16.724 16.65 0 9.005 7.492 16.573 16.27 16.573 3.406 0 6.509-1.135 9.46-3.406v2.952h7.417zm-16.271-25.503c5.222 0 9.157 4.162 9.157 9.61 0 5.147-4.087 9.082-9.233 9.082-5.297 0-9.384-4.087-9.384-9.308 0-5.298 4.162-9.384 9.46-9.384zM161.832 44.802V27.774c0-6.205 1.513-7.946 7.113-8.324v-7.19c-.757-.075-1.438-.075-1.816-.075-8.173 0-12.714 4.843-12.714 13.622v18.995h7.417zM183.99 44.802V12.488h-7.416v32.314h7.416zM180.282 0c-2.498 0-4.617 2.119-4.617 4.616 0 2.497 2.044 4.616 4.541 4.616 2.497 0 4.692-2.119 4.692-4.616 0-2.649-1.968-4.616-4.616-4.616zM190.863 12.488v17.784c0 4.919.815 7.416 3.261 10.14 2.772 3.103 6.93 4.844 11.414 4.844s8.724-1.816 11.414-4.995c2.283-2.573 3.261-5.373 3.261-9.46V12.488h-7.99v16.195c0 3.632-.326 5.146-1.304 6.66-1.06 1.588-3.261 2.648-5.462 2.648-2.12 0-4.321-1.135-5.3-2.725-.978-1.589-1.304-3.405-1.304-7.113V12.488h-7.99z"
        fill="#fff"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default OrionLogo;
