import * as React from "react";
import {
  Button,
  Checkbox,
  Form,
  Step,
  Segment,
  Icon,
  Message,
  Transition,
} from "semantic-ui-react";
import axios from "axios";
import cogoToast from "cogo-toast";
import Ajax from "../../Public/Ajax";

import {
  Header,
  FormWrapper,
  DetailsWrapper,
  MainWrapper,
  LabelTitle,
  SummaryWrapper, CodeWrapper,
} from "./Styled";
import PoweredByJira from "../../Public/PoweredByJira";

export default class JiraForm extends React.Component {
  checkboxArr = ["cash"];
  state = {
    currSteps: 2,

    responseData: "",
    responseDataVisibleMap: [],
    cashVersion: "",
    cashExclude: "",
    cashExcludeVersion: "",
    creditVersion: "",
    dyhVersion: "",

    createTicket: "",
    token: "",
    checkboxArrState: ["cash"],
    isFetching: false,
    cCashScript: true,
    cCreditScript: true,
    cDyhScript: true,
  };

  handleClick = (key) => {
    if (key === 2) {
      this.setState({
        currSteps: key,
        // checkboxArrState: [],
        isFetching: false,
      });
    } else {
      this.setState({ currSteps: key });
    }
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    const {
      cashVersion,
      cashExclude,
      cashExcludeVersion,
      creditVersion,
      dyhVersion,
      checkboxArrState,
    } = this.state;

    let isValid = true;

    checkboxArrState.forEach((item) => {
      switch (item) {
        case "cash":
          if (cashVersion === "") {
            isValid = false;
          }
          break;
        case "credit":
          if (creditVersion === "") {
            isValid = false;
          }
          break;
        case "dyh":
          if (dyhVersion === "") {
            isValid = false;
          }
          break;
        default:
          return;
      }
    });

    if (isValid && checkboxArrState.length !== 0) {
      this.setState({
        isFetching: true,
      });
      this.getJiraIssueList();
    } else {
      cogoToast.error("Please fill in all the details");
    }
  };

  handleResponseData = (responseData) => {
    this.setState({
      responseData: responseData,
    });
  };

  listFranchiseProperties = async () => {
    const that = this;
    Ajax("/api/jenkins/listFranchiseProperties", {
      method: "GET",
    }).then(function (response) {
      const cashResign = response.filter(
        (item) => item.key === "cash_resign"
      )[0];

      const cashResignFiltered = cashResign.list.filter(
        (item) => !item.includes("dsn")
      );
      that.setState({
        cashExclude: cashResignFiltered.join(","),
      });
    });
  };

  getJiraIssueList = async () => {
    const {
      cashVersion,
      cashExclude,
      cashExcludeVersion,
      dsnVersion,
      dsnId,
      creditVersion,
      dyhVersion,
      currSteps,
      cCashScript,
      cCreditScript,
      cDyhScript,
    } = this.state;
    let nextStep = currSteps + 1;

    const self = this;

    const data = {
      ...(cashVersion && { cashVersion: cashVersion }),
      ...(cashExclude && { cashExclude: cashExclude }),
      ...(cashExcludeVersion && { cashExcludeVersion: cashExcludeVersion }),
      ...(dsnVersion && { dsnVersion: dsnVersion }),
      ...(dsnId && { dsnId: dsnId }),
      ...(creditVersion && { creditVersion: creditVersion }),
      ...(dyhVersion && { dyhVersion: dyhVersion }),
      ...(cCashScript && { cCashScript: cCashScript }),
      ...(cCreditScript && { cCreditScript: cCreditScript }),
      ...(cDyhScript && { cDyhScript: cDyhScript }),
    };

    await axios
      .post("/api/jira/appList", data)
      .then(function (response) {
        console.log(response);
        let responseDataVisibleMap = [];
        for (let i in response.data) {
          responseDataVisibleMap.push(true);
        }
        self.setState({
          currSteps: nextStep,
          responseData: response.data,
          responseDataVisibleMap: responseDataVisibleMap,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  deleteIssue = (index) => {
    const { responseData, responseDataVisibleMap } = this.state;
    const processedData = responseData;

    let responseDataVisibleMapTemp = responseDataVisibleMap;
    responseDataVisibleMapTemp[index] = false;

    // TO-DO
    // Visible map is to render animation
    this.setState({
      responseDataVisibleMap: responseDataVisibleMapTemp,
    });

    // Remove element after animation end
    setTimeout(() => {
      processedData.splice(index, 1);
      responseDataVisibleMapTemp.splice(index, 1);
      this.setState({
        responseData: processedData,
        responseDataVisibleMap: responseDataVisibleMapTemp,
      });
      console.log(responseData);
    }, 300);
  };

  handleChangeCheckbox = (key, isChecked) => {
    if (isChecked && (key === "cash" || key === "credit" || key === "dyh")) {
      this.checkboxArr.push(key);
    } else {
      switch (key) {
        case "cash":
          this.setState({
            cashVersion: "",
            cashExclude: "",
            cashExcludeVersion: "",
            dsnVersion: "",
            dsnId: "",
          });
          break;
        case "credit":
          this.setState({
            creditVersion: "",
          });
          break;
        case "dyh":
          this.setState({
            dyhVersion: "",
          });
          break;
        case "cashScript":
          this.setState({
            cCashScript: !this.state.cCashScript,
          });
          break;
        case "creditScript":
          this.setState({
            cCreditScript: !this.state.cCreditScript,
          });
          break;
        case "dyhScript":
          this.setState({
            cDyhScript: !this.state.cDyhScript,
          });
          break;
        default:
          return;
      }

      this.checkboxArr = this.checkboxArr.filter(function (item) {
        return item !== key;
      });
    }
    this.setState({
      checkboxArrState: this.checkboxArr,
    });
  };

  createMarkup = (HTMLString) => {
    return { __html: HTMLString };
  };

  createJiraTicket = () => {
    const { responseData, mainTicket } = this.state;
    // console.log(responseData);
    const token = localStorage.getItem("token");
    axios
      .post("/api/jira/createTicket", {
        data: responseData,
        token: token,
        mainTicket: mainTicket,
        withAttachment: true,
      })
      .then(function (response) {
        console.log(response);
        if (response.errorMessages) {
          cogoToast.error(response.errorMessages[0]);
        } else {
          cogoToast.success("Successfully created Jira Ticket");
        }
      })
      .catch(function (error) {
        cogoToast.error("Failed to create Jira Ticket");
      });
  };

  async componentDidMount() {
    await this.listFranchiseProperties();
  }

  render() {
    const {
      responseData,
      responseDataVisibleMap,
      currSteps,
      isFetching,
      checkboxArrState,
      cashVersion,
      cashExclude,
      cashExcludeVersion,
      dsnVersion,
      dsnId,
      creditVersion,
      dyhVersion,
      cCashScript,
      cCreditScript,
      cDyhScript,
    } = this.state;

    return (
      <FormWrapper>
        <Step.Group widths={3}>
          <Step
            active={currSteps === 2}
            key="2"
            completed={currSteps > 2}
            onClick={currSteps > 2 ? () => this.handleClick(2) : null}
          >
            <Icon name="info circle" />
            <Step.Content>
              <Step.Title>Info</Step.Title>
              <Step.Description>
                Information required to open ticket
              </Step.Description>
            </Step.Content>
          </Step>

          <Step
            active={currSteps === 3}
            key="3"
            completed={currSteps > 3}
            onClick={currSteps >= 3 ? () => this.handleClick(3) : null}
          >
            <Icon name="list alternate outline" />
            <Step.Content>
              <Step.Title>Ticket</Step.Title>
              <Step.Description>Double check the information</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
        <Segment attached>
          {/*Steps 2*/}
          {currSteps === 2 && (
            <Form onSubmit={this.handleSubmit}>
              <Form.Group widths="equal">
                <Form.Field>
                  <Checkbox
                    style={{ marginRight: 20 }}
                    checked={checkboxArrState.some((item) => item === "cash")}
                    onChange={(e, data) =>
                      this.handleChangeCheckbox("cash", data.checked)
                    }
                    label="Cash"
                  />
                  <Checkbox
                    style={{ marginRight: 20 }}
                    checked={checkboxArrState.some((item) => item === "credit")}
                    onChange={(e, data) =>
                      this.handleChangeCheckbox("credit", data.checked)
                    }
                    label="Credit"
                  />
                  <Checkbox
                    style={{ marginRight: 20 }}
                    checked={checkboxArrState.some((item) => item === "dyh")}
                    onChange={(e, data) =>
                      this.handleChangeCheckbox("dyh", data.checked)
                    }
                    label="DYH"
                  />
                </Form.Field>
              </Form.Group>
              {checkboxArrState.some((item) => item === "cash") && (
                <Segment>
                  <Form.Field>
                    <Form.Input
                      label={"Cash Version"}
                      placeholder="2.2.9"
                      name="cashVersion"
                      value={cashVersion}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Form.Input
                        label={"Exclude"}
                        placeholder="dsn,dsn2,dsn3"
                        name="cashExclude"
                        value={cashExclude}
                        onChange={this.handleChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        label={"Exclude Version"}
                        placeholder="2.2.8"
                        name="cashExcludeVersion"
                        value={cashExcludeVersion}
                        onChange={this.handleChange}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Form.Input
                        label={"DSN Version"}
                        placeholder="3.0.2"
                        name="dsnVersion"
                        value={dsnVersion}
                        onChange={this.handleChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        label={"DSN App ID"}
                        placeholder="dsn, dsn_agad"
                        name="dsnId"
                        value={dsnId}
                        onChange={this.handleChange}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Field>
                    <Checkbox
                      style={{ marginRight: 20 }}
                      checked={cCashScript}
                      onChange={(e, data) =>
                        this.handleChangeCheckbox("cashScript", data.checked)
                      }
                      label="Create Cash DB Script"
                    />
                  </Form.Field>
                </Segment>
              )}

              {checkboxArrState.some((item) => item === "credit") && (
                <Segment>
                  <Form.Field>
                    <Form.Input
                      label={"Credit Version"}
                      placeholder="1.0.26"
                      name="creditVersion"
                      value={creditVersion}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      style={{ marginRight: 20 }}
                      checked={cCreditScript}
                      onChange={(e, data) =>
                        this.handleChangeCheckbox("creditScript", data.checked)
                      }
                      label="Create Credit DB Script"
                    />
                  </Form.Field>
                </Segment>
              )}
              {checkboxArrState.some((item) => item === "dyh") && (
                <Segment>
                  <Form.Field>
                    <Form.Input
                      label={"DYH Version"}
                      placeholder="3.0.11"
                      name="dyhVersion"
                      value={dyhVersion}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      style={{ marginRight: 20 }}
                      checked={cDyhScript}
                      onChange={(e, data) =>
                        this.handleChangeCheckbox("dyhScript", data.checked)
                      }
                      label="Create DYH DB Script"
                    />
                  </Form.Field>
                </Segment>
              )}
              <Form.Field>
                {!isFetching && (
                  <Transition
                    visible={!isFetching}
                    animation="fade up"
                    duration={300}
                  >
                    <Button
                      primary
                      loading={isFetching}
                      disabled={isFetching}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Transition>
                )}
              </Form.Field>
              <Transition
                visible={isFetching}
                animation="fade down"
                duration={500}
              >
                <div>
                  <Message icon color="blue">
                    <Icon name="circle notched" loading />
                    <Message.Content>
                      <Message.Header>Just one second</Message.Header>
                      Checking domain availability
                    </Message.Content>
                  </Message>
                </div>
              </Transition>
            </Form>
          )}

          {/*Steps 3*/}
          <Transition
            visible={currSteps === 3}
            animation="fade right"
            duration={500}
          >
            <DetailsWrapper>
              <Form>
                {responseData &&
                  responseData.map((item, index) => {
                    const summary = item.summary.replace(/\n/gi, "<br/>");
                    console.log(responseDataVisibleMap);
                    return (
                      <MainWrapper
                        key={Date.now() + item.title}
                        className={
                          responseDataVisibleMap[index] ? "" : "deleting"
                        }
                      >
                        <LabelTitle>{item.title}</LabelTitle>
                        <button
                          className="delete-issue"
                          onClick={this.deleteIssue.bind(this, index)}
                        >
                          <Icon name="delete" />
                        </button>
                        <span className="ticket-type">{item.type}</span>
                        <SummaryWrapper
                          dangerouslySetInnerHTML={this.createMarkup(summary)}
                        />
                        <CodeWrapper
                          dangerouslySetInnerHTML={this.createMarkup(
                            item.dbScript
                          )}
                        />
                      </MainWrapper>
                    );
                  })}
                <Form.Field>
                  <Button
                    primary
                    type="submit"
                    onClick={() => this.createJiraTicket()}
                  >
                    Submit
                  </Button>
                </Form.Field>
              </Form>
            </DetailsWrapper>
          </Transition>
        </Segment>
        <PoweredByJira />
      </FormWrapper>
    );
  }
}
