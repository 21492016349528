import * as React from "react";
import { Icon, Confirm, Button, Modal } from "semantic-ui-react";
import { Link } from "react-router-dom";
import UserContext from "../../Context";
import axios from "axios";
import { WorkspaceWrapper } from "./Styled";
import cogoToast from "cogo-toast";

const validOutcomeState = [
  "AVAILABLE",
  "IMPAIRED",
  "MAINTENANCE",
  "ADMIN_MAINTENANCE",
  "SUSPENDED",
  "STOPPED",
  "ERROR"
];

class RebootWorkspace extends React.PureComponent {
  checkingTimer;
  state = {
    username: "",
    isWorkspaceAvailable: false,
    open: "",
    isSubmitting: false,
    workspaceState: "",
    workspaceUsername: "",
    workspaceArr: []
  };

  checkWorkspaceAvailable = async () => {
    const userContext = this.context;
    const username = userContext.username;
    const that = this;
    await axios
      .get(`/api/aws/listWorkspace?username=${username}`)
      .then(function(response) {
        const data = response && response.data;
        if (Array.isArray(data)) {
          that.setState({
            isWorkspaceAvailable: true,
            workspaceArr: data.map(item => {
              return {
                workspaceState: item.state,
                workspaceUsername: item.username
              };
            })
          });
        } else {
          that.setState({
            isWorkspaceAvailable: data.success,
            workspaceState: data.state,
            workspaceUsername: data.username
          });
        }
      });
  };

  initCheckingTimer = workspaceUsername => {
    const that = this;
    this.checkingTimer = setInterval(async () => {
      console.log("Checking status");
      await that.checkWorkspaceAvailable();
      const { workspaceState, workspaceArr } = that.state;
      let isValidState;
      if (workspaceArr.length !== 0) {
        const currentState = workspaceArr.find(
          item => item.workspaceUsername === workspaceUsername
        ).workspaceState;
        isValidState = validOutcomeState.find(item => item === currentState);
      } else {
        isValidState = validOutcomeState.find(item => item === workspaceState);
      }

      console.log(isValidState);
      if (isValidState) {
        clearInterval(this.checkingTimer);
      }
    }, 1000 * 10);
  };

  rebootWorkspace = async workspaceUsername => {
    const userContext = this.context;
    const username = userContext.username;
    const workspaceName = workspaceUsername;
    const token = localStorage.getItem("token");
    const that = this;
    this.setState({
      isSubmitting: true
    });
    await axios
      .get(
        `/api/aws/rebootWorkspace?username=${username}&token=${token}${
          workspaceName ? `&workspaceName=${workspaceName}` : ""
        }`
      )
      .then(function(response) {
        const isSuccess = response && response.data && response.data.success;
        const message = response && response.data && response.data.message;
        if (isSuccess) {
          cogoToast.success("Workspace restarted");
          that.initCheckingTimer(workspaceUsername);
        } else {
          cogoToast.error(message);
        }
        that.setState({
          open: false,
          isSubmitting: false
        });
      });
  };

  async componentDidMount() {
    const userContext = this.context;
    this.setState({
      username: userContext.username
    });
    await this.checkWorkspaceAvailable();
  }

  componentWillUnmount() {
    clearInterval(this.checkingTimer);
  }

  open = workspaceUsername => this.setState({ open: workspaceUsername });
  close = () => this.setState({ open: "" });

  render() {
    const {
      username,
      isWorkspaceAvailable,
      isSubmitting,
      workspaceState,
      workspaceUsername,
      workspaceArr
    } = this.state;
    if (!isWorkspaceAvailable) return <></>;
    return (
      <>
        {workspaceArr.length !== 0 ? (
          <Modal
            trigger={
              <a className="dashboard-item">
                <div className="label">
                  Restart Workspace
                  <div className="desc">
                    This will open a modal with a list Workspace for {username}
                  </div>
                </div>
                <Icon name="repeat" />
              </a>
            }
          >
            <Modal.Header>Reboot workspace</Modal.Header>
            <Modal.Content style={{ background: "#f7faff" }}>
              <WorkspaceWrapper>
                {workspaceArr.map((item, index) => {
                  return (
                    <>
                      <Confirm
                        key={`confirm-${item.workspaceUsername}`}
                        open={this.state.open === item.workspaceUsername}
                        content={`Are you sure you want to restart Workspace of [${
                          item.workspaceUsername
                        }]?`}
                        onCancel={this.close}
                        onConfirm={() =>
                          this.rebootWorkspace(item.workspaceUsername)
                        }
                        confirmButton={
                          <Button loading={isSubmitting} color="blue">
                            OK
                          </Button>
                        }
                      />
                      <a
                        key={`button-${item.workspaceUsername}`}
                        className="workspace-item"
                        onClick={() => this.open(item.workspaceUsername)}
                      >
                        <div className="label">
                          Restart Workspace
                          <div className="desc">
                            This will restart Workspace of{" "}
                            {item.workspaceUsername}
                            <div>
                              Status: <span>{item.workspaceState}</span>
                            </div>
                          </div>
                        </div>
                        <Icon name="repeat" />
                      </a>
                    </>
                  );
                })}
              </WorkspaceWrapper>
            </Modal.Content>
          </Modal>
        ) : (
          <>
            <Confirm
              open={this.state.open === workspaceUsername}
              content={`Are you sure you want to restart Workspace of [${workspaceUsername}]?`}
              onCancel={this.close}
              onConfirm={() => this.rebootWorkspace(workspaceUsername)}
              confirmButton={
                <Button loading={isSubmitting} color="blue">
                  OK
                </Button>
              }
            />
            <a
              className="dashboard-item"
              onClick={() => this.open(workspaceUsername)}
            >
              <div className="label">
                Restart Workspace
                <div className="desc">
                  This will restart Workspace of {workspaceUsername}
                  <div>
                    Status: <span>{workspaceState}</span>
                  </div>
                </div>
              </div>
              <Icon name="repeat" />
            </a>
          </>
        )}
      </>
    );
  }
}

RebootWorkspace.contextType = UserContext;

export default RebootWorkspace;
