import * as React from "react";
import { Button, Form, Message, Icon } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import cogoToast from "cogo-toast";
import axios from "axios";
import OrionLogo from "./OrionLogo";
import { LoginWrapper, LoadingText } from "./Styled";
import { startAnimation, stopAnimation } from "./animation";
import UserContext from "../../Context";
const logo = require("../../img/jira-logo.png");

const TIMEOUT = 120;
class Login extends React.Component {
  state = {
    username: "",
    password: "",
    token: "",
    loading: false,
    countdown: TIMEOUT,
    redirectUrl: ""
  };
  checkOauthCallbackInterval;
  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  doLogin = () => {
    const { username, password } = this.state;
    const { toggleLoginState } = this.props;
    let userContext = this.context;
    const self = this;
    axios
      .post("/api/jira/login", {
        username: username,
        password: password
      })
      .then(function(response) {
        if (response.data.error) {
          cogoToast.error("Login failed");
        } else {
          const name = response && response.data && response.data[0].name;
          const encoded = btoa(btoa(`${username}:${password}`) + "g");
          self.setState({
            token: encoded
          });
          localStorage.setItem("token", encoded);
          // localStorage.setItem("name", name);
          userContext.setUsername(name);
          toggleLoginState();
          cogoToast.success("Login success");
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleLogin = () => {
    const { username, password } = this.state;

    if (username !== "" && password !== "") {
      this.setState({ username: username, password: password });
      this.doLogin();
    } else {
      cogoToast.error("Please enter login information");
    }
  };

  checkOauthCallback = () => {};

  openPopup = (url, title, w, h) => {
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : document.querySelector("body").getBoundingClientRect().width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : document.querySelector("body").getBoundingClientRect().height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
      url,
      title,
      "scrollbars=yes, width=" +
        w / systemZoom +
        ", height=" +
        h / systemZoom +
        ", top=" +
        top +
        ", left=" +
        left
    );

    // Puts focus on the newWindow
    // if (window.focus) newWindow.focus();
  };

  detectIsMobile = () => {
    var check = false;
    (function(a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  loginWithJira = () => {
    const that = this;
    const { countdown } = this.state;
    const { toggleLoginState } = this.props;
    let userContext = this.context;
    this.setState({
      loading: true,
      countdown: TIMEOUT
    });
    axios
      .get("/api/sessions/connect")
      .then(function(response) {
        if (response.data.error) {
          cogoToast.error("Login failed");
        } else {
          const redirectUrl = response && response.data;
          const isMobile = that.detectIsMobile();
          // window.open(
          //   redirectUrl,
          //   "_blank",
          //   "location=yes,height=500,width=700,scrollbars=yes,status=yes"
          // );

          if (!isMobile) {
            that.openPopup(redirectUrl, "Jira Login", 700, 500);
          }

          that.setState({
            redirectUrl: redirectUrl
          });

          let timeout = TIMEOUT;
          that.checkOauthCallbackInterval = setInterval(() => {
            timeout -= 1;
            that.setState({
              countdown: timeout
            });
            axios.get("/api/sessions/callback").then(function(response) {
              const isSuccess =
                response && response.data && response.data.success;
              if (timeout === 0) {
                clearInterval(that.checkOauthCallbackInterval);
                cogoToast.error("Jira Timeout");
                that.setState({
                  loading: false
                });
              }
              if (isSuccess) {
                clearInterval(that.checkOauthCallbackInterval);
                const { token, token_secret, username, bearer } = response.data;
                localStorage.setItem("token", token + "|" + token_secret);
                localStorage.setItem("oauth", "1");
                localStorage.setItem("bearer", bearer);

                userContext.setBearer(bearer);
                // localStorage.setItem("name", username);
                userContext.setUsername(username);
                toggleLoginState();
                cogoToast.success("Login success");
              }
            });
          }, 1000);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  componentDidMount() {
    startAnimation();
  }

  componentWillUnmount() {
    stopAnimation();
  }

  render() {
    const { loading, countdown, redirectUrl } = this.state;
    return (
      <UserContext.Consumer>
        {context => {
          return (
            <LoginWrapper context={context}>
              <canvas id="particle-canvas" />
              <div className="logo-wrapper">
                <OrionLogo
                  width={200}
                  height={40}
                  className="main-logo"
                  color="#fff"
                />
                {/*<img src={orionLogo} className="main-logo" />*/}
                {/*<span className="logo-text">Dev tools</span>*/}
              </div>
              <Form onSubmit={this.handleLogin}>
                {loading ? (
                  <LoadingText>
                    <h3>Connecting to Jira ... {countdown}s</h3>
                    <div className="support-text">
                      {this.detectIsMobile() ? (
                        <>
                          Please click{" "}
                          <a href={redirectUrl} target="_blank">
                            here
                          </a>{" "}
                          to open a new tab
                        </>
                      ) : (
                        <>Please disable popup blocker</>
                      )}
                    </div>
                    <span
                      className="countdown-timer"
                      style={{ width: `${(countdown / TIMEOUT) * 100}%` }}
                    />
                  </LoadingText>
                ) : (
                  <>
                    {/*<Form.Field>*/}
                    {/*  /!*<label>Username</label>*!/*/}
                    {/*  <Form.Input*/}
                    {/*    placeholder="Username"*/}
                    {/*    name="username"*/}
                    {/*    value={username}*/}
                    {/*    onChange={this.handleChange}*/}
                    {/*  />*/}
                    {/*</Form.Field>*/}
                    {/*<Form.Field>*/}
                    {/*  /!*<label>Password</label>*!/*/}
                    {/*  <Form.Input*/}
                    {/*    placeholder="Password"*/}
                    {/*    type="password"*/}
                    {/*    name="password"*/}
                    {/*    value={password}*/}
                    {/*    onChange={this.handleChange}*/}
                    {/*  />*/}
                    {/*</Form.Field>*/}
                    {/*<Form.Field>*/}
                    {/*  <Button primary fluid type="submit">*/}
                    {/*    Submit*/}
                    {/*  </Button>*/}
                    {/*</Form.Field>*/}
                    <Button
                      primary
                      fluid
                      type="submit"
                      onClick={this.loginWithJira}
                    >
                      Login with Jira
                    </Button>
                  </>
                )}
              </Form>
            </LoginWrapper>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

Login.contextType = UserContext;

export default withRouter(Login);
