/**
 * Links
 */
export const JIRA_BASE_URL = "https://aquariux.atlassian.net"
export const DEPLOYMENT_PLAN =
  "https://docs.google.com/spreadsheets/d/1GYO6N3I06JmSseSJPl_QRM-Gyj0SDZQ_rOO_pCjVicw/edit#gid=0";
export const SUPPORT_REFERENCE =
  "https://docs.google.com/spreadsheets/d/1AM5NfOtzkfHGagDak8YZlnsX9giITF7OuafyT5TvxYA/edit#gid=0";
export const JIRA_LINK = "https://aquariux.atlassian.net/jira/projects";
export const CONFLUENCE_LINK = "https://aquariux.atlassian.net/wiki/home";
export const ADAXES_LINK = "https://adaxes.aquariux.com"
export const GENERAL_COMPANY_INFO =
  "https://aquariux.atlassian.net/wiki/spaces/GCI/overview?homepageId=96469260";
export const ON_BOARDING =
  "https://aquariux.atlassian.net/wiki/spaces/GCI/pages/23756818/On+Boarding+SOP";
export const ORG_CHART =
  "https://aquariux.atlassian.net/wiki/spaces/GCI/pages/96469353/Organization+Chart";
export const DEVELOPMENT_INFORMATION = "https://aquariux.atlassian.net/wiki/spaces/development/pages/105807887/Development+Info"
