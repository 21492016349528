import React from "react";

const style = {
  width: "14px",
  height: "14px"
};

const MergeRequestBadge = props => {
  return (
    <svg style={style}>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_4" data-name="Layer 4">
          <path
            fill={props.fill}
            xmlns="http://www.w3.org/2000/svg"
            d="M11 11.56V5.293a1 1 0 0 0-1-1H9v.793a.5.5 0 0 1-.854.353L6.354 3.646a.5.5 0 0 1 0-.707l1.792-1.793A.5.5 0 0 1 9 1.5v.793h1a3 3 0 0 1 3 3v6.267a2 2 0 1 1-2 0zM3 5.025a2 2 0 1 1 2 0v6.535a2 2 0 1 1-2 0V5.025zm1-.732a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm8 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
            id="dha"
          />
        </g>
      </g>
    </svg>
  );
};

export default MergeRequestBadge;
