import * as React from "react";
import PageHeader from "../Header";
import { Container, Icon } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { JenkinsStatusContainer } from "./Styled";
import JenkinsStatus from "./Status";

class Jenkins extends React.Component {
  componentDidMount() {
    document.title = "Jenkins Status";
  }
  render() {
    return (
      <>
        <PageHeader>
          <span className="title">Jenkins Status</span>
        </PageHeader>
        <JenkinsStatusContainer>
          <div className="jenkins-wrapper">
            <JenkinsStatus/>
          </div>
        </JenkinsStatusContainer>
      </>
    );
  }
}

export default withRouter(Jenkins);
