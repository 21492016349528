import styled from "styled-components";

export const TimelineWrapper = styled.div`
  line-height: initial;
`;

export const PageFilter = styled.div`
  display: flex;
  align-items: center;
`

export const BodyWrapper = styled.div`
  padding: 1rem;
  box-sizing: border-box;
  background: ${({ theme }) => theme.body};
  .chart-label {
    width: 350px;
    display: flex;
    align-items: center;
    .chart-link {
      width: 300px;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .status {
      font-size: 12px;
      //height: 16px;
      padding: 2px 8px;
      display: flex;
      align-items: center;
      border-radius: 16px;
      margin-left: auto;
      font-weight: bold;
      &.yellow {
        color: #594300;
        background-color: rgba(225, 225, 0, 0.1);
        border: 1px solid #e3b000;
      }
      &.green {
        color: #14892c;
        background-color: rgba(0, 150, 18, 0.08);
        border: 1px solid #1cb03c;
      }
      &.blue-gray {
        color: #0747A6;
        background-color: #DEEBFF;
        border: 1px solid #82b8e3;
      }
    }
  }
`;
