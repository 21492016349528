import * as React from "react";
import { AWSList } from "../Styled";
import { Icon, Segment, Label, Button } from "semantic-ui-react";
import Ajax from "../../Public/Ajax";
import { Loading } from "../../Public/Styled";
import moment from "moment";

const displayTab = [
  "All",
  "Cash",
  "Credit",
  "Wind2Api",
  "WeChat",
  "Livechat",
  "Alert",
  "Score",
  "Nginx",
  "SIT",
  "UAT"
];

class AWSStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      application: [],
      environment: [],
      lastBuild: [],
      flattenedLastBuild: [],
      currentSelected: "All",
      isFetching: true,
      showFixedRefresh: false
    };
  }

  getEnvironments = env => {
    /* The following operation retrieves information about an environment named my-env: */
    var params = {
      // EnvironmentNames: [
      //   "my-env"
      // ]
    };
    return new Promise(async resolver => {
      await Ajax("/api/aws/describeEnvironments", {
        method: "GET",
        params: {
          env: env ? env : undefined
        }
      }).then(function(response) {
        resolver(response);
      });
    });
  };

  onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  getApplicationStatus = env => {
    /* The following operation retrieves information about applications in the current region: */

    return new Promise(async resolver => {
      const params = {
        // region: ''
      };
      await Ajax(`/api/aws/describeApplications`, {
        method: "GET",
        params: {
          env: env ? env : undefined
        }
      }).then(function(response) {
        resolver(response);
      });
    });
  };

  async init() {
    // this.getEnvironmentHealth();
    this.setState({
      isFetching: true
    });
    const that = this;

    Promise.all([
      that.getEnvironments(),
      that.getEnvironments("MIRROR_CHAT"),
      that.getEnvironments("SIT"),
      that.getApplicationStatus(),
      that.getApplicationStatus("MIRROR_CHAT"),
      that.getApplicationStatus("SIT")
    ]).then(function(response) {
      const ultimate100environment = response[0];
      const weChatEnv = response[1];
      const sitEnv = response[2];

      const ultimate100Application = response[3];
      const weChatApplication = response[4];
      const sitApplication = response[5];

      const environment = [
        ...ultimate100environment.Environments,
        ...weChatEnv.Environments,
        ...sitEnv.Environments
      ];

      const result = [
        ...ultimate100Application.Applications,
        ...weChatApplication.Applications,
        ...sitApplication.Applications
      ];

      // console.log(result);

      let lastBuild = [];
      let flattenedLastBuild = [];

      const flatList = result
        .map(item => {
          return item && item.ApplicationName.startsWith("wechat")
            ? item.Versions.map(item => `wechat-${item}`)
            : item.Versions;
        })
        .flat();

      const filteredFlatList = flatList
        .map(item => {
          return {
            name: item.split("-#")[0],
            version: item.split("-#")[1]
          };
        })
        .sort((a, b) => {
          // If the name is the same between both items, sort alphabetically
          // If the first item comes first in the alphabet, move it up
          // Otherwise move it down
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;

          // Sort by Version
          // If the first item has a higher number, move it down
          // If the first item has a lower number, move it up
          if (Number(a.version) > Number(b.version)) return -1;
          if (Number(a.version) < Number(b.version)) return 1;
        });

      const filteredFlatListSet = Array.from(
        new Set(filteredFlatList.map(item => item.name))
      ).map(item => {
        return {
          name: item,
          version: `${item}-#${
            filteredFlatList.find(it => it.name === item).version
          }`
        };
      });

      result.map(item => {
        // if (item.ApplicationName === "accounting") {
        let application = [];
        // let unique = [];
        const versions = item.Versions.map(item => {
          return {
            name: item.split("#")[0],
            version: item.split("#")[1]
          };
        })
          .sort((a, b) => {
            // Sort by Version
            // If the first item has a higher number, move it down
            // If the first item has a lower number, move it up
            if (Number(a.version) > Number(b.version)) return -1;
            if (Number(a.version) < Number(b.version)) return 1;

            // If the name is the same between both items, sort alphabetically
            // If the first item comes first in the alphabet, move it up
            // Otherwise move it down
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
          })
          .map(item => {
            return `${item.name}#${item.version}`;
          });

        // console.log(versions);

        // console.log(customVersion);
        // const versions = item.Versions.sort((a, b) => b.localeCompare(a));
        const applicationName = item.ApplicationName;

        if (applicationName === "accounting") {
          // console.log(versions);
        }

        const filtered = versions.map(item => item.split("-#")[0]);

        const unique = filtered.filter(that.onlyUnique);

        unique.map(item => {
          const currItem = item;
          const version = versions.find(item => item.includes(currItem));
          const subApplicationObj = {
            subApplication: {
              name: item,
              version: version
            }
          };
          flattenedLastBuild.push({
            name: item.toLowerCase(),
            version: version
          });
          application.push(subApplicationObj);
        });

        lastBuild.push({
          applicationName: applicationName,
          subApplication: application
        });
      });

      that.setState({
        application: lastBuild,
        environment: environment,
        flattenedLastBuild: filteredFlatListSet,
        isFetching: false
      });
    });
  }

  async componentDidMount() {
    await this.init();
    const doc = document.documentElement;
    const that = this;
    // document.addEventListener("scroll", function(e) {
    //   const offsetTop =
    //     (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    //   that.setState({
    //     showFixedRefresh: offsetTop > 150
    //   });
    // });
  }

  renderAwsItem = props => {
    const envName = props.envName;
    const health = props.health;
    const healthStatus = props.healthStatus;
    const lastUpdate = props.lastUpdate;

    const isDocker = envName.includes("scheduler");
    const status = props.status;
    return (
      <div
        key={`${props.envName}${props.index}${props.lastBuildVersion}${
          props.envVersion
        }${Math.random()}`}
        className={`aws-item ${
          status.toLowerCase() === "updating"
            ? "loading"
            : isDocker
            ? "docker"
            : // : props.envName.toLowerCase().includes("wechatapi")
            // ? "unknown"
            health === "Red" || healthStatus === "Degraded"
            ? "failed"
            : ""
        } ${props.isSuccess ? "success" : "failed"}`}
      >
        <div className="aws-application-name">
          {props.envName}
          <span className="aws-application-status">
            {status.toLowerCase() === "updating" ? (
              <Icon name="spinner loading icon" />
            ) : isDocker ? (
              <Icon name="docker" />
            ) : props.envName.toLowerCase().includes("wechatapi") ? (
              <Icon name="question circle" />
            ) : props.isSuccess ? (
              <Icon name="check circle" />
            ) : (
              <Icon name="times circle" />
            )}
          </span>
        </div>
        <div className="aws-application-details">
          <div className="status-wrapper">
            <div className="label">Last build</div>
            <div className="content"> {props.lastBuildVersion}</div>
          </div>
          <div className="status-wrapper">
            <div className="label">Running in EB</div>
            <div className="content">{props.envVersion}</div>
          </div>
          <div className="status-wrapper">
            <div className="label">Health Status</div>
            <div className="content">
              {healthStatus ? healthStatus : "Not stated"}
            </div>
          </div>
          <div className="status-wrapper">
            <div className="label">Last Update</div>
            <div className="content">
              {moment.utc(lastUpdate).local().format("DD-MMM-YYYY hh:ss:mm a")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  changeTab = selected => {
    this.setState({
      currentSelected: selected
    });
  };

  render() {
    const {
      environment,
      flattenedLastBuild,
      currentSelected,
      isFetching,
      showFixedRefresh
    } = this.state;

    // console.log(environment, flattenedLastBuild);
    return (
      <>
        {isFetching ? (
          <Loading>
            {Array.from(Array(10).keys()).map((item, i) => {
              return <div className="loading-item" key={i} />;
            })}
          </Loading>
        ) : (
          <>
            <Segment
              vertical
              className="tab-wrapper"
              style={{ overflow: "auto" }}
            >
              {displayTab.map((item, index) => (
                <Label
                  className="tab-item"
                  key={item}
                  color={currentSelected === item ? "blue" : ""}
                  onClick={() => this.changeTab(item)}
                >
                  {item}
                </Label>
              ))}
              <Label
                color="teal"
                className={`tab-item ${showFixedRefresh ? "fixed" : ""}`}
                style={{ marginLeft: "auto" }}
                onClick={() => this.init()}
              >
                <Icon name="refresh" />
                Refresh
              </Label>
            </Segment>
            <AWSList>
              {environment &&
                environment
                  .filter(item =>
                    item && currentSelected === "Alert"
                      ? item.EnvironmentName.toLowerCase().includes("listener")
                      : currentSelected === "Score"
                      ? item.EnvironmentName.toLowerCase().includes("score")
                      : currentSelected === "Cash"
                      ? /^r[0-9]-/g.test(item.EnvironmentName.toLowerCase()) &&
                        !item.EnvironmentName.toLowerCase().includes(
                          "scheduler"
                        ) &&
                        !item.EnvironmentName.toLowerCase().includes("listener")
                      : currentSelected === "Credit"
                      ? item.EnvironmentName.toLowerCase().includes("credit0")
                      : currentSelected === "Wind2Api"
                      ? item.EnvironmentName.toLowerCase().includes("wind2api")
                      : currentSelected === "Nginx"
                      ? item.EnvironmentName.toLowerCase().includes("nginx")
                      : currentSelected === "SIT"
                      ? item.EnvironmentName.toLowerCase().includes("sit")
                      : currentSelected === "UAT"
                      ? item.EnvironmentName.toLowerCase().includes("uat")
                      : currentSelected === "WeChat"
                      ? item.EnvironmentName.toLowerCase().includes("wechat")
                      : currentSelected === "Livechat"
                      ? /^cr-/g.test(item.EnvironmentName.toLowerCase())
                      : true
                  )
                  .filter(item => {
                    return !item.VersionLabel.includes("Sample Application");
                  })
                  .sort((a, b) => {
                    const first = a.EnvironmentName.toLowerCase().split("-")[0];
                    const second = b.EnvironmentName.toLowerCase().split(
                      "-"
                    )[0];

                    if (first < second) return -1;
                    if (first > second) return 1;
                    return 0;
                  })
                  .map((item, index) => {
                    const countingIndex = index;
                    const envName = item && item.EnvironmentName;
                    const appName = item && item.ApplicationName;
                    const envVersion = appName.startsWith("wechat")
                      ? item && `wechat-${item.VersionLabel}`
                      : item && item.VersionLabel;
                    const status = item && item.Status;
                    const health = item && item.Health;
                    const healthStatus = item && item.HealthStatus;
                    const lastUpdate = item && item.DateUpdated;

                    return flattenedLastBuild.map(item => {
                      const lastBuildEnvName = item.name;
                      const lastBuildVersion = item.version;
                      if (
                        envVersion.toLowerCase().split("#")[0] ===
                        lastBuildVersion.toLowerCase().split("#")[0]
                      ) {
                        const isSuccess =
                          envVersion.toLowerCase() ===
                          lastBuildVersion.toLowerCase();

                        return this.renderAwsItem({
                          envName: envName,
                          envVersion: envVersion,
                          lastBuildVersion: lastBuildVersion,
                          lastBuildEnvName: lastBuildEnvName,
                          status: status,
                          health: health,
                          healthStatus: healthStatus,
                          lastUpdate: lastUpdate,
                          isSuccess: isSuccess,
                          index: countingIndex
                        });
                      }
                    });
                  })}
            </AWSList>
          </>
        )}
      </>
    );
  }
}

export default AWSStatus;
