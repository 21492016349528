import * as React from "react";
import styled from "styled-components";
import { getEditHistory } from "./liveEdit";
import { Button, Icon, Modal, Table, Label } from "semantic-ui-react";
import moment from "moment";
import { InputContent } from "./InputField";

const ContextMenuWrapper = styled.div`
  .contextMenu {
    position: fixed;
    background: white;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    z-index: 100;
    border-radius: 4px;
    overflow: hidden;

    &--option {
      padding: 6px 50px 5px 10px;
      min-width: 160px;
      cursor: default;
      font-size: 12px;
      &:hover {
        background: rgba(33, 133, 208, 0.71);
        color: #fff;
      }

      &:active {
        color: #fff;
        background: #2185d0;
      }

      &__disabled {
        color: #999999;
        pointer-events: none;
      }
    }

    &--separator {
      width: 100%;
      height: 1px;
      background: #cccccc;
      margin: 0 0 0 0;
    }
  }
`;

class ContextMenu extends React.Component {
  state = {
    visible: false,
    currentTarget: "",
    popupOpen: false,
    historyData: {},
    zhArr: [],
    vnArr: []
  };

  componentDidMount() {
    document.addEventListener("contextmenu", this._handleContextMenu);
    document.addEventListener("click", this._handleClick);
    document.addEventListener("scroll", this._handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("contextmenu", this._handleContextMenu);
    document.removeEventListener("click", this._handleClick);
    document.removeEventListener("scroll", this._handleScroll);
  }

  _handleContextMenu = event => {
    event.preventDefault();
    const key = event.target.getAttribute("data-key");

    if (key) {
      this.setState({ visible: true, currentTarget: key });

      const clickX = event.clientX;
      const clickY = event.clientY;
      const screenW = window.innerWidth;
      const screenH = window.innerHeight;
      const rootW = this.root.offsetWidth;
      const rootH = this.root.offsetHeight;

      const right = screenW - clickX > rootW;
      const left = !right;
      const top = screenH - clickY > rootH;
      const bottom = !top;

      if (right) {
        this.root.style.left = `${clickX + 5}px`;
      }

      if (left) {
        this.root.style.left = `${clickX - rootW - 5}px`;
      }

      if (top) {
        this.root.style.top = `${clickY + 5}px`;
      }

      if (bottom) {
        this.root.style.top = `${clickY - rootH - 5}px`;
      }
    }
  };

  viewHistory = async () => {
    const { currentTarget } = this.state;
    const { selectedProject } = this.props;
    const result = await getEditHistory(currentTarget, selectedProject);

    const zhArr =
      result &&
      result.zh &&
      result.zh.action &&
      result.zh.action.lastUpdate &&
      result.zh.action.lastUpdate.reverse();

    const vnArr =
      result &&
      result.vn &&
      result.vn.action &&
      result.vn.action.lastUpdate &&
      result.vn.action.lastUpdate.reverse();

    // console.log(result);
    this.setState({
      historyData: result,
      popupOpen: true,
      zhArr: zhArr,
      vnArr: vnArr
    });
  };

  _handleClick = event => {
    const { visible } = this.state;
    const wasOutside = !(event.target.contains === this.root);

    if (wasOutside && visible)
      this.setState({ visible: false, historyData: {} });
  };

  _handleScroll = () => {
    const { visible } = this.state;

    if (visible) this.setState({ visible: false, historyData: {} });
  };

  handleOpen = () => {
    this.setState({ popupOpen: true });
  };

  handleClose = () => {
    this.setState({ popupOpen: false });
  };

  render() {
    const { visible, currentTarget, popupOpen, zhArr, vnArr } = this.state;

    return (
      <>
        {(visible || null) && (
          <ContextMenuWrapper>
            <div
              className="contextMenu"
              ref={ref => {
                this.root = ref;
              }}
            >
              <div className="contextMenu--option" onClick={this.viewHistory}>
                View History
              </div>
              {/*<div className="contextMenu--option">New window</div>*/}
              {/*<div className="contextMenu--option">Visit official site</div>*/}
              {/*<div className="contextMenu--option contextMenu--option__disabled">*/}
              {/*  View full version*/}
              {/*</div>*/}
              {/*<div className="contextMenu--option">Settings</div>*/}
              {/*<div className="contextMenu--separator" />*/}
              {/*<div className="contextMenu--option">About this app</div>*/}
            </div>
          </ContextMenuWrapper>
        )}
        <Modal closeIcon open={popupOpen} onClose={this.handleClose}>
          <Modal.Header>{currentTarget}'s change log</Modal.Header>
          <Modal.Content>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="3">Chinese</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Updated</Table.HeaderCell>
                  <Table.HeaderCell>By</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {zhArr &&
                  zhArr.map((item, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>
                          {moment(item.lastModified).format(
                            "DD-MMM-YYYY h:mm:ss a"
                          )}
                        </Table.Cell>
                        <Table.Cell>{item.updatedText}</Table.Cell>
                        <Table.Cell>{item.username}</Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="3">Vietnamese</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Updated</Table.HeaderCell>
                  <Table.HeaderCell>By</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {vnArr &&
                  vnArr.map((item, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>
                          {moment(item.lastModified).format(
                            "DD-MMM-YYYY h:mm:ss a"
                          )}
                        </Table.Cell>
                        <Table.Cell>{item.updatedText}</Table.Cell>
                        <Table.Cell>{item.username}</Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

export default ContextMenu;
