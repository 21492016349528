import React, { useEffect, useState, useContext } from "react";
import { TimelineWrapper, PageFilter, BodyWrapper } from "./Styled";
import PageHeader from "../Header";
import Ajax from "../Public/Ajax";
import { Checkbox, Icon, Label, Select } from "semantic-ui-react";
import moment from "moment";
import { useParams } from "react-router-dom";
import Chart from "./Chart";
import Gantt from "./Gantt";

const projectOptions = [
  {
    key: "BFA",
    value: "BFA",
    text: "Bond Fund Admin",
  },
  {
    key: "REM",
    value: "REM",
    text: "Remittance",
  },
  {
    key: "WT",
    value: "WT",
    text: "Web Trader",
  },
  {
    key: "MPGM",
    value: "MPGM",
    text: "Multi Payment Gateway",
  },
  {
    key: "AQP",
    value: "AQP",
    text: "Internal Request",
  }
];

function TimelinePage() {
  let params = useParams();
  const [project, setProject] = useState(
    params.project ? params.project.toUpperCase() : ""
  );
  const [tasks, setTasks] = useState([]);
  const [originalTasks, setOriginalTasks] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);

  const getEpic = (epicKey, summary, status) => {
    const token = localStorage.getItem("token");
    return Ajax("/api/getEpicIssue", {
      method: "GET",
      params: {
        epicKey,
        summary,
        status,
        token,
      },
    });
  };

  const renderTasks = (issue) => {
    const {
      key,
      fields: {
        summary,
        issuetype,
        customfield_10015,
        customfield_10052,
        customfield_10053,
        customfield_10054,
        duedate,
        status: {
          statusCategory: { id, name },
        },
      },
    } = issue;

    const trackId = `${key} ${summary}`;

    const startDate = customfield_10015;
    const sitDate = customfield_10052;
    const uatDate = customfield_10053;
    const prodDate = customfield_10054;
    const dueDate = duedate;
    const issueType = issuetype;

    const momentStart = moment(startDate, "YYYY-MM-DD");
    const momentSitDate = moment(sitDate, "YYYY-MM-DD");
    const momentUatDate = moment(uatDate, "YYYY-MM-DD");
    const momentDueDate = moment(duedate, "YYYY-MM-DD");
    const momentEnd = moment(prodDate, "YYYY-MM-DD");

    if(key === "REM-39") {
      console.log(momentDueDate.toDate())
    }

    const duration = momentDueDate.diff(momentStart, "days") + 1;
    const completed = momentSitDate.diff(momentStart, "days");

    const progressPercentage = (completed / duration) * 100;
    const progress =
      progressPercentage > 100 ? 100 : progressPercentage.toFixed(2);

    return {
      TaskID: key,
      TaskName: trackId,
      StartDate: momentStart.toDate(),
      EndDate: momentDueDate.toDate(),
      Duration: duration,
      Progress: progress,
      status: {
        name: name,
        id: id,
      },
    };
  };

  useEffect(() => {
    if (project !== "") {
      setFetching(true);
      (async () => {
        const token = localStorage.getItem("token");
        await Ajax("/api/basicSearch", {
          method: "GET",
          params: {
            token,
            project,
          },
        }).then((response) => {
          const { data: issues } = response;

          let taskWithEpic = [];
          const epicIssuePromises = issues
            .filter((issue) => {
              const isEpic = issue.fields.issuetype.id === "10000";
              if (isEpic) {
                taskWithEpic.push(issue.key);
              }

              return isEpic;
            })
            .map((issue) => {
              const {
                key,
                fields: {
                  summary,
                  status: {
                    statusCategory: { id, name },
                  },
                },
              } = issue;

              const status = {
                id,
                name,
              };
              console.log(key);
              return getEpic(key, summary, status);
            });

          Promise.all(epicIssuePromises).then((epicIssues) => {
            /**
             * To generate Epic with Subtasks
             */
            const epicTimeline = epicIssues
              .map(({ data: { key, summary, status, issues } }) => {
                const subtask = issues
                  .filter((issue) => {
                    taskWithEpic.push(issue.key);
                    // Filter out issue without startDate
                    const hasStartDate = issue.fields.customfield_10015;
                    const hasDueDate = issue.fields.duedate;
                    return hasDueDate && hasStartDate;
                  })
                  .map((issue) => renderTasks(issue));

                const minDate =
                  subtask.length > 0
                    ? moment
                        .min(
                          subtask
                            .filter((task) => task.StartDate)
                            .map((task) => moment(task.StartDate))
                        )
                        .toDate()
                    : new Date();

                const maxDate =
                  subtask.length > 0
                    ? moment
                        .max(
                          subtask
                            .filter((task) => task.EndDate)
                            .map((task) => moment(task.EndDate))
                        )
                        .toDate()
                    : new Date();

                return {
                  TaskID: key,
                  TaskName: summary,
                  StartDate: minDate,
                  EndDate: maxDate,
                  subtasks: subtask,
                  status: status,
                };
              })
              .filter(({ subtasks }) => subtasks.length !== 0);

            console.log(epicTimeline)

            /**
             * To generate Task that is not in Subtasks
             */
            const issueMap = issues
              .filter((issue) => {
                // Filter out issue without startDate
                const hasStartDate = issue.fields.customfield_10015;
                const taskWithoutEpic = !taskWithEpic.includes(issue.key);
                return hasStartDate && taskWithoutEpic;
              })
              .map((issue, index) => [renderTasks(issue)])
              .reduce((acc, item) => {
                acc = [...acc, ...item];

                return acc;
              }, [])
              .sort((issueA, issueB) => {
                if (issueA.startDate && issueB.startDate) {
                  return issueB.StartDate.isBefore(issueA.startDate);
                } else {
                  return 0;
                }
              });

            setTasks([...issueMap, ...epicTimeline]);
            setOriginalTasks([...issueMap, ...epicTimeline]);
            setFetching(false);
          });
        });
      })();
    }
  }, [project]);

  useEffect(() => {
    const clonedOriginalTask = JSON.parse(JSON.stringify(originalTasks));
    const filteredTask = clonedOriginalTask.reduce((acc, task, index) => {
      const { subtasks, status } = task;
      if (subtasks) {
        const filteredSubtask = subtasks.filter((subtask) => {
          return showCompleted || subtask.status.id !== 3;
        });

        task.subtasks = filteredSubtask;
        acc.push(task);
      } else {
        if (showCompleted) {
          acc.push(task);
        } else {
          if (status.id !== 3) {
            acc.push(task);
          }
        }
      }
      return acc;
    }, []);

    setTasks(filteredTask);
  }, [originalTasks, showCompleted]);

  return (
    <TimelineWrapper>
      <PageHeader className="prod">
        <span className="title">Timeline</span>
      </PageHeader>
      <BodyWrapper>
        <PageFilter>
          <Select
            value={project}
            loading={fetching}
            placeholder="Select your projects"
            options={projectOptions.sort((a, b) => a.key.localeCompare(b.key))}
            onChange={(e, data) => {
              setProject(data.value);
            }}
            style={{ marginRight: 16 }}
          />
          <Checkbox
            toggle
            checked={showCompleted}
            onChange={() => setShowCompleted(!showCompleted)}
            label="Show Completed"
          />
        </PageFilter>
        {tasks.length !== 0 && <Gantt tasks={tasks} />}
        {/*{tasks.length !== 0 && <Chart tasks={tasks} />}*/}
      </BodyWrapper>
    </TimelineWrapper>
  );
}

export default TimelinePage;
